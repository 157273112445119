import * as React from "react";
import { Link, useParams, useHistory, withRouter } from "react-router-dom";
import { LeftSidebar, HeaderSection, Confirmation } from "components/molecules";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Document from "assets/images/cover.jpg";
import User from "assets/images/user-pic.png";
import SportsBlack from "assets/images/sports-black.svg";
import "./index.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getFanDetail,
  getFanDetail_,
  blockUnblockDeleteUser,
  setMenu_,
  activateMenuLevel2_,
} from "store/actions";
import {
  convertObjToStringParams,
  roundOff,
  showAction,
  showSection,
} from "utils/helper";
import { Loader } from "components/atoms";
import Moment from "react-moment";
import moment from "moment";

/**
 *  This Component contains fan Detail
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getFanDetail : function containing API call for getting fan details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * blockUnblockDeleteUser : used to block/unblock fan
 * @returns the fan detail component which will be rendered when this page is called.
 */
const FanDetail = (props) => {
  const {
    getFanDetail,
    showLoader,
    blockUnblockDeleteUser,
    detail,
    alPermissions,
    getFanDetail_,
    setMenu_,
    activateMenuLevel2_,
  } = props;
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {
      // Will do something
    },
  });
  const history = useHistory();

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 2))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as User Management/Fan and It calls Fan Detail APi
   * when Fan detail page is rendered on the screen
   */
  React.useEffect(() => {
    activateMenuLevel2_({ key: "fan" });
    setMenu_({ key: "userMgmt" });
    fetchFanDetail();
    return () => {
      getFanDetail_(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This Function is used to call API to get fan details
   */
  const fetchFanDetail = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getFanDetail({ params });
  };

  /**
   * Used to create API Data to send in API call
   * @returns object having id
   */
  const getApiData = () => {
    return {
      id,
    };
  };

  /**
   * This Function is used to open the dialog box
   * @param {object} : This contains type parameter which specific the type of dialog box
   */
  const handleClickOpen = ({ type }) => {
    let obj = { ...confirmationInfo };
    if (type === "activate") {
      obj = {
        ...obj,
        title: "Activate user",
        description: `Are you sure you want to activate ${detail?.details?.name} ?`,
        clickYes: () => {
          const apiData = {
            id: id,
            status: 1,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) fetchFanDetail();
            setOpen(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete user",
        description: `Are you sure you want to delete ${detail?.details?.name} ?`,
        clickYes: () => {
          const apiData = {
            id: id,
            status: 3,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) {
              setOpen(false);
              history.push("/fans");
            }
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Block user",
        description: `Are you sure you want to block ${detail?.details?.name} ?`,
        clickYes: () => {
          const apiData = {
            id: id,
            status: 2,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) fetchFanDetail();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  /**
   * This Function is used to set default image
   */
  const showDefaultImage = (e) => {
    e.target.src = SportsBlack;
  };

  /**
   * This Function is used to set default cover image
   */
  const showDefaultCoverImage = (e) => {
    e.target.src = Document;
  };

  /**
   * This Function is used to set default profile image
   */
  const showDefaultProfileImage = (e) => {
    e.target.src = User;
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="fan-wrap">
            <div className="fan-title">
              <div className="title-left">
                <Link to={`/fans`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="section-title">Fan Details</h1>
              </div>
              {showAction(alPermissions, 2) && (
                <div className="btn-group">
                  <Link to={`/fans/${id}/edit`}>
                    <Button variant="outlined">Edit</Button>
                  </Link>
                  <Button
                    color={detail?.details?.status === 1 ? "error" : "success"}
                    variant="outlined"
                    onClick={() =>
                      handleClickOpen({
                        type:
                          detail?.details?.status === 1
                            ? "de-activate"
                            : "activate",
                      })
                    }
                  >
                    {detail?.details?.status === 1 ? "Block" : "Activate"}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => handleClickOpen({ type: "delete" })}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
            <div className="user-wrap">
              <h3 className="section-heading">Account Details</h3>
              <div className="user-inner">
                <div className="user-inner-row">
                  <div className="user-col-fourth">
                    <label className="user-detail">Name</label>
                    <p>{detail?.details?.name}</p>
                  </div>
                  <div className="user-col-fourth">
                    <label className="user-detail">Mobile Number</label>
                    <p>
                      {detail?.details?.phoneNo !== "NA" &&
                      detail?.details?.countryCode !== "NA"
                        ? `${detail?.details?.countryCode} ${detail?.details?.phoneNo}`
                        : "NA"}
                    </p>
                  </div>
                  <div className="user-col-fourth">
                    <label className="user-detail">Email Address</label>
                    <p>{detail?.details?.email}</p>
                  </div>
                  <div className="user-col-fourth">
                    <label className="user-detail">
                      Successfull Invitation
                    </label>
                    <p>{detail?.details?.inviteCount ?? 0}</p>
                    <div className="subscription-link">
                      <Link to={`/fans/${id}/invitation`}>
                        Invitation History
                      </Link>
                    </div>
                  </div>
                  <div className="user-col-fourth top-spacing">
                    <label className="user-detail">Subscription</label>
                    <p>
                      {detail?.details?.subscription.expireDate &&
                      moment().format("YYYY-MM-DD") <=
                        moment(detail?.details?.subscription.expireDate).format(
                          "YYYY-MM-DD"
                        )
                        ? "Subscribed"
                        : "Free"}
                    </p>
                    <div className="subscription-link">
                      <Link to={`/fans/${id}/subscriptions`}>
                        Subscription History
                      </Link>
                    </div>
                  </div>
                  <div className="user-col-fourth top-spacing">
                    <label className="user-detail">Athlete Score</label>
                    <p>
                      {roundOff(detail?.details?.rating?.athleteRating ?? 0)}
                    </p>
                  </div>
                  <div className="user-col-fourth top-spacing">
                    <label className="user-detail">Fan Score</label>
                    <p>{roundOff(detail?.details?.rating?.fanRating ?? 0)}</p>
                  </div>
                  <div className="user-col-fourth top-spacing">
                    <label className="user-detail">Rating Count</label>
                    <p>
                      {detail?.details?.totalRatingGivenCount &&
                      detail.details.totalRatingGivenCount !== "NA"
                        ? `${detail.details.totalRatingGivenCount}`
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-wrap">
              <h3 className="section-heading">Basic Details</h3>
              <div className="user-profile-inner">
                <div className="user-left">
                  <div className="user-image">
                    <figure>
                      <img
                        src={
                          detail?.details?.profileImage &&
                          detail?.details?.profileImage !== "NA"
                            ? detail?.details?.profileImage
                            : User
                        }
                        alt="User"
                        onError={showDefaultProfileImage}
                      />
                    </figure>
                  </div>
                </div>
                <div className="user-right margin">
                  <div className="user-inner-row">
                    <div className="user-col">
                      <label className="user-detail">Username</label>
                      <p>{detail?.details?.username}</p>
                    </div>
                    <div className="user-col">
                      <label className="user-detail">Date Of Birth</label>
                      <p>
                        {detail?.details?.personalDetails?.dob &&
                        detail?.details?.personalDetails?.dob !== "N/A" ? (
                          <Moment format="DD MMM, YYYY">
                            {detail?.details?.personalDetails?.dob}
                          </Moment>
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </div>
                    <div className="user-col">
                      <label className="user-detail">Zip</label>
                      <p>
                        {detail?.details?.zipcode
                          ? detail?.details?.zipcode
                          : "NA"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-inner-row">
                <div className="user-col top-spacing">
                  <div className="user-bio">
                    <label className="user-detail bottom-spacing">
                      Cover Image
                    </label>
                    <div className="cover-img">
                      <figure>
                        <img
                          src={
                            detail?.details?.coverImage &&
                            detail?.details?.coverImage !== "NA"
                              ? detail?.details?.coverImage
                              : Document
                          }
                          alt="Cover"
                          onError={showDefaultCoverImage}
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="user-col-bio top-spacing">
                  <label className="user-detail">Bio</label>
                  <p className="bio-description">
                    {detail?.details?.personalDetails?.bio}
                  </p>
                </div>
              </div>
            </div>
            <div className="user-wrap">
              <h3 className="section-heading">Liked Sports</h3>
              <div className="user-inner">
                <div className="user-row scroll">
                  {detail?.details?.likedSport &&
                  detail?.details?.likedSport.length > 0 ? (
                    detail?.details?.likedSport.map((item) => {
                      return (
                        <div className="like-col" key={item._id}>
                          <div className="sport-title-sec">
                            <div className="sport-logo like">
                              <figure>
                                <img
                                  src={item.sportImg}
                                  alt="Icon"
                                  onError={showDefaultImage}
                                />
                              </figure>
                            </div>
                            <h3 className="like-title">{item.sportName}</h3>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="like-sport-text">NA</p>
                  )}
                </div>
              </div>
            </div>
            <div className="user-wrap">
              <div className="user-row">
                <div className="user-col">
                  <div className="followers-info">
                    <label className="followers-title">Followers</label>
                    <div className="info-text">
                      <Link to={`/fans/${id}/followers`}>
                        {detail?.followerCount} Followers
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="user-col">
                  <div className="followers-info">
                    <label className="followers-title">Following</label>
                    <div className="info-text">
                      <Link to={`/fans/${id}/followings`}>
                        {detail?.followingCount} Following
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="user-col">
                  <div className="followers-info">
                    <label className="followers-title">Video Library</label>
                    <div className="info-text">
                      <Link to={`/fans/${id}/video-library`}>
                        {detail?.VideoCount} Video
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="user-col user-top-spacing">
                  <div className="followers-info">
                    <label className="followers-title">Video Rated</label>
                    <div className="info-text">
                      <Link to={`/fans/${id}/video-rated`}>
                        {detail?.RateCount} Video
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.fanInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getFanDetail: (data) => dispatch(getFanDetail(data)),
  blockUnblockDeleteUser: (data) => dispatch(blockUnblockDeleteUser(data)),
  getFanDetail_: (data) => dispatch(getFanDetail_(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  setMenu_: (data) => dispatch(setMenu_(data)),
});

FanDetail.propTypes = {
  getFanDetail: PropTypes.func,
  showLoader: PropTypes.bool,
  blockUnblockDeleteUser: PropTypes.func,
  detail: PropTypes.object,
};
export default withRouter(connect(mapState, mapDispatch)(FanDetail));
