import { USER_LOGIN, SET_INITIAL_AUTH_NEW } from "store/types";

const initialState = {
  auth: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_AUTH_NEW:
      localStorage.clear();
      state.auth = null;
      state.error = null;
      return { ...state };

    case USER_LOGIN:
      state.auth = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default authReducer;
