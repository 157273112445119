/* eslint-disable no-undef */
import axios from "axios";

let interceptorObj = {
  setupInterceptors: () => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // in case request error code is >= 400
        // catches if the session ended!
        // console.log("err###", error);
        // console.log("errRes##", error.response);

        if (
          error &&
          error.response &&
          (error.response.statusText === "Unauthorized" ||
            error.response.status === 401)
        ) {
          localStorage.clear();
          window.location.replace("/");
        }

        return error.response;
      }
    );
  },
};

export default interceptorObj;
