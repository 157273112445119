import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Video Tags Table
 * @param {Object} props : listData : contains list of tags
 * @returns the Video Tags Table Component which will be rendered when this page is called.
 */
const VideoTaggedTableList = (props) => {
  const { listData } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData.result : [];

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }
  return (
    <div className="video-tagged-user table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row.userId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link
                    to={`/${row.userType === 1 ? "fans" : "athletes"}/${
                      row.userId
                    }`}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell
                  className={
                    row.status === 1 ? "status-active" : "status-block"
                  }
                >
                  {row.status === 1 ? "Active" : "Blocked"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default VideoTaggedTableList;
