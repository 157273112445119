import {
  TOGGLE_MENU,
  ACTIVATE_MENU_LEVEL2,
  SET_INITIAL,
  SET_MENU,
} from "store/types";

export const toggleMenu_ = (payload) => ({
  type: TOGGLE_MENU,
  payload,
});

export const activateMenuLevel2_ = (payload) => ({
  type: ACTIVATE_MENU_LEVEL2,
  payload,
});

export const setMenu_ = (payload) => ({
  type: SET_MENU,
  payload,
});

export const setInitialMenu_ = () => ({
  type: SET_INITIAL,
});
