import * as React from "react";
import IconButton from "@mui/material/IconButton";
import {
  LeftSidebar,
  HeaderSection,
  PaginationSection,
  FanTableList,
} from "components/molecules";
import { FilterSubmit, FilterReset, Loader } from "components/atoms";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import {
  getAllFans,
  setMenu_,
  activateMenuLevel2_,
  getFanPaginationDetail_,
} from "store/actions";
import {
  convertObjToStringParams,
  showSection,
  showAction,
} from "utils/helper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { Link, useHistory } from "react-router-dom";
import { ratingValidator } from "utils/validations";

/**
 *  This Component contains Fan Listing
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getAllFans : function containing API call for getting all the Fans,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * page,limit : The page keep track of the pageNo called during API call and limit tracks the no. of records we need
 * @returns the Fan Listing component which will be rendered when this page is called.
 */
const FansList = (props) => {
  const {
    getAllFans,
    showLoader,
    alPermissions,
    setMenu_,
    activateMenuLevel2_,
    page,
    limit,
    getFanPaginationDetail_,
  } = props;
  const [listData, setListData] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const history = useHistory();

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    from: "",
    to: "",
    pageLimit: limit,
    pageNo: page,
    sortBy: { key: "addedOn", val: -1 },
    location: "",
    subscriptionType: "",
    rating: "",
    status: "",
    error: "",
    ratingError: "",
  });

  /**
   * This Function check if the admin does have the permission to module then will redirect to unaythorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 2))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to call Fan Listing API when this Component is rendered on the screen
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as User Management/Fan
   */
  React.useEffect(() => {
    activateMenuLevel2_({ key: "fan" });
    setMenu_({ key: "userMgmt" });
  }, []);

  /**
   * Used to open filter popup on click of filter icon
   * @param {event} event : This event parameter keeps the value of current target
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Used to close the filter Popup
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Debouncing For Search
   */
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  /**
   * This is used to set the filter values on the basis of which Listing will be sorted.
   * @param {string} key : used to send the key whose value needs to be changed
   */
  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;

    if (key === "rating") {
      newFilter.ratingError = ratingValidator(e.target.value);
    }

    if (newFilter.from && newFilter.to && ["from", "to"].includes(key)) {
      if (newFilter.from > newFilter.to) {
        newFilter.error = "Please select valid age options !";
      } else {
        newFilter.error = "";
      }
    }
    setFilter(newFilter);
  };

  /**
   * Used to create API Data to send in API call
   * @returns object having all the filter selected,page,limit and other params values
   */
  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      sortKey: filter.sortBy.key,
      sortOrder: filter.sortBy.val,
      userType: 1, // Fan
    };
    if (filter.from) apiData.from = filter.from;
    if (filter.to) apiData.to = filter.to;
    if (filter.search) apiData.search = filter.search;
    if (filter.subscriptionType) apiData.subscription = filter.subscriptionType;
    if (filter.location) apiData.location = filter.location;
    if (filter.status) apiData.status = filter.status;
    if (filter.rating) apiData.rating = filter.rating;
    return apiData;
  };

  /**
   * used to call API to get list of all fans
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    if (filter.error || filter.ratingError) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    setAnchorEl(null);
    getAllFans({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  /**
   * This Function is used to reset the selected values in filter
   */
  const handleFilterReset = () => {
    const newFilter = { ...filter };
    newFilter.from = "";
    newFilter.to = "";
    newFilter.location = "";
    newFilter.subscriptionType = "";
    newFilter.error = "";
    newFilter.status = "";
    newFilter.rating = "";
    newFilter.ratingError = "";
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
    getFanPaginationDetail_({
      limit: val,
      page: newFilter.pageNo,
    });
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
    getFanPaginationDetail_({
      page: val,
      limit: newFilter.pageLimit,
    });
  };

  /**
   * This Function is used to sort any column in table
   * @param {Object} obj : takes the key of column to sort and it's sorting order ascending/desending
   */
  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   *  This Function is used to create the menuitems for age dropdown
   * @param {string} key : takes the key from/to to check from where it is called
   * @returns age options
   */
  const renderAgeOptions = (key) => {
    let options = [];
    for (let i = 13; i <= 100; i++) {
      let item = (
        <MenuItem value={i} key={i + key}>
          {i} Years
        </MenuItem>
      );
      options.push(item);
    }
    return options;
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section fan-list">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Fan Management</h1>
              </div>
              <div className="breadcrumb-right">
                {showAction(alPermissions, 2) && (
                  <Link to="/fans/add">
                    <Button variant="contained">Add</Button>
                  </Link>
                )}
              </div>
            </div>
            <div className="search-filter-wrap">
              <div className="serach-wrap">
                <TextField
                  label="Search"
                  variant="outlined"
                  onChange={handleSearch}
                  value={filter?.search}
                  placeholder="Search by name, email and mobile number"
                />
              </div>
              <div className="filter-btn">
                <Tooltip title="Filter">
                  <IconButton
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    color="primary"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <div className="filterPopup">
                    <div className="age-group">
                      <label className="textLabel">Age group</label>
                      <div className="filter-row">
                        <div className="filter-col bottom-spacing">
                          <FormControl fullWidth>
                            <InputLabel>From</InputLabel>
                            <Select
                              label="From"
                              placeholder="From"
                              value={filter?.from}
                              onChange={handleChangeFilter("from")}
                            >
                              {renderAgeOptions("from")}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="filter-col">
                          <FormControl fullWidth>
                            <InputLabel>To</InputLabel>
                            <Select
                              label="To"
                              placeholder="To"
                              value={filter?.to}
                              onChange={handleChangeFilter("to")}
                            >
                              {renderAgeOptions("to")}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    {filter?.error && (
                      <span className="error-msg">{filter.error}</span>
                    )}
                    <div className="filter-text">
                      <label className="form-label">Location</label>
                      <TextField
                        label="Select"
                        variant="outlined"
                        value={filter?.location}
                        onChange={handleChangeFilter("location")}
                        placeholder="Enter Zipcode"
                      />
                    </div>
                    <div className="filter-text">
                      <label className="form-label">Rating</label>
                      <TextField
                        placeholder="Enter Rating"
                        label="Select Rating"
                        variant="outlined"
                        value={filter?.rating}
                        onChange={handleChangeFilter("rating")}
                        className={filter.ratingError ? "input-error" : ""}
                      />
                      {filter.ratingError && (
                        <span className="error-msg">{filter.ratingError}</span>
                      )}
                    </div>
                    <div className="filter-text">
                      <label className="form-label">Subscription Type</label>
                      <FormControl fullWidth>
                        <InputLabel>Subscription Type</InputLabel>
                        <Select
                          label="Subscription Type"
                          placeholder="Select"
                          value={filter?.subscriptionType}
                          onChange={handleChangeFilter("subscriptionType")}
                        >
                          <MenuItem value="3">Non Premium User</MenuItem>
                          <MenuItem value="1">Monthly</MenuItem>
                          <MenuItem value="2">Yearly</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="filter-text">
                      <label className="form-label">Status</label>
                      <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          label="Status"
                          placeholder="Select"
                          value={filter?.status}
                          onChange={handleChangeFilter("status")}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={2}>Blocked</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="btn-wrap">
                      <FilterReset
                        name="Reset"
                        onClickButton={handleFilterReset}
                      />
                      <FilterSubmit
                        name="Apply Filter"
                        onClickButton={handleFilterSubmit}
                      />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            {showLoader && <Loader />}
            <FanTableList
              listData={listData}
              filter={filter}
              toggleSortOrder={toggleSortOrder}
              handleFilterSubmit={handleFilterSubmit}
            />
            {listData?.result?.length > 0 && (
              <PaginationSection
                limit={limit}
                mainSection={true}
                listData={listData}
                handleChangePage={handleChangePage}
                handleChangePageLimit={handleChangePageLimit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
  page: state?.fanInfo?.page,
  limit: state?.fanInfo?.limit,
});
const mapDispatch = (dispatch) => ({
  getAllFans: (data) => dispatch(getAllFans(data)),
  getFanPaginationDetail_: (data) => dispatch(getFanPaginationDetail_(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  setMenu_: (data) => dispatch(setMenu_(data)),
});

FansList.propTypes = {
  getAllFans: PropTypes.func,
  showLoader: PropTypes.bool,
};
export default connect(mapState, mapDispatch)(FansList);
