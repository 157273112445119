import * as React from "react";
import { PaginationSection, PostsTableList } from "components/molecules";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { getAllReports, getReportPaginationDetail_ } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { Loader } from "components/atoms";

/**
 * This Component contains Reported Post Tab
 * @param {Object} props : page,limit : The page keep track of the pageNo called during API call and limit tracks the no. of records needed , showLoader : shows loading state until data is getting fetched , getAllReports : function containing API call for getting all the reported posts
 * @returns the Reported Post Tab which will be rendered when this page is called.
 */
const ReportPostTab = (props) => {
  const { getAllReports, showLoader, getReportPaginationDetail_, page, limit } =
    props;
  const [listData, setListData] = React.useState(null);

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: limit,
    pageNo: page,
    error: "",
  });

  /**
   * This useEffect is used to call Reported Post API when this Component is rendered on the screen
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Used to create API Data to send in API call
   * @returns object having search, page, limit and other params values
   */
  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
    };
    if (filter.search) apiData.search = filter.search;
    return apiData;
  };

  /**
   * This Function is used to call API to get list of reported posts
   * @returns false if there is any error in the values
   */
  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getAllReports({ params, type: "post" }).then((apiData) => {
      setListData(apiData);
    });
  };

  /**
   * Debouncing For Search
   */
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
    getReportPaginationDetail_({
      limit: val,
      page: newFilter.pageNo,
    });
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
    getReportPaginationDetail_({
      page: val,
      limit: newFilter.pageLimit,
    });
  };
  return (
    <div className="post-tab-wrap">
      {showLoader && <Loader />}
      <div className="search-filter-wrap">
        <div className="serach-wrap">
          <TextField
            label="Search"
            variant="outlined"
            placeholder="Search by caption"
            onChange={handleSearch}
            value={filter?.search}
          />
        </div>
      </div>
      <PostsTableList
        listData={listData}
        filter={filter}
        handleFilterSubmit={handleFilterSubmit}
      />
      {listData?.result?.length > 0 && (
        <PaginationSection
          limit={limit}
          mainSection={true}
          listData={listData}
          handleChangePage={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
        />
      )}
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  page: state?.reportInfo?.page,
  limit: state?.reportInfo?.limit,
});
const mapDispatch = (dispatch) => ({
  getAllReports: (data) => dispatch(getAllReports(data)),
  getReportPaginationDetail_: (data) =>
    dispatch(getReportPaginationDetail_(data)),
});
export default connect(mapState, mapDispatch)(ReportPostTab);
