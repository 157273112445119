import * as React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import "./index.css";

/**
 * This Component contains unauthorized component
 * @returns the Component to be rendered when any unauthorized route is called
 */
const UnauthorizedPage = () => {
  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          <div className="main-section-inner">
            <div className="unauthorized-section">
              <h1 className="err-title">403</h1>
              <p className="err-description">
                You do not have access to this page.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
