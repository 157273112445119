import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { showAction } from "utils/helper";
import { IconButton, TextField, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Moment from "react-moment";
import { FilterReset, FilterSubmit } from "components/atoms";
import { ConfirmationInput } from "components/molecules";

import "./index.css";

/**
 * This Component contains Content Creator List
 * @param {Object} props : listData : contains list of top content creators
 * @returns the Content Creator Component which will be rendered when this page is called.
 */
const ContentCreatorList = (props) => {
  const { listData, alPermissions, handleEdit, handleDelete, dataLength } =
    props;
  const [rankToBeAddedAt, setRankToBeAddedAt] = React.useState(1);
  const [valueToBeChanged, setValuetoBeChanged] = React.useState(0);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "Change Ranking",
    description: "",
    clickClose: () => {
      setPopupOpen(false);
    },
  });
  let rows = listData && listData.length > 0 ? listData : [];

  /**
   * Used to open edit popup on click of edit icon
   * @param {event} event : This event parameter keeps the value of current target
   */
  const handleClick = (row) => {
    const { name, order } = row;
    setConfirmationInfo({
      ...confirmationInfo,
      description: `Enter ${name}'s New Rank`,
    });
    setValuetoBeChanged(order - 1);
    setRankToBeAddedAt(order);
    setPopupOpen(true);
    setError("");
  };

  const handleClose = () => {
    setError("");
    setPopupOpen(false);
  };

  const handleValueChange = (event) => {
    const value = event.target.value;
    setRankToBeAddedAt(value);
    if (value > 0 && value <= dataLength) {
      setError("");
    } else {
      setError("Enter a valid rank");
    }
  };

  const checkVal = (rankToBeAddedAt, valueToBeChanged) => {
    if (rankToBeAddedAt > 0 && rankToBeAddedAt <= dataLength) {
      setError("");
      handleEdit(rankToBeAddedAt, valueToBeChanged);
      handleClose();
    } else {
      setError("Enter a valid rank");
    }
  };

  return (
    <div className="table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Added on</TableCell>
              <TableCell>Mobile Number</TableCell>
              {showAction(alPermissions, 13) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row?.order}</TableCell>
                <TableCell>
                  <Link
                    to={`/${row?.userType === 1 ? "fans" : "athletes"}/${
                      row?.userId
                    }`}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY & h:mm A">{row?.addedOn}</Moment>
                </TableCell>
                <TableCell>
                  {row?.phoneNo &&
                  row?.countryCode &&
                  row.phoneNo !== "NA" &&
                  row.countryCode !== "NA"
                    ? `${row.countryCode} ${row.phoneNo}`
                    : "NA"}
                </TableCell>
                {showAction(alPermissions, 13) && (
                  <TableCell>
                    <div className="action-wrap">
                      <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                          onClick={() => handleClick(row)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() => handleDelete(row?.userId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationInput
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={popupOpen}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      >
        <div className="rankChange">
          <TextField
            type="tel"
            InputProps={{
              inputProps: { min: 0 },
            }}
            value={rankToBeAddedAt}
            onChange={handleValueChange}
            className={error !== "" ? "input-error" : ""}
          />
          {error !== "" && <span className="error-msg">{error}</span>}
        </div>
        <div className="btn-wrap">
          <FilterReset name="Close" onClickButton={handleClose} />
          <FilterSubmit
            name="Update Ranking"
            onClickButton={() => {
              checkVal(rankToBeAddedAt, valueToBeChanged);
            }}
          />
        </div>
      </ConfirmationInput>
    </div>
  );
};

ContentCreatorList.propTypes = {
  listData: PropTypes.any,
};
export default ContentCreatorList;
