import {
  GET_SPORT_DETAIL,
  SET_EDIT_POSITION,
  SET_SPORT_INITIAL,
  SET_SPORT_PAGINATION,
} from "store/types";

const initialState = {
  current: null,
  editPosition: null,
  page: 1,
  limit: 10,
};

const fanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPORT_DETAIL:
      state.current = action.payload;
      return { ...state };

    case SET_SPORT_PAGINATION:
      return {
        ...state,
        page: action.payload.page,
        limit: action.payload.limit,
      };

    case SET_SPORT_INITIAL:
      return { ...initialState };

    case SET_EDIT_POSITION:
      state.editPosition = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default fanReducer;
