import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  FanSubscriptionTableList,
} from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useParams } from "react-router-dom";
import { setMenu_, activateMenuLevel2_ } from "store/actions";
import { connect } from "react-redux";

/**
 *  This Component contains fan subscription listing
 * @param {Object} props setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * @returns the fan subscription listing component which will be rendered when this page is called.
 */
const FanSubscription = (props) => {
  const { id: userId } = useParams();
  const { setMenu_, activateMenuLevel2_ } = props;

  /**
   * This useEffect is used to set Active Module as User Management/Fan
   */
  React.useEffect(() => {
    activateMenuLevel2_({ key: "fan" });
    setMenu_({ key: "userMgmt" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section fan-subscription">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="title-left">
                <Link to={`/fans`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="section-title">Fan Details</h1>
                <Link to={`/fans/${userId}`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h2 className="section-sub-title">Subscription History</h2>
              </div>
            </div>
            <FanSubscriptionTableList />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  setMenu_: (data) => dispatch(setMenu_(data)),
});
export default connect(null, mapDispatch)(FanSubscription);
