import * as React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import { useHistory, withRouter, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { editAppUser, setMenu_, activateMenuLevel2_ } from "store/actions";
import { connect } from "react-redux";
import {
  customTextFieldValidator,
  emailFieldValidator,
  mobileNumberValidator,
} from "utils/validations";
import { Loader } from "components/atoms";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import codes from "country-calling-code";
import {
  showSection,
  showAction,
  convertObjToStringParams,
  getErrors,
} from "utils/helper";
import { getAthleteDetail, getAthleteDetail_ } from "store/actions";
import { Link } from "react-router-dom";

const countryCodes = codes.sort((a, b) =>
  a.isoCode3 > b.isoCode3 ? 1 : b.isoCode3 > a.isoCode3 ? -1 : 0
);

/**
 *  This Component contains Edit Fan Page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * editAppUser : function containing API call for editing user Details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * @returns the Edit Fan component which will be rendered when this page is called.
 */
const FanEdit = (props) => {
  const {
    editAppUser,
    showLoader,
    detail,
    alPermissions,
    getAthleteDetail,
    getAthleteDetail_,
    setMenu_,
    activateMenuLevel2_,
  } = props;
  const { id } = useParams();
  const [formData, setFormData] = React.useState({
    name: detail?.details?.name,
    email: detail?.details?.email,
    phoneNo: detail?.details?.phoneNo,
    username: detail?.details?.username,
    countryCode: detail?.details?.countryCode.substring(1),
    errors: {},
  });
  const history = useHistory();

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 1))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Used for setting values with saved fan details
   */
  React.useEffect(() => {
    setFormData({
      ...formData,
      name: detail?.details?.name,
      email: detail?.details?.email,
      phoneNo: detail?.details?.phoneNo,
      username: detail?.details?.username,
      countryCode: detail?.details?.countryCode.substring(1),
    });
  }, [detail]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as User Management/Fan
   */
  React.useEffect(() => {
    fetchAthleteDetail();
    activateMenuLevel2_({ key: "fan" });
    setMenu_({ key: "userMgmt" });
    return () => {
      getAthleteDetail_(null);
    };
  }, []);

  /**
   * This Function is used to call API to get athlete details
   */
  const fetchAthleteDetail = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getAthleteDetail({ params });
  };

  /**
   * Used to create API Data to send in API call
   * @returns object having id
   */
  const getApiData = () => {
    return {
      id,
    };
  };

  /**
   * When form is submitted the values are set to empty values
   */
  const onCloseForm = () => {
    setFormData({
      name: "",
      email: "",
      phoneNo: "",
      countryCode: "1",
      errors: {},
    });
  };

  /**
   * This Function is called everytime the entered value is changed
   * @param {string} key : It will take the changed value for the input fields and then will validate it.
   */
  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;
    setFormData(newFormData);
  };

  /**
   * This Function is used to check if the value is edited or not
   * @returns the object containing new values
   */
  const checkForChange = () => {
    const tempData = { userType: 1 };

    if (detail.details["name"].trim() !== formData["name"].trim()) {
      tempData["name"] = formData["name"].trim();
    }
    if (
      formData["email"].toLowerCase() !== detail.details["email"].toLowerCase()
    ) {
      tempData["email"] = formData["email"].toLowerCase();
    }
    if (
      detail.details["countryCode"].substring(1) !== formData["countryCode"] ||
      detail.details["phoneNo"] !== formData["phoneNo"]
    ) {
      tempData["countryCode"] = `+${formData.countryCode}`;
    }
    if (
      detail.details["phoneNo"] !== formData["phoneNo"] ||
      detail.details["countryCode"].substring(1) !== formData["countryCode"]
    ) {
      tempData["phoneNo"] = formData["phoneNo"];
    }
    if (detail.details["username"] !== formData["username"]) {
      tempData["username"] = formData["username"];
    }
    return tempData;
  };

  /**
   * This Function is called when the form click on submit button after all the values are added/edited
   * then this function will validate the values and hit the post API in case of Add and put API in case of edit
   * @returns false if all the values fail to validate
   */
  const handleSubmit = () => {
    if (checkForError()) return false;
    const tempData = checkForChange();
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    const data = {
      data: tempData,
      params,
    };
    editAppUser(data).then((res) => {
      if (res) {
        onCloseForm();
        history.push("/fans");
      }
    });
  };

  /**
   * This Function is used to validate the entered value as per the defined rules
   * @param {string} key : takes the key to be checked
   * @param {string} val : takes the value corresponding to the key passed
   * @returns the error array if any error found
   */
  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "name") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 3 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter name !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            true,
            "Please enter only alphabets with spaces in between !",
          ],
        });
      } else if (key === "email") {
        err = emailFieldValidator(val, true, "Please enter email !");
      } else if (key === "phoneNo") {
        err = mobileNumberValidator({
          len: [10, "Please enter 10 digits !"],
          val,
          req: [true, "Please enter mobile number !"],
          min: [10, "Min. length is 10 digits !"],
          max: [10, "Max. length is 10 digits !"],
        });
      } else if (key === "username") {
        err = customTextFieldValidator({
          min: [5, "Min. length is 5 characters !"],
          max: [30, "Max. length is 30 characters !"],
          val,
          req: [true, "Please enter username !"],
          customRegex: [
            true,
            "Please enter only alphabets, digits only !",
            /^[A-Za-z0-9]*$/,
          ],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.name = customTextFieldValidator({
        min: [3, "Min. length is 3 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.name,
        req: [true, "Please enter name !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          true,
          "Please enter only alphabets with spaces in between !",
        ],
      });
      newFormState.errors.phoneNo = mobileNumberValidator({
        len: [10, "Please enter 10 digits !"],
        val: newFormState.phoneNo,
        req: [true, "Please enter mobile number !"],
        min: [10, "Min. length is 10 digits !"],
        max: [10, "Max. length is 10 digits !"],
      });
      newFormState.errors.email = emailFieldValidator(
        newFormState.email,
        true,
        "Please enter email !"
      );
      newFormState.errors.username = customTextFieldValidator({
        min: [5, "Min. length is 5 characters !"],
        max: [30, "Max. length is 30 characters !"],
        val: newFormState.username,
        req: [true, "Please enter username !"],
        customRegex: [
          true,
          "Please enter only alphabets, digits only !",
          /^[A-Za-z0-9]*$/,
        ],
      });
      newFormState.errors = getErrors(newFormState?.errors);
    }
    setFormData(newFormState);
    return Object.keys(newFormState.errors).length > 0 ? true : false;
  };
  return (
    <div className="pageWrapper">
      {showLoader && <Loader />}
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        {formData.countryCode && (
          <div className="main-section">
            <div className="main-section-inner">
              <div className="breadcrumb-wrap">
                <div className="title-left">
                  <Link to={`/fans/${id}`}>
                    <ArrowBackIosNewIcon />
                  </Link>
                  <h1 className="title">Edit Fan</h1>
                </div>
                <div className="breadcrumb-right">
                  {showAction(alPermissions, 1) && (
                    <Button variant="contained" onClick={handleSubmit}>
                      Save
                    </Button>
                  )}
                </div>
              </div>
              <div className="addathlete-info">
                <div className="addathlete-row">
                  <div className="addathlete-col">
                    <label className="form-label">Name*</label>
                    <TextField
                      placeholder="Name"
                      variant="outlined"
                      onChange={onChangeForm("name")}
                      value={formData.name}
                      className={formData?.errors?.name ? "input-error" : ""}
                    />
                    {formData?.errors?.name && (
                      <span className="error-msg">
                        {formData?.errors?.name}
                      </span>
                    )}
                  </div>
                  <div className="addathlete-col">
                    <label className="form-label">Mobile Number*</label>
                    <div className="country-name-section">
                      <div className="country-code">
                        <FormControl fullWidth>
                          <Select
                            disabled={detail?.details?.isSocialLogin}
                            placeholder="Select"
                            value={formData.countryCode}
                            onChange={onChangeForm("countryCode")}
                          >
                            {countryCodes.map((item, i) => {
                              return (
                                <MenuItem
                                  value={item.countryCodes[0]}
                                  key={i}
                                >{`${item.isoCode3} (+${item.countryCodes[0]})`}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="country-text">
                        <TextField
                          disabled={detail?.details?.isSocialLogin}
                          placeholder="Mobile Number"
                          onChange={onChangeForm("phoneNo")}
                          value={formData.phoneNo}
                          className={
                            formData?.errors?.phoneNo ? "input-error" : ""
                          }
                        />
                        {formData?.errors?.phoneNo && (
                          <span className="error-msg">
                            {formData?.errors?.phoneNo}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="addathlete-col top-spacing">
                    <label className="form-label">Email ID*</label>
                    <TextField
                      disabled={detail?.details?.isSocialLogin}
                      placeholder="Email ID"
                      variant="outlined"
                      onChange={onChangeForm("email")}
                      value={formData.email}
                      className={formData?.errors?.email ? "input-error" : ""}
                    />
                    {formData?.errors?.email && (
                      <span className="error-msg">
                        {formData?.errors?.email}
                      </span>
                    )}
                  </div>
                  <div className="addathlete-col top-spacing">
                    <label className="form-label">User Name*</label>
                    <TextField
                      placeholder="User Name"
                      variant="outlined"
                      onChange={onChangeForm("username")}
                      value={formData.username}
                      className={
                        formData?.errors?.username ? "input-error" : ""
                      }
                    />
                    {formData?.errors?.username && (
                      <span className="error-msg">
                        {formData?.errors?.username}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
  detail: state.athleteInfo?.current,
});
const mapDispatch = (dispatch) => ({
  editAppUser: (data) => dispatch(editAppUser(data)),
  getAthleteDetail: (data) => dispatch(getAthleteDetail(data)),
  getAthleteDetail_: (data) => dispatch(getAthleteDetail_(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  setMenu_: (data) => dispatch(setMenu_(data)),
});
export default withRouter(connect(mapState, mapDispatch)(FanEdit));
