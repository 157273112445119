import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import "./index.css";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Confirmation } from "components/molecules";
import { connect } from "react-redux";
import { showAction } from "utils/helper";
import { updateSupportStatus } from "store/actions";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Support Management Table
 * @param {Object} props : listData : contains list of support requests , alPermissions : keeps the track if the logged in admin have permission to the particular module , updateSupportStatus : contains API to update support status , filter : used to filter on the basis of chosen filters
 * @returns the Support Table Component which will be rendered when this page is called.
 */
const SupportTableList = (props) => {
  const { listData, handleFilterSubmit, alPermissions, updateSupportStatus } =
    props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];
  const [open, setOpen] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {
      // Will do something
    },
  });

  /**
   * This Function is used to open the dialog box
   * @param {object} : This contains type parameter which specific the type of dialog box ,
   * row parameter which contains details about the support
   */
  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };

    if (type === "mark-pending") {
      obj = {
        ...obj,
        title: "Mark as pending",
        description: `Are you sure you want to mark ${
          row.subject === 1
            ? "Subscription Support"
            : row.subject === 2
            ? "Bug Report"
            : row.subject === 3
            ? "Tech Support"
            : row.subject === 4
            ? "Others"
            : ""
        } as pending ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 1,
          };
          updateSupportStatus(apiData).then((res) => {
            if (res) {
              handleFilterSubmit();
              setOpen(false);
            }
          });
        },
      };
    } else if (type === "mark-resolved") {
      obj = {
        ...obj,
        title: "Mark as resolved",
        description: `Are you sure you want to mark ${
          row.subject === 1
            ? "Subscription Support"
            : row.subject === 2
            ? "Bug Report"
            : row.subject === 3
            ? "Tech Support"
            : row.subject === 4
            ? "Others"
            : ""
        } as resolved ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 2,
          };
          updateSupportStatus(apiData).then((res) => {
            if (res) {
              handleFilterSubmit();
              setOpen(false);
            }
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete",
        description: `Are you sure you want to delete ${
          row.subject === 1
            ? "Subscription Support"
            : row.subject === 2
            ? "Bug Report"
            : row.subject === 3
            ? "Tech Support"
            : row.subject === 4
            ? "Others"
            : ""
        } ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 3,
          };
          updateSupportStatus(apiData).then((res) => {
            if (res) {
              handleFilterSubmit();
              setOpen(false);
            }
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }
  return (
    <div className="support table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date & Time</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Status</TableCell>
              {showAction(alPermissions, 11) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Moment format="DD MMM YYYY, h:mm A">{row.createdAt}</Moment>
                </TableCell>
                <TableCell>{row.userData.name}</TableCell>
                <TableCell>{row.userData.email}</TableCell>
                <TableCell>
                  {row.subject === 1
                    ? "Subscription Support"
                    : row.subject === 2
                    ? "Bug Report"
                    : row.subject === 3
                    ? "Tech Support"
                    : row.subject === 4
                    ? "Others"
                    : ""}
                </TableCell>
                <TableCell
                  className={
                    row.status !== 1 ? "status-active" : "status-block"
                  }
                >
                  {row.status === 1 ? "Pending" : "Resolved"}
                </TableCell>
                {showAction(alPermissions, 11) && (
                  <TableCell>
                    <div className="action-wrap">
                      <Tooltip
                        title={`Mark ${
                          row.status === 1 ? "Resolved" : "Pending"
                        }`}
                      >
                        <IconButton
                          onClick={() =>
                            handleClickOpen({
                              type: `mark-${
                                row.status === 1 ? "resolved" : "pending"
                              }`,
                              row,
                            })
                          }
                        >
                          <AssignmentTurnedInIcon
                            color={row.status === 1 ? "success" : "error"}
                          />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title="Detail">
                        <Link to={`/support/${row._id}`}>
                          <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="span"
                          >
                            <RemoveRedEyeOutlinedIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  updateSupportStatus: (data) => dispatch(updateSupportStatus(data)),
});

export default connect(null, mapDispatch)(SupportTableList);
