import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import "./index.css";
import {
  flagUnflagDeleteContent,
  updateReportStatus,
  deleteAds,
} from "store/actions";
import { connect } from "react-redux";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Reported Ads List
 * @param {Object} props : listData : contains list of athletes , alPermissions : keeps the track if the logged in admin have permission to the particular module , flagUnflagDeleteContent : contains API to flag/unflag content , updateReportStatus : : contains API to update report status
 * @returns the Reported Ads Table Component which will be rendered when this page is called.
 */
const AdsTableList = (props) => {
  const { listData } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData.result : [];

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }

  return (
    <div className="ad-tab table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Report Count</TableCell>
              <TableCell>Report Status</TableCell>
              <TableCell>Advertisement Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row._id}</TableCell>
                <TableCell>
                  <Tooltip title={row.adMedia ? row.adMedia : "NA"}>
                    <div>
                      {row.adMedia.length <= 50 && row.adMedia}
                      {row.adMedia.length > 50 &&
                        `${row.adMedia.slice(0, 50)} ...`}
                      {!row.adMedia && "NA"}
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell>{row?.adTitle}</TableCell>
                <TableCell>
                  {row.reportCount} User
                  {[0, 1].includes(row.reportCount) ? "" : "s"}
                </TableCell>
                <TableCell
                  className={`status-${row.status === 2 ? "unblock" : "block"}`}
                >
                  {row.status === 1
                    ? "Pending"
                    : row.status === 2
                    ? "Resolved"
                    : "Deleted"}
                </TableCell>
                <TableCell
                  className={`status-${row.status === 1 ? "unblock" : "block"}`}
                >
                  {row.status === 1
                    ? "Active"
                    : row.status === 2
                    ? "Inactive"
                    : "Deleted"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});

const mapDispatch = (dispatch) => ({
  flagUnflagDeleteContent: (data) => dispatch(flagUnflagDeleteContent(data)),
  updateReportStatus: (data) => dispatch(updateReportStatus(data)),
  deleteAds: (data) => dispatch(deleteAds(data)),
});

export default connect(mapState, mapDispatch)(AdsTableList);
