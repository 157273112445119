import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router";
import { getSubscription } from "store/actions";
import { connect } from "react-redux";
import { Loader } from "components/atoms";
import { convertObjToStringParams } from "utils/helper";
import Moment from "react-moment";
import { PaginationSection } from "components/molecules";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Fan's Subscription Table List
 * @param {Object} props : getSubscription : function containing API call for getting subscription listing, showLoader : show loading state until data is getting fetched.
 * @returns the Fan's Subscription Table Component which will be rendered when this page is called.
 */
const FanSubscriptionTableList = (props) => {
  const { id } = useParams();
  const { getSubscription, showLoader } = props;

  const [listData, setListData] = useState(null);
  let [counter, setCounter] = React.useState(0);

  const [filter, setFilter] = React.useState({
    pageLimit: 10,
    pageNo: 1,
    error: "",
  });

  /**
   * Used to create API Data to send in API call
   * @returns object having id, page, limit and other params values
   */
  const getApiData = () => {
    return {
      page: filter.pageNo,
      limit: filter.pageLimit,
      id,
    };
  };

  /**
   * used to call API to get list of subscription on Fan page
   * @returns false if there is any error in the values
   */
  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getSubscription({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This useEffect is used to call Fan Subscription Listing API when this Component is rendered on the screen
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }

  return (
    <div className="table-wrapper subscription">
      {showLoader && <Loader />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Start Date & Time</TableCell>
              <TableCell>Expire Date & Time</TableCell>
              <TableCell>Cancel Date & Time</TableCell>
              <TableCell>Subscription</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Moment format="DD MMM, YYYY & h:mm A">
                    {row.startDate}
                  </Moment>
                </TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY & h:mm A">
                    {row.expireDate}
                  </Moment>
                </TableCell>
                <TableCell>
                  {row?.cancelDate ? (
                    <Moment format="DD MMM, YYYY & h:mm A">
                      {row.cancelDate}
                    </Moment>
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  <TableCell>
                    {row?.subscriptionType === 1
                      ? "Monthly"
                      : row?.subscriptionType === 2
                      ? "Yearly"
                      : "Free"}
                  </TableCell>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {listData?.result?.length > 0 && (
        <PaginationSection
          filter={filter}
          listData={listData}
          handleChangePage={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
        />
      )}
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
});

const mapDispatch = (dispatch) => ({
  getSubscription: (data) => dispatch(getSubscription(data)),
});

export default connect(mapState, mapDispatch)(FanSubscriptionTableList);
