import React from "react";
import "./index.css";
import FooterPattern from "assets/images/footer-pattern.png";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { getAllStaticContent } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { Loader } from "components/atoms";

/**
 *  This Component contains data detection policy page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getAllStaticContent : function containing API call for getting data detection policy content,
 * @returns the data detection policy component which will be rendered when this page is called.
 */
const DataDetectionpolicys = (props) => {
  const { getAllStaticContent, showLoader } = props;
  const [listData, setListData] = React.useState(null);

  /**
   * This useEffect is used to call fetchDetail function when this Component is rendered on the screen
   */
  React.useEffect(() => {
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This Function is contains API call for getting data detection content.
   */
  const fetchDetail = () => {
    const apiData = { type: 3 };
    const params = convertObjToStringParams(apiData);
    getAllStaticContent({ params }).then((resp) => {
      setListData(resp);
    });
  };
  return (
    <div className="policys-wrapper data-deletion">
      {showLoader && <Loader />}
      <div className="policys-inner">
        {listData?.content && parse(listData?.content)}
      </div>
      <div className="footer-img">
        <figure>
          <img src={FooterPattern} alt="Footer Pattern" />
        </figure>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getAllStaticContent: (data) => dispatch(getAllStaticContent(data)),
});
export default connect(mapState, mapDispatch)(DataDetectionpolicys);
