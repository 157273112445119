import React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import "./index.css";
import {
  getDashboardDetails,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import { connect } from "react-redux";

/**
 * This Component is Dashboard Component
 * @param {Object} props : takes the getDashboardDetails Dashboard Details, toggleMenu_,activateMenuLevel2_
 * keeps the track of active page.
 * @returns the Dashboard component which will be rendered when this page is called.
 */
const Dashboard = (props) => {
  const { getDashboardDetails, toggleMenu_, activateMenuLevel2_ } = props;
  const [detail, setDetail] = React.useState(null);

  /**
   * This useEffect is used to call Dashboard API when Dashboard Component is rendered on the screen
   */
  React.useEffect(() => {
    fetchDetail();
    toggleMenu_({ key: "dashboard" });
    activateMenuLevel2_({ key: "dashboard" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This Function contains the Dashboard Details API call
   */
  const fetchDetail = () => {
    getDashboardDetails().then((resp) => {
      setDetail(resp);
    });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section dashboard">
          <div className="main-section-inner">
            <h3 className="section-title">Users</h3>
            <div className="card-section">
              <div className="card-row">
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Users</h2>
                    <div className="info-value">
                      <p>{detail?.user[0].total ?? 0}</p>
                    </div>
                  </div>
                </div>
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Active Users</h2>
                    <div className="info-value">
                      <p>{detail?.user[0].total_active ?? 0}</p>
                    </div>
                  </div>
                </div>
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Blocked Users</h2>
                    <div className="info-value">
                      <p>{detail?.user[0].total_inactive ?? 0}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="section-title">Athletes</h3>
            <div className="card-section">
              <div className="card-row">
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Athletes</h2>
                    <div className="info-value">
                      <p>{detail?.user[0].ath ?? 0}</p>
                    </div>
                  </div>
                </div>
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Active Athletes</h2>
                    <div className="info-value">
                      <p>{detail?.user[0].ath_active ?? 0}</p>
                    </div>
                  </div>
                </div>
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Blocked Athletes</h2>
                    <div className="info-value">
                      <p>{detail?.user[0].ath_inactive ?? 0}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="section-title">Fans</h3>
            <div className="card-section">
              <div className="card-row">
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Fans</h2>
                    <div className="info-value">
                      <p>{detail?.user[0].fan ?? 0}</p>
                    </div>
                  </div>
                </div>
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Active Fans</h2>
                    <div className="info-value">
                      <p>{detail?.user[0].fan_active ?? 0}</p>
                    </div>
                  </div>
                </div>
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Blocked Fans</h2>
                    <div className="info-value">
                      <p>{detail?.user[0].fan_inactive ?? 0}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="section-title">Videos</h3>
            <div className="card-section">
              <div className="card-row">
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Videos</h2>
                    <div className="info-value">
                      <p>{detail?.video ?? 0}</p>
                    </div>
                  </div>
                </div>
                <div className="card-col">
                  <div className="card-info">
                    <h2 className="info-title">Total Reels</h2>
                    <div className="info-value">
                      <p>{detail?.reel ?? 0}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatch = (dispatch) => ({
  getDashboardDetails: () => dispatch(getDashboardDetails()),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});
export default connect(null, mapDispatch)(Dashboard);
