import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import "./index.css";
import Moment from "react-moment";
import { Confirmation, EditPositionDialog } from "components/molecules";
import { deletePosition, setEditPosition_ } from "store/actions";
import { connect } from "react-redux";
import { convertObjToStringParams, showAction } from "utils/helper";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Sport's Position Table
 * @param {Object} props : listData : contains list of sport's position , alPermissions : keeps the track if the logged in admin have permission to the particular module , deletePosition : contains API to delete sport's position , fetchSportDetail : function containing API to fetch sport's details
 * @returns the Sport's Position Component which will be rendered when this page is called.
 */
const SportsPositionTableList = (props) => {
  const {
    listData,
    deletePosition,
    fetchSportDetail,
    setEditPosition_,
    sport,
    ordinateName,
    alPermissions,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];
  const [open, setOpen] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {
      // Will do something
    },
  });
  const [openEditPosition, setOpenEditPosition] = React.useState(false);

  /**
   * This Function is used to open the dialog box
   * @param {object} : This contains type parameter which specific the type of dialog box ,
   * row parameter which contains details about the sport position
   */
  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "delete") {
      obj = {
        ...obj,
        title: `Delete ${ordinateName}`,
        description: `Are you sure you want to delete ${row.name} ?`,
        clickYes: () => {
          const params = convertObjToStringParams({
            id: sport._id,
            name: row.name,
          });
          deletePosition({ params }).then((res) => {
            if (res) fetchSportDetail();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  const handleEditPositionDialog = ({ row }) => {
    setEditPosition_(row);
    setOpenEditPosition(true);
  };

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }

  return (
    <div className="table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Created on</TableCell>
              {showAction(alPermissions, 10) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY">{row.createdAt}</Moment>
                </TableCell>
                {showAction(alPermissions, 10) && (
                  <TableCell>
                    <div className="action-wrap">
                      <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                          onClick={() => handleEditPositionDialog({ row })}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
      <EditPositionDialog
        open={openEditPosition}
        setOpen={setOpenEditPosition}
        sport={sport}
        fetchSportDetail={fetchSportDetail}
        ordinateName={ordinateName}
      />
    </div>
  );
};
const mapDispatch = (dispatch) => ({
  deletePosition: (data) => dispatch(deletePosition(data)),
  setEditPosition_: (data) => dispatch(setEditPosition_(data)),
});

export default connect(null, mapDispatch)(SportsPositionTableList);
