import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import {
  Login,
  ForgotPassword,
  ResetPassword,
  Dashboard,
  FansList,
  FanDetail,
  FanFollowers,
  FanRanked,
  FanSubscription,
  FanFollowing,
  FanVideoLibrary,
  FanAdd,
  AthletesList,
  AthleteDetail,
  SubAdminsList,
  SubAdminDetail,
  SubAdminAdd,
  VideoFeedList,
  VideoDetail,
  AthletesFollowers,
  AthletesFollowings,
  AthletesVideoLibrary,
  AthletesSubscription,
  AthletesInvitation,
  AthletesAdd,
  NotificationList,
  NotificationDetail,
  AddNotification,
  SportList,
  AddSport,
  SportDetail,
  StaticContentList,
  PrivacyPolicyAdd,
  PrivacyPolicyEdit,
  FaqAdd,
  FaqEdit,
  ProfileEdit,
  ReportedContentList,
  ReportDetailFanUser,
  ReportDetailAd,
  ReportDetailPost,
  EarningsList,
  EditSport,
  Unauthorized,
  SupportList,
  SupportDetail,
  AboutUss,
  PrivacyPolicys,
  DataDetectionPolicys,
  Faqs,
  TermsToUse,
  AthleteRanked,
  AthleteEdit,
  FanEdit,
  FansInvitation,
  WhyAreYouSeeingThisAd,
  SubscriptionPolicy,
  LeaderboardManagement,
  ContentCreators,
} from "components/pages";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import AdminProfileManagement from "components/pages/profile-mgmt";

/** This Router Component keeps track of the paths of all the screens and components */

const Routes = () => {
  return (
    <>
      <Router>
        <Switch>
          <PublicRoute restricted={true} exact path="/" component={Login} />
          <PublicRoute
            restricted={true}
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/fans" component={FansList} />
          <PrivateRoute exact path="/fans/add" component={FanAdd} />
          <PrivateRoute exact path="/fans/:id" component={FanDetail} />
          <PrivateRoute exact path="/fans/:id/edit" component={FanEdit} />
          <PrivateRoute
            exact
            path="/fans/:id/followers"
            component={FanFollowers}
          />
          <PrivateRoute
            exact
            path="/fans/:id/video-rated"
            component={FanRanked}
          />
          <PrivateRoute
            exact
            path="/fans/:id/subscriptions"
            component={FanSubscription}
          />
          <PrivateRoute
            exact
            path="/fans/:id/invitation"
            component={FansInvitation}
          />
          <PrivateRoute
            exact
            path="/fans/:id/followings"
            component={FanFollowing}
          />
          <PrivateRoute
            exact
            path="/fans/:id/video-library"
            component={FanVideoLibrary}
          />
          <PrivateRoute exact path="/athletes" component={AthletesList} />
          <PrivateRoute exact path="/athletes/add" component={AthletesAdd} />
          <PrivateRoute
            exact
            path="/athletes/:id/edit"
            component={AthleteEdit}
          />
          <PrivateRoute exact path="/athletes/:id" component={AthleteDetail} />
          <PrivateRoute
            exact
            path="/athletes/:id/followers"
            component={AthletesFollowers}
          />
          <PrivateRoute
            exact
            path="/athletes/:id/followings"
            component={AthletesFollowings}
          />
          <PrivateRoute
            exact
            path="/athletes/:id/video-library"
            component={AthletesVideoLibrary}
          />
          <PrivateRoute
            exact
            path="/athletes/:id/subscriptions"
            component={AthletesSubscription}
          />
          <PrivateRoute
            exact
            path="/athletes/:id/invitation"
            component={AthletesInvitation}
          />
          <PrivateRoute
            exact
            path="/athletes/:id/video-rated"
            component={AthleteRanked}
          />
          <PrivateRoute exact path="/sub-admins" component={SubAdminsList} />
          <PrivateRoute exact path="/sub-admins/add" component={SubAdminAdd} />
          <PrivateRoute
            exact
            path="/sub-admins/:id"
            component={SubAdminDetail}
          />
          <PrivateRoute exact path="/video-feed" component={VideoFeedList} />
          <PrivateRoute
            exact
            path="/video-feed/:videoId"
            component={VideoDetail}
          />
          <PrivateRoute
            exact
            path="/notifications"
            component={NotificationList}
          />
          <PrivateRoute
            exact
            path="/notifications/add"
            component={AddNotification}
          />
          <PrivateRoute
            exact
            path="/notifications/:id"
            component={NotificationDetail}
          />
          <PrivateRoute exact path="/sports" component={SportList} />
          <PrivateRoute exact path="/sports/add" component={AddSport} />
          <PrivateRoute exact path="/sports/:id" component={SportDetail} />
          <PrivateRoute exact path="/sports/:id/edit" component={EditSport} />
          <PrivateRoute
            exact
            path="/static-content"
            component={StaticContentList}
          />
          <PrivateRoute
            exact
            path="/static-content/:type/add"
            component={PrivacyPolicyAdd}
          />
          <PrivateRoute
            exact
            path="/static-content/:type/edit"
            component={PrivacyPolicyEdit}
          />
          <PrivateRoute
            exact
            path="/static-content/faq-add"
            component={FaqAdd}
          />
          <PrivateRoute
            exact
            path="/static-content/:id/faq-edit"
            component={FaqEdit}
          />
          <PrivateRoute
            exact
            path="/profile"
            component={AdminProfileManagement}
          />
          <PrivateRoute exact path="/profile/edit" component={ProfileEdit} />
          <PrivateRoute
            exact
            path="/reported-content"
            component={ReportedContentList}
          />
          <PrivateRoute
            exact
            path="/reported-content/user/:userId"
            component={ReportDetailFanUser}
          />
          <PrivateRoute
            exact
            path="/reported-content/post/:videoId"
            component={ReportDetailPost}
          />
          <PrivateRoute
            exact
            path="/reported-content/ad/:adId"
            component={ReportDetailAd}
          />
          <PrivateRoute exact path="/earnings" component={EarningsList} />
          <PrivateRoute exact path="/support" component={SupportList} />
          <PrivateRoute exact path="/support/:id" component={SupportDetail} />
          <PrivateRoute exact path="/unauthorized" component={Unauthorized} />
          <PublicRoute exact path="/about-us" component={AboutUss} />
          <PrivateRoute
            exact
            path="/leaderboard"
            component={LeaderboardManagement}
          />
          <PrivateRoute
            exact
            path="/content-creator"
            component={ContentCreators}
          />
          <PrivateRoute
            exact
            path="/reported-content/ad/:adId"
            component={ReportDetailAd}
          />
          <PublicRoute
            exact
            path="/privacy-policy"
            component={PrivacyPolicys}
          />
          <PublicRoute
            exact
            path="/data-deletion-policy"
            component={DataDetectionPolicys}
          />
          <PublicRoute exact path="/faq" component={Faqs} />
          <PublicRoute exact path="/terms-to-use" component={TermsToUse} />
          <PublicRoute
            exact
            path="/why-are-you-seeing-this-ad"
            component={WhyAreYouSeeingThisAd}
          />
          <PublicRoute
            exact
            path="/subscription-policy"
            component={SubscriptionPolicy}
          />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
