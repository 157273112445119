import * as React from "react";
import { withRouter, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { LeftSidebar, HeaderSection } from "components/molecules";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";
import {
  addSports,
  uploadAFile,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import { connect } from "react-redux";
import {
  fileFieldValidator,
  customTextFieldValidator,
} from "utils/validations";
import { Loader } from "components/atoms";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getErrors, showAction, showSection } from "utils/helper";
import { Link } from "react-router-dom";
import { Checkbox, FormGroup } from "@mui/material";

/**
 *  This Component contains Add Sport Page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * addSports : function containing API call for saving created sport Details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * @returns the Add Sport component which will be rendered when this page is called.
 */
const AddSport = (props) => {
  const {
    addSports,
    showLoader,
    uploadAFile,
    alPermissions,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const [formData, setFormData] = React.useState({
    name: "",
    ordinateType: 1,
    image: null,
    uploadedFileUrl: "",
    hideTeamDetails: false,
    errors: {},
  });
  const history = useHistory();

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 10))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Sports management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "sportMgmt" });
    activateMenuLevel2_({ key: "sport" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This Function is called everytime the entered value is changed
   * @param {string} key : It will take the changed value for the input fields and then will validate it.
   */
  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  const handleCheckboxChange = (key) => (e) => {
    setFormData({ ...formData, [key]: e.target.checked });
  };

  /**
   * This Function is used to validate the entered value as per the defined rules
   * @param {string} key : takes the key to be checked
   * @param {string} val : takes the value corresponding to the key passed
   * @returns the error array if any error found
   */
  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "name") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 3 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter name !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            false,
            "Please enter only alphabets with spaces in between !",
          ],
          customRegex: [
            true,
            "Please enter only alphabets, digits, /, &, -, spaces in between !",
            /^[a-z/0-9-& ]+$/i,
          ],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.name = customTextFieldValidator({
        min: [3, "Min. length is 3 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.name,
        req: [true, "Please enter name !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          false,
          "Please enter only alphabets with spaces in between !",
        ],
        customRegex: [
          true,
          "Please enter only alphabets, digits, /, &, -, spaces in between !",
          /^[a-z/0-9-& ]+$/i,
        ],
      });

      newFormState.errors.uploadedFileUrl = fileFieldValidator({
        min: 1,
        max: 100,
        fileType: "image/png",
        fileSizeUnit: "KB",
        multiplier: 1000,
        file: newFormState.image,
        req: true,
      });

      newFormState.errors = getErrors(newFormState?.errors);
    }
    setFormData(newFormState);
    return Object.keys(newFormState.errors).length > 0 ? true : false;
  };

  /**
   * This Function is called when the form click on submit button after all the values are added/edited
   * then this function will validate the values and hit the post API in case of Add and put API in case of edit
   * @returns false if all the values fail to validate
   */
  const handleSubmit = () => {
    if (checkForError()) return false;
    const data = new FormData();
    data.append("files", formData.image);
    uploadAFile(data).then((res) => {
      if (res) {
        let postData = {
          sportImg: res.fileUrl,
          sportName: formData.name.trim(),
          ordinateType: parseInt(formData.ordinateType),
          hideTeamDetails: formData.hideTeamDetails,
        };
        addSports(postData).then((resp) => {
          if (resp) {
            onCloseForm();
            history.push("/sports");
          }
        });
      }
    });
  };

  /**
   * When form is submitted the values are set to empty values
   */
  const onCloseForm = () => {
    setFormData({
      name: "",
      ordinateType: 1,
      image: null,
      uploadedFileUrl: "",
      errors: {},
    });
  };

  /**
   * This Function is called a image is uploaded
   * @param {event} e : This event is triggered when a image is uploaded related to the sport
   */
  const onChangeFile = (e) => {
    const newFormData = { ...formData };

    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    let uploadedFileUrl = "";
    let uploadedFile = e.target.files[0];

    if (uploadedFile) {
      uploadedFileUrl = URL.createObjectURL(e.target.files[0]);
      newFormData.image = e.target.files[0];
      newFormData.uploadedFileUrl = uploadedFileUrl;

      newFormData.errors.uploadedFileUrl = fileFieldValidator({
        min: 1,
        max: 100,
        fileType: "image/png",
        fileSizeUnit: "KB",
        multiplier: 1000,
        file: newFormData.image,
        req: true,
      });
    } else {
      newFormData.image = null;
      newFormData.uploadedFileUrl = "";
      newFormData.errors.uploadedFileUrl = "Please upload a file !";
    }
    setFormData(newFormData);
  };

  /**
   * This Function is used to remove image
   */
  const handleRemoveImage = () => {
    const newFormData = { ...formData };
    newFormData.image = null;
    newFormData.uploadedFileUrl = "";
    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    setFormData(newFormData);
  };

  /**
   * This Function is called when a image is corrupted
   */
  const handleCorruptImage = () => {
    const newFormData = { ...formData };
    newFormData.image = null;
    newFormData.uploadedFileUrl = "";
    newFormData.errors.uploadedFileUrl = "Please upload a valid file format !";

    setFormData(newFormData);
  };

  /**
   * This Function is called when radio button is toggled
   * @param {event} event : This event is triggered when radio buttons are toggled
   */
  const handleChange = (event) => {
    const newFormData = { ...formData };
    newFormData.ordinateType = event.target.value;
    setFormData(newFormData);
  };

  return (
    <div className="pageWrapper">
      {showLoader && <Loader />}
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section add-sport">
          <div className="sport-wrap">
            <div className="sport-title">
              <div className="title-left">
                <Link to={`/sports`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title-info">Add a Sport</h1>
              </div>
              <div className="btn-group">
                {showAction(alPermissions, 10) && (
                  <Button variant="contained" onClick={handleSubmit}>
                    Save
                  </Button>
                )}
              </div>
            </div>
            <div className="sport-info">
              <div className="sport-description">
                <p>Upload a sport icon in PNG</p>
                <div className="upload-wrap">
                  {(!formData.uploadedFileUrl ||
                    formData?.errors?.uploadedFileUrl) && (
                    <div className="upload-file">
                      <input
                        type="file"
                        name="sportImage"
                        onChange={onChangeFile}
                        accept=".png"
                      />
                      <div className="upload-icon">
                        <FileUploadOutlinedIcon />
                      </div>
                    </div>
                  )}
                  {formData.uploadedFileUrl &&
                    !formData?.errors?.uploadedFileUrl && (
                      <div className="upload-image-2">
                        <figure>
                          <img
                            src={formData.uploadedFileUrl}
                            alt="User Icon"
                            onError={handleCorruptImage}
                          />
                        </figure>

                        <div className="close-icon" onClick={handleRemoveImage}>
                          <CloseIcon />
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {formData?.errors?.uploadedFileUrl && (
                <span className="error-msg">
                  {formData?.errors?.uploadedFileUrl}
                </span>
              )}
              <div className="sport-text">
                <label className="sport-label">Sport Name</label>
                <TextField
                  variant="outlined"
                  placeholder="Enter sport name "
                  onChange={onChangeForm("name")}
                  value={formData.name}
                />
              </div>
              {formData?.errors?.name && (
                <span className="error-msg">{formData?.errors?.name}</span>
              )}
              <div className="sport-text">
                <label className="sport-label">Select subordinate type</label>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formData.ordinateType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Positions"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Style"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Discipline"
                  />
                  <FormControlLabel value={4} control={<Radio />} label="N/A" />
                </RadioGroup>
              </div>
              <div className="sport-hide-details">
                <label className="sport-hide-label">Hide Team Details</label>
                <FormControlLabel
                  value={formData?.hideTeamDetails}
                  control={
                    <Checkbox
                      checked={formData?.hideTeamDetails}
                      onChange={handleCheckboxChange("hideTeamDetails")}
                    />
                  }
                  label=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  addSports: (data) => dispatch(addSports(data)),
  uploadAFile: (data) => dispatch(uploadAFile(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});
export default withRouter(connect(mapState, mapDispatch)(AddSport));
