import * as React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import {
  changeStaticContent,
  getAllStaticContent,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import { Loader } from "components/atoms";
import { showSection, convertObjToStringParams } from "utils/helper";
import { Link } from "react-router-dom";

/**
 *  This Component contains Edit Static Content Page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * changeStaticContent : function containing API call for editing static content details,
 * getAllStaticContent : function containing API call for getting static content details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * @returns the Edit Static Content component which will be rendered when this page is called.
 */
const PrivacyPolicyEdit = (props) => {
  const {
    getAllStaticContent,
    alPermissions,
    showLoader,
    changeStaticContent,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const history = useHistory();
  const { type } = useParams();
  const [value, setValue] = React.useState("");

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 4))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Static management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "staticMgmt" });
    activateMenuLevel2_({ key: "content" });
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { type: parseInt(type) };
    const params = convertObjToStringParams(apiData);
    getAllStaticContent({ params }).then((resp) => {
      if (resp) {
        setValue(resp.content);
      }
    });
  };

  /**
   * This Function is called when Submit button on static content edit page is clicked
   * @param {event} e : This will take the event that is trigger when Submit button is clicked.
   * @returns API Call for editing static content.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      type: parseInt(type),
      content: value,
    };
    changeStaticContent(data).then((resp) => {
      if (resp) {
        setValue("");
        history.push(`/static-content?type=${type}`);
      }
    });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="static-content-header">
              <div className="title-left">
                <Link to={`/static-content?type=${type}`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title">Edit</h1>
              </div>
              <div className="btn-group">
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </div>
            <div className="text-editor">
              <ReactQuill theme="snow" onChange={setValue} value={value} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  changeStaticContent: (data) => dispatch(changeStaticContent(data)),
  getAllStaticContent: (data) => dispatch(getAllStaticContent(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});

export default connect(mapState, mapDispatch)(PrivacyPolicyEdit);
