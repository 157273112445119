import * as React from "react";
import { UnauthorizedPage } from "components/atoms";

/**
 * This Component contains unauthorized page
 * @returns the Component to be rendered when any unauthorized route is called
 */
const Unauthorized = () => {
  return (
    <div>
      <UnauthorizedPage />
    </div>
  );
};

export default Unauthorized;
