import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  ProfileInfo,
  PasswordInfo,
} from "components/molecules";
import { Loader } from "components/atoms";
import { connect } from "react-redux";
import {
  changeMyPassword,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";

/**
 *  This Component contains Admin Profile page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * setMenu_,activateMenuLevel2_ : keeps the track of active page,
 * changeMyPassword : function to change password
 * @returns the privacy policy component which will be rendered when this page is called.
 */
const AdminProfileManagement = (props) => {
  const {
    changeMyPassword,
    showLoader,
    detail,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;

  /**
   * This useEffect is used to set Active Module as Dashboard
   */
  React.useEffect(() => {
    toggleMenu_({ key: "dashboard" });
    activateMenuLevel2_({ key: "dashboard" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">My Profile</h1>
              </div>
            </div>
            <ProfileInfo info={detail} />
            <PasswordInfo changeMyPassword={changeMyPassword} />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state?.authInfo?.auth,
});
const mapDispatch = (dispatch) => ({
  changeMyPassword: (data) => dispatch(changeMyPassword(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});

export default connect(mapState, mapDispatch)(AdminProfileManagement);
