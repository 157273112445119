import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NearMeIcon from "@mui/icons-material/NearMe";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { ColumnWithSort } from "components/atoms";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Confirmation } from "components/molecules";
import { deleteNotification, sendNotification } from "store/actions";
import { connect } from "react-redux";
import {
  showAction,
  convertObjToStringParams,
  getReceiverType,
} from "utils/helper";
import "./index.css";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Notification List
 * @param {Object} props : listData : contains list of notifications , alPermissions : keeps the track if the logged in admin have permission to the particular module , deleteNotification : contains API to delete notification ,  sendNotification : contains API to send notification
 * @returns the Notification Table Component which will be rendered when this page is called.
 */
const NotificationList = (props) => {
  const {
    listData,
    filter,
    toggleSortOrder,
    deleteNotification,
    handleFilterSubmit,
    alPermissions,
    sendNotification,
  } = props;

  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];

  const [open, setOpen] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {
      // Will do something
    },
  });

  /**
   * This Function is used to open the dialog box
   * @param {object} : This contains type parameter which specific the type of dialog box ,
   * row parameter which contains details about the notification
   */
  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete notification",
        description: `Are you sure you want to delete ${row.title} ?`,
        clickYes: () => {
          const apiData = { id: row._id };
          const params = convertObjToStringParams(apiData);
          deleteNotification({ params }).then((res) => {
            if (res) {
              handleFilterSubmit();
              setOpen(false);
            }
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Send notification",
        description: `Are you sure you want to execute ${row.title} ?`,
        clickYes: () => {
          const apiData = { id: row._id };
          sendNotification(apiData).then((res) => {
            if (res) {
              setOpen(false);
            }
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }

  return (
    <div className="table-wrapper notification-list">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Sent to</TableCell>
              <TableCell>
                <ColumnWithSort
                  title="Date"
                  sortBy={filter?.sortBy}
                  currentKey="createdAt"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>
              {showAction(alPermissions, 8) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link to={`/notifications/${row._id}`}>
                    <Tooltip title={row?.title}>
                      <span>
                        {row.title.length <= 25 && row.title}
                        {row.title.length > 25 &&
                          `${row.title.slice(0, 25)} ...`}
                      </span>
                    </Tooltip>
                  </Link>
                </TableCell>
                <TableCell>{getReceiverType(row.receiverType)}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY">{row.createdAt}</Moment>
                </TableCell>
                {showAction(alPermissions, 8) && (
                  <TableCell>
                    <div className="action-wrap">
                      <Tooltip title="Send">
                        <IconButton
                          onClick={() => handleClickOpen({ type: "send", row })}
                        >
                          <NearMeIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};
const mapDispatch = (dispatch) => ({
  deleteNotification: (data) => dispatch(deleteNotification(data)),
  sendNotification: (data) => dispatch(sendNotification(data)),
});
export default connect(null, mapDispatch)(NotificationList);
