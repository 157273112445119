import Routes from "routes";
import "./App.css";
import { NotificationBar } from "components/atoms";

/**
 * 
 * @returns all the public and private routing path of the admin with it's component.
 */

function App() {
  return (
    <div className="App">
      <Routes />
      <NotificationBar />
    </div>
  );
}

export default App;
