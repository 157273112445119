import React, { useState } from "react";
import {
  VideoReportedTableList,
  PaginationSection,
} from "components/molecules";
import TextField from "@mui/material/TextField";
import { Loader } from "components/atoms";
import { getContentReports } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { connect } from "react-redux";

/**
 * This Component contains Video Reported Tab
 * @param {Object} props :  getContentReports : function containing API call for getting post reports, showLoader : shows loading state until data is getting fetched, contentId : stores video id whose rating is needed
 * @returns the Video Reported Tab Component which will be rendered when this page is called.
 */
const ReportedTabSection = (props) => {
  const {
    getContentReports,
    showLoader,
    contentId,
    reportPage,
    parentCounter,
    fetchPageDetail,
  } = props;
  const [listData, setListData] = useState(null);

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: 10,
    pageNo: 1,
    error: "",
  });

  /**
   * This useEffect is used to call Reported Tab API when this Component is rendered on the screen
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (parentCounter) {
      handleFilterSubmit();
    }
  }, [parentCounter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Used to create API Data to send in API call
   * @returns object having id, page, limit and search
   */
  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      id: contentId,
    };
    if (filter.search) apiData.search = filter.search;
    return apiData;
  };

  /**
   * used to call API to get list of reported posts
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getContentReports({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  /**
   * Debouncing For Search
   */
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="reported-wrap">
      {showLoader && <Loader />}
      <div className="search-filter-wrap">
        <div className="serach-wrap">
          <TextField
            label="Search"
            variant="outlined"
            placeholder="Search by reported by"
            onChange={handleSearch}
            value={filter?.search}
          />
        </div>
      </div>
      <VideoReportedTableList
        listData={listData}
        filter={filter}
        reportPage={reportPage}
        handleFilterSubmit={handleFilterSubmit}
        fetchPageDetail={fetchPageDetail}
      />
      {listData?.result?.length > 0 && (
        <PaginationSection
          filter={filter}
          listData={listData}
          handleChangePage={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
        />
      )}
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getContentReports: (data) => dispatch(getContentReports(data)),
});
export default connect(mapState, mapDispatch)(ReportedTabSection);
