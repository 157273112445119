import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import "./index.css";
import PropTypes from "prop-types";
import Moment from "react-moment";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Earning Table List
 * @param {Object} props : listData : contains list of earnings
 * @returns the Earning Table Component which will be rendered when this page is called.
 */
const EarningTableList = (props) => {
  const { listData } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }

  return (
    <div className="table-wrapper earning-list">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Subscription Plan</TableCell>
              <TableCell>Transaction Date & Time</TableCell>
              <TableCell>User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {row?.subscriptionType === 1
                    ? "Monthly"
                    : row?.subscriptionType === 2
                    ? "Yearly"
                    : "Free"}
                </TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY & h:mm A">
                    {row.createdAt}
                  </Moment>
                </TableCell>
                <TableCell>
                  {row.user.length > 0 ? (
                    <Link
                      to={`/${
                        row.user[0]?.userType === 1 ? "fans" : "athletes"
                      }/${row.user[0]?._id}`}
                    >
                      {row.user[0]?.name}
                    </Link>
                  ) : (
                    "Fivestar User"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

EarningTableList.propTypes = {
  listData: PropTypes.any,
};

export default EarningTableList;
