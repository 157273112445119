import * as React from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { LeftSidebar, HeaderSection, Confirmation } from "components/molecules";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "./index.css";
import { connect } from "react-redux";
import {
  getSubadmin,
  updateSubadminStatus,
  editSubadminPermissions,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import {
  convertObjToStringParams,
  showAction,
  showSection,
} from "utils/helper";
import { Loader } from "components/atoms";
import { modulesArr, permissionsArr } from "utils/helper";
import User from "assets/images/user-pic.png";
import { Link } from "react-router-dom";

/**
 *  This Component contains Subadmin detail
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getSubadmin : function containing API call for getting Subadmin details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * updateSubadminStatus : function containing API call for updating Subadmin details,
 * @returns the Subadmin detail component which will be rendered when this page is called.
 */
const SubAdminDetail = (props) => {
  const history = useHistory();
  const {
    getSubadmin,
    showLoader,
    updateSubadminStatus,
    detail,
    editSubadminPermissions,
    alPermissions,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {
      // Will do something
    },
  });
  const [formData, setFormData] = React.useState({
    errors: {},
    permissions: [],
  });

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 3))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as SubAdmin management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "subadminMgmt" });
    activateMenuLevel2_({ key: "subadmins" });
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = { id };
    const params = convertObjToStringParams(apiData);
    getSubadmin({ params }).then((resp) => {
      const newFormData = { ...formData };
      newFormData.permissions = resp.permissions;
      setFormData(newFormData);
    });
  };

  /**
   * This Function is used to open the dialog box
   * @param {object} : This contains type parameter which specific the type of dialog box
   */
  const handleClickOpen = ({ type }) => {
    let obj = { ...confirmationInfo };
    if (type === "activate") {
      obj = {
        ...obj,
        title: "Activate user",
        description: `Are you sure you want to activate ${detail?.username} ?`,
        clickYes: () => {
          const apiData = {
            id: id,
            status: 1,
          };
          updateSubadminStatus(apiData).then((res) => {
            if (res) fetchDetail();
            setOpen(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete user",
        description: `Are you sure you want to delete ${detail?.username} ?`,
        clickYes: () => {
          const apiData = {
            id: id,
            status: 3,
          };
          updateSubadminStatus(apiData).then((res) => {
            if (res) {
              setOpen(false);
              history.push("/sub-admins");
            }
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Block user",
        description: `Are you sure you want to block ${detail?.username} ?`,
        clickYes: () => {
          const apiData = {
            id: id,
            status: 2,
          };
          updateSubadminStatus(apiData).then((res) => {
            if (res) fetchDetail();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  const toggleEditMode = () => {
    const newFormData = { ...formData };
    if (editMode) {
      if (newFormData.permissions.length > 0) {
        const apiData = {
          id,
          permissions: newFormData.permissions,
        };
        editSubadminPermissions(apiData).then((resp) => {
          if (resp) fetchDetail();
        });
      } else {
        newFormData.errors.permissions =
          "Please select atleast one permission !";
        setFormData(newFormData);
        return true;
      }
    }
    setEditMode((prevVal) => !prevVal);
  };

  /**
   * This Function is called when checkbox is selected/changed
   * @param {Object} item : contains value selected by the admin
   * @returns the value selected in the form of state
   */
  const handleCheckboxChange = (item) => (e) => {
    const newFormData = { ...formData };
    if (newFormData.errors.permissions) delete newFormData.errors.permissions;
    if (e.target.checked) {
      newFormData.permissions.push({
        name: item.value,
        value: 1,
      });
    } else {
      newFormData.permissions = newFormData.permissions.filter((it) => {
        if (it.name === item.value) return false;
        return true;
      });
    }
    setFormData(newFormData);
  };

  /**
   * This Function is called to check if checkbox is selected
   * @param {*} item : contains status of all checkboxs
   * @returns true if checkbox is checked and false if checkbox is not checked
   */
  const isCheckBoxChecked = (item) => {
    const newFormData = { ...formData };
    const found = newFormData.permissions.find((it) => {
      if (it.name === item.value) return true;
      return false;
    });
    return found ? true : false;
  };

  const getRadioValue = (item) => {
    const newFormData = { ...formData };
    const found = newFormData.permissions.find((it) => {
      if (it.name === item.value) return true;
      return false;
    });
    return found ? found.value : "";
  };

  const handleChangeRadio = (item) => (e) => {
    const newFormData = { ...formData };

    newFormData.permissions = newFormData.permissions.map((it) => {
      if (it.name === item.value) {
        it.value = parseInt(e.target.value);
      }
      return it;
    });
    setFormData(newFormData);
  };

  /**
   * This Function is used to set default image
   */
  const showDefaultImage = (e) => {
    e.target.src = User;
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          <div className="subadmin-wrap">
            {showLoader && <Loader />}
            <div className="subadmin-title">
              <div className="title-left">
                <Link to={`/sub-admins`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title">Admin Detail</h1>
              </div>
              {showAction(alPermissions, 3) && (
                <div className="btn-group">
                  <Button
                    color={detail?.status === 1 ? "error" : "success"}
                    variant="outlined"
                    onClick={() =>
                      handleClickOpen({
                        type: detail?.status === 1 ? "de-activate" : "activate",
                      })
                    }
                  >
                    {detail?.status === 1 ? "Block" : "Activate"}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => handleClickOpen({ type: "delete" })}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
            <div className="user-wrap">
              <h3 className="section-heading">Account Details</h3>
              <div className="subadmin-profile-inner">
                <div className="subadmin-profile-left">
                  <div className="subadmin-profile-image">
                    <figure>
                      <img
                        src={detail?.profilePhoto ? detail?.profilePhoto : User}
                        alt="User"
                        onError={showDefaultImage}
                      />
                    </figure>
                  </div>
                </div>
                <div className="subadmin-profile-right">
                  <div className="user-inner">
                    <div className="user-row">
                      <div className="user-col">
                        <label className="user-detail">Name</label>
                        <p>{detail?.username}</p>
                      </div>
                      <div className="user-col">
                        <label className="user-detail">Mobile Number</label>
                        <p>
                          {detail?.phoneNo !== "NA" &&
                          detail?.countryCode !== "NA"
                            ? `${detail?.countryCode} ${detail?.phoneNo}`
                            : "NA"}
                        </p>
                      </div>
                      <div className="user-col">
                        <label className="user-detail">Email Address</label>
                        <p>{detail?.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="permission-form form">
              <div className="form-inner">
                <div className="form-left">
                  <h3 className="section-heading">Permissions</h3>
                  {formData?.errors?.permissions && (
                    <span className="error-msg">
                      {formData?.errors?.permissions}
                    </span>
                  )}
                </div>
                {showAction(alPermissions, 3) && (
                  <div className="btn-group">
                    <Button variant="contained" onClick={toggleEditMode}>
                      {!editMode ? "Edit" : "Save"}
                    </Button>
                  </div>
                )}
              </div>
              {modulesArr.map((per, i) => {
                return (
                  <div className="add-form-row" key={per.name + i}>
                    <div className="add-form-col form-checkbox">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCheckBoxChecked(per)}
                              onChange={handleCheckboxChange(per)}
                            />
                          }
                          label={per.name}
                          disabled={!editMode}
                        />
                      </FormGroup>
                    </div>
                    <div className="add-form-col radio-btn">
                      <RadioGroup
                        row
                        aria-label="edit"
                        name="row-radio-buttons-group"
                        value={getRadioValue(per)}
                        onChange={handleChangeRadio(per)}
                      >
                        <FormControlLabel
                          value={permissionsArr[0].value}
                          control={<Radio />}
                          label={permissionsArr[0].name}
                          disabled={!editMode || !isCheckBoxChecked(per)}
                        />
                        <FormControlLabel
                          value={permissionsArr[1].value}
                          control={<Radio />}
                          label={permissionsArr[1].name}
                          disabled={!editMode || !isCheckBoxChecked(per)}
                        />
                      </RadioGroup>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.subadminInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getSubadmin: (data) => dispatch(getSubadmin(data)),
  updateSubadminStatus: (data) => dispatch(updateSubadminStatus(data)),
  editSubadminPermissions: (data) => dispatch(editSubadminPermissions(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});

export default withRouter(connect(mapState, mapDispatch)(SubAdminDetail));
