import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

/** Public Route - This is the global component which makes the route Public so 
 * can be accessed without getting logged in to the admin or without Access token
 * */

class PublicRoute extends Component {
  render() {
    const { component: Component, restricted, ...rest } = this.props;
    let isLoggedIn = localStorage.getItem("token") ? true : false;

    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn && restricted ? (
            <Redirect to="/dashboard" />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }
}
PublicRoute.propTypes = {
  component: PropTypes.any,
  isLoggedIn: PropTypes.any,
  restricted: PropTypes.any,
};
const mapState = (state) => {
  return {
    isLoggedIn: state?.authInfo?.auth?.authToken || false,
  };
};

export default connect(mapState, null)(PublicRoute);
