import "./index.css";
import DataNotFound from "assets/images/data-not-found.png";

/**
 * This Component contains No Data Component which is shown if data is empty.
 * @param {Object} props : listData : list of data
 * @returns The Loader
 */
const NoData = (props) => {
  const { listData } = props;
  return (
    <div className="not-search-found">
      {listData?.result !== undefined && (
        <>
          <figure>
            <img src={DataNotFound} alt={"Data Not Found"} />
          </figure>
          <h3>No Data Found</h3>
        </>
      )}
    </div>
  );
};

export default NoData;
