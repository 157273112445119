import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./index.css";
import { ColumnWithSort } from "components/atoms";
import Moment from "react-moment";
import moment from "moment";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Notification Detail Table/Selected Users
 * @param {Object} props : listData : contains list of athletes , filter : used to filter values on the basis of filters selected
 * @returns the  Notification Detail Table which will be rendered when this page is called.
 */
const NotificationDetailTableList = (props) => {
  const { listData, filter, toggleSortOrder } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];

  if (rows.length === 0) {
    return <NoData listData={listData} />;

  }
  return (
    <div className="table-wrapper detail-notification-list">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Email ID</TableCell>
              <TableCell>
                <ColumnWithSort
                  title="Added On"
                  sortBy={filter?.sortBy}
                  currentKey="addedOn"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.userType === 1 ? "Fan" : "Athlete"}</TableCell>
                <TableCell>
                  {row?.phoneNo !== "NA" && row?.countryCode !== "NA"
                    ? `${row?.countryCode} ${row?.phoneNo}`
                    : "NA"}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY">{row.createdAt}</Moment>
                </TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>
                  {row?.subscription?.expireDate &&
                  moment().format("YYYY-MM-DD") <=
                    moment(row?.subscription.expireDate).format("YYYY-MM-DD")
                    ? "Subscribed"
                    : "Free"}
                </TableCell>
                <TableCell
                  className={
                    row.status === 1 ? "status-active" : "status-block"
                  }
                >
                  {row.status === 1 ? "Active" : "Blocked"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default NotificationDetailTableList;
