import { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import "./index.css";
import { AccountLeftSection } from "components/molecules";
import { passwordFieldValidator, emailFieldValidator } from "utils/validations";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "store/actions";
import { Loader } from "components/atoms";
import { getErrors } from "utils/helper";

/**
 * This Component is Login Component
 * @param {Object} props : It takes showLoader in this object which will show loading state until data is getting fetched.
 * and this login user will keep the information of the logged user.
 * @returns the Login component which will be rendered when this page is called.
 */
const Login = (props) => {
  const { loginUser, showLoader } = props;
  const [fieldType, setFieldType] = useState("password");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    errors: {},
  });

  /**
   * This will toggle the visible/Non visible part of password when clicked on eye icon in password
   */
  const toggleFieldType = () => {
    if (fieldType === "password") {
      setFieldType("text");
    } else {
      setFieldType("password");
    }
  };

  /**
   * This Function is called when Login button on Login Page is clicked
   * @param {event} e : This will take the event that is trigger when Login button is clicked.
   * @returns API Call for getting logged user Information.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkForError()) return false;
    const data = {
      email: formData.email.toLowerCase(),
      password: formData.password,
    };
    loginUser(data).then((res) => {
      if (res) onCloseForm();
    });
  };

  /**
   * When form is submitted the values are set to empty values
   */
  const onCloseForm = () => {
    setFormData({
      email: "",
      password: "",
      errors: {},
    });
  };

  /**
   * This Function is called everytime the entered value is changed
   * @param {string} key : It will take the changed value for the input fields and then will validate it.
   */
  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  /**
   * This Function is used to validate the entered value as per the defined rules
   * @param {string} key : takes the key to be checked
   * @param {string} val : takes the value corresponding to the key passed
   * @returns the error array if any error found
   */
  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "password") {
        err = passwordFieldValidator(8, 40, val, true);
      } else if (key === "email") {
        err = emailFieldValidator(val, true);
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.password = passwordFieldValidator(
        8,
        40,
        newFormState.password,
        true
      );
      newFormState.errors.email = emailFieldValidator(newFormState.email, true);
      newFormState.errors = getErrors(newFormState?.errors);
    }
    setFormData(newFormState);
    return Object.keys(newFormState.errors).length > 0 ? true : false;
  };

  return (
    <div className="accountWrapper">
      {showLoader && <Loader />}
      <div className="accountInner">
        <AccountLeftSection />
        <div className="right-section">
          <div className="right-inner">
            <h1 className="page-title">Welcome Back!</h1>
            <p className="page-description">Login to your account</p>
            <div className="form-wrapper normal-btn">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="input-row">
                  <TextField
                    id="email"
                    label="Email address"
                    variant="outlined"
                    onChange={onChangeForm("email")}
                    value={formData.email}
                    className={formData?.errors?.email ? "input-error" : ""}
                  />
                  {formData?.errors?.email && (
                    <span className="error-msg">{formData?.errors?.email}</span>
                  )}
                </div>
                <div className="password-row">
                  <div className="form-group">
                    <TextField
                      type={fieldType}
                      id="password"
                      label="Password"
                      variant="outlined"
                      onChange={onChangeForm("password")}
                      value={formData.password}
                      className={
                        formData?.errors?.password ? "input-error" : ""
                      }
                    />
                    <div className="eye-icon" onClick={toggleFieldType}>
                      <IconButton aria-label="toggle password visibility">
                        {fieldType === "password" ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </div>
                  </div>
                  {formData?.errors?.password && (
                    <span className="error-msg">
                      {formData?.errors?.password}
                    </span>
                  )}
                </div>
                <div className="forgot-link">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <div className="btn-wrap login-btn">
                  <Button variant="contained" type="submit">
                    Login
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
});
const mapDispatch = (dispatch) => ({
  loginUser: (data) => dispatch(loginUser(data)),
});

Login.propTypes = {
  loginUser: PropTypes.func,
  showLoader: PropTypes.bool,
};
export default withRouter(connect(mapState, mapDispatch)(Login));
