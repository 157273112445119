import * as React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import {
  LeftSidebar,
  HeaderSection,
  VideoTableList,
  PaginationSection,
} from "components/molecules";
import { FilterSubmit, FilterReset, Loader } from "components/atoms";
import Popover from "@mui/material/Popover";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import "./index.css";
import {
  getAllVideoFeeds,
  toggleMenu_,
  activateMenuLevel2_,
  getVideoPaginationDetail_,
} from "store/actions";
import { convertObjToStringParams, showSection } from "utils/helper";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ratingValidator } from "utils/validations";

/**
 *  This Component contains Video Listing
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getAllVideoFeeds : function containing API call for getting all the Videos,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * toggleMenu_,activateMenuLevel2_ : keeps the track of active page.
 * page,limit : The page keep track of the pageNo called during API call and limit tracks the no. of records we need
 * @returns the Video Listing component which will be rendered when this page is called.
 */
const VideoFeedList = (props) => {
  const {
    getAllVideoFeeds,
    showLoader,
    alPermissions,
    toggleMenu_,
    activateMenuLevel2_,
    getVideoPaginationDetail_,
    page,
    limit,
  } = props;
  const [listData, setListData] = React.useState(null);
  const [value, setValue] = React.useState([null, null]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const history = useHistory();

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: limit,
    pageNo: page,
    sortBy: { key: "addedOn", val: -1 },
    rating: "",
    error: "",
  });

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 6))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to call handleFilterSubmit
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Feed management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "feedMgmt" });
    activateMenuLevel2_({ key: "feed" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Used to open filter popup on click of filter icon
   * @param {event} event : This event parameter keeps the value of current target
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Used to close the filter Popup
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Used to create API Data to send in API call
   * @returns object having id, page, limit and selected filter values
   */
  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      sortKey: filter.sortBy.key,
      sortOrder: filter.sortBy.val,
    };
    if (value[0]) apiData.from = value[0];
    if (value[1]) apiData.to = value[1];
    if (filter.search) apiData.search = filter.search;
    if (filter.rating) apiData.rating = filter.rating;
    return apiData;
  };

  /**
   * used to call API to get list of videos
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    setAnchorEl(null);
    getAllVideoFeeds({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  /**
   * Debouncing For Search
   */
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  /**
   * This is used to set the filter values on the basis of which listing will be sorted.
   * @param {string} key : used to send the key whose value needs to be changed
   */
  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;
    if (key === "rating") {
      newFilter.error = ratingValidator(e.target.value);
    }
    setFilter(newFilter);
  };

  /**
   * This Function is used to reset the selected values in filter
   */
  const handleFilterReset = () => {
    const newFilter = { ...filter };
    newFilter.error = "";
    newFilter.rating = "";
    setValue([null, null]);
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
    getVideoPaginationDetail_({
      limit: val,
      page: newFilter.pageNo,
    });
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
    getVideoPaginationDetail_({
      page: val,
      limit: newFilter.pageLimit,
    });
  };

  /**
   * This Function is used to sort any column in table
   * @param {Object} obj : takes the key of column to sort and it's sorting order ascending/desending
   */
  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        {showLoader && <Loader />}
        <LeftSidebar />
        <div className="main-section">
          <div className="main-section-inner video-list">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Feed Management</h1>
              </div>
            </div>
            <div className="search-filter-wrap">
              <div className="search-sub-wrap">
                <div className="search-wrap">
                  <TextField
                    label="Search"
                    variant="outlined"
                    placeholder="Search by caption"
                    onChange={handleSearch}
                    value={filter?.search}
                  />
                </div>
                <h2 className="video-info-title">
                  Total videos : <span>{listData?.Total_count ?? 0}</span>
                </h2>
              </div>

              <div className="filter-btn">
                <Tooltip title="Filter">
                  <IconButton
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    color="primary"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <div className="filterPopup">
                    <label className="form-label">Created date</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateRangePicker
                        startText="From"
                        endText="To"
                        value={value}
                        open={datePickerOpen}
                        onChange={(newValue) => {
                          setValue(newValue);
                          if (newValue[0] !== null && newValue[1] !== null) {
                            setDatePickerOpen(false);
                          }
                        }}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextField
                              onClick={() => {
                                setDatePickerOpen(true);
                              }}
                              {...startProps}
                            />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField
                              onClick={() => {
                                setDatePickerOpen(true);
                              }}
                              {...endProps}
                            />
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                    <div className="filter-text">
                      <label className="form-label">Highlight Rating</label>
                      <TextField
                        label="Select Rating"
                        variant="outlined"
                        value={filter?.rating}
                        onChange={handleChangeFilter("rating")}
                        placeholder="Enter Rating"
                        className={filter.error ? "input-error" : ""}
                      />
                      {filter.error && (
                        <span className="error-msg">{filter.error}</span>
                      )}
                    </div>
                    <div className="btn-wrap">
                      <FilterReset
                        name="Reset"
                        onClickButton={handleFilterReset}
                      />
                      <FilterSubmit
                        name="Apply Filter"
                        onClickButton={handleFilterSubmit}
                      />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            <VideoTableList
              listData={listData}
              filter={filter}
              toggleSortOrder={toggleSortOrder}
              handleFilterSubmit={handleFilterSubmit}
            />
            {listData?.result?.length > 0 && (
              <PaginationSection
                limit={limit}
                mainSection={true}
                listData={listData}
                handleChangePage={handleChangePage}
                handleChangePageLimit={handleChangePageLimit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
  page: state?.videoFeedInfo?.page,
  limit: state?.videoFeedInfo?.limit,
});
const mapDispatch = (dispatch) => ({
  getAllVideoFeeds: (data) => dispatch(getAllVideoFeeds(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
  getVideoPaginationDetail_: (data) =>
    dispatch(getVideoPaginationDetail_(data)),
});
export default connect(mapState, mapDispatch)(VideoFeedList);
