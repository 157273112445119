import * as React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import User from "assets/images/user-pic.png";
import "./index.css";

/**
 * This Component shows Profile details
 * @param {Object} props : info : containing admin's information
 * @returns Profile details component
 */
const ProfileInfo = (props) => {
  const { info } = props;

  /**
   * This Function is used to set default image
   */
  const showDefaultImage = (e) => {
    e.target.src = User;
  };

  return (
    <div className="profile-info">
      <div className="profile-inner">
        <div className="profile-left">
          <div className="profile-image">
            <figure>
              <img
                src={info?.profilePhoto ? info?.profilePhoto : User}
                alt="User"
                onError={showDefaultImage}
              />
            </figure>
          </div>
        </div>
        <div className="profile-right">
          <div className="profile-inner-row">
            <div className="profile-col">
              <label className="profile-detail">Name</label>
              <p>{info?.username}</p>
            </div>
            <div className="profile-col">
              <label className="profile-detail">Mobile Number</label>
              <p>
                {info?.phoneNo !== "NA" && info?.countryCode !== "NA"
                  ? `${info?.countryCode} ${info?.phoneNo}`
                  : "NA"}
              </p>
            </div>
            <div className="profile-col top-spacing">
              <label className="profile-detail">Email Address</label>
              <p>{info?.email}</p>
            </div>
          </div>
        </div>
        <div className="btn-wrap">
          <Link to="/profile/edit">
            <Button variant="contained">Edit</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
