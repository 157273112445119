import "./index.css";
import LoaderIcon from "assets/images/loader.svg";

/**
 * This Component contains Loader which is shown until data is getting fetched.
 * @param {Object} props : relative : tells it's position relative/absolute
 * @returns The Loader
 */
const Loader = (props) => {
  const { relative } = props;

  return (
    <div
      className={`loader-wrap ${relative ? "loader-relative" : "loader-fixed"}`}
    >
      <div className="loader-inner">
        <figure>
          <img src={LoaderIcon} alt={"Loader"} />
        </figure>
      </div>
    </div>
  );
};

export default Loader;
