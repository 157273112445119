import PropTypes from "prop-types";
import Button from "@mui/material/Button";

/**
 * This Component contains Reset Button
 * @param {Object} props : name : name of the button , onClickButton : function to be triggered on click of button
 * @returns the Reset Button which will be rendered when this component is called.
 */
const FilterReset = (props) => {
  const { name, customClass, onClickButton } = props;
  return (
    <Button
      variant="outlined"
      color="error"
      className={customClass}
      onClick={onClickButton}
    >
      {name}
    </Button>
  );
};

FilterReset.propTypes = {
  name: PropTypes.string,
  customClass: PropTypes.string,
  onClickButton: PropTypes.func,
};

export default FilterReset;
