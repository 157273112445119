import {
    GET_LEADERBOARD_DETAIL,
    SET_LEADERBOARD_INITIAL,
    SET_LEADERBOARD_PAGINATION,
  } from "store/types";
  
  const initialState = {
    current: null,
    page: 1,
    limit: 10,
  };
  
  const leaderboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_LEADERBOARD_DETAIL:
        state.current = action.payload;
        return { ...state };
  
      case SET_LEADERBOARD_PAGINATION:
        return {
          ...state,
          page: action.payload.page,
          limit: action.payload.limit,
        };
  
      case SET_LEADERBOARD_INITIAL:
        return { ...initialState };
  
      default:
        return state;
    }
  };
  
  export default leaderboardReducer;
  