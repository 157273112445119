import React, { useState } from "react";
import { ModerationTableList } from "components/molecules";
import { Loader } from "components/atoms";
import { getModerationKeys, refreshModeration } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { connect } from "react-redux";
import "./index.css";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";

/**
 * This Component contains Moderation Details
 * @param {Object} props : getModerationKeys : function containing API call for getting moderation keys, showLoader : shows loading state until data is getting fetched, refreshModeration : function containing API call to check if moderation is complete or not
 * @returns the Moderation Details Component which will be rendered when this page is called.
 */
const ModerationSection = (props) => {
  const { getModerationKeys, showLoader, contentId, refreshModeration } = props;
  const [listData, setListData] = useState(null);

  /**
   * This useEffect is used to call Moderation Section API when this Component is rendered on the screen
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Used to create API Data to send in API call
   * @returns object having id
   */
  const getApiData = () => {
    return {
      id: contentId,
    };
  };

  /**
   * used to call API to get list of moderation keys
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getModerationKeys({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  const handleClick = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    refreshModeration({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  return (
    <div className="reported-wrap">
      {showLoader && <Loader />}
      {(listData === undefined ||
        listData?.moderationData?.JobStatus === 2) && (
        <div className="moderator-loader">
          <label className="moderator-label">Moderation in Progress</label>
          <div className="loader-btn">
            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={handleClick}
            >
              <ReplayIcon />
            </IconButton>
          </div>
        </div>
      )}
      {listData !== undefined && listData?.moderationData?.JobStatus !== 2 && (
        <>
          <div className="report-info-section">
            <div className="flex-row">
              <div className="flex-col">
                <label>
                  Color Range:
                  {listData?.moderationData?.VideoMetadata?.ColorRange}
                </label>
              </div>
              <div className="flex-col">
                <label>
                  Duration Millis:
                  {listData?.moderationData?.VideoMetadata?.DurationMillis}
                </label>
              </div>
              <div className="flex-col">
                <label>
                  Status:
                  {listData?.moderationData?.JobStatus === 1
                    ? "Succeeded"
                    : listData?.moderationData?.JobStatus === 2
                    ? "In Progress"
                    : listData?.moderationData?.JobStatus}
                </label>
              </div>
            </div>
            <div className="flex-row">
              <div className="flex-col">
                <label>
                  Frame Height:
                  {listData?.moderationData?.VideoMetadata?.FrameHeight}
                </label>
              </div>
              <div className="flex-col">
                <label>
                  Frame Rate:
                  {listData?.moderationData?.VideoMetadata?.FrameRate}
                </label>
              </div>
              <div className="flex-col">
                <label>
                  Frame Width:
                  {listData?.moderationData?.VideoMetadata?.FrameWidth}
                </label>
              </div>
            </div>
          </div>
          <ModerationTableList listData={listData} />
        </>
      )}
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
});

const mapDispatch = (dispatch) => ({
  getModerationKeys: (data) => dispatch(getModerationKeys(data)),
  refreshModeration: (data) => dispatch(refreshModeration(data)),
});

export default connect(mapState, mapDispatch)(ModerationSection);
