import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "store/reducers";
import axios from "axios";
import { API_URL } from "utils/constants";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

let api = axios.create({
  baseURL: API_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

const middlewares = [thunk.withExtraArgument(api)];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
