import { callApi, callApiAdvanced } from "utils/apiCaller";
import { toast_, loader_ } from "store/actions";
import { modulesArr } from "utils/helper";

const moduleAthlete = modulesArr.filter((item) => item.id === 1)[0];
const moduleFan = modulesArr.filter((item) => item.id === 2)[0];

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const uploadAFile = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi(`/upload-file`, "POST", data, true, true).then(
      (response) => {
        dispatch(loader_({ key: "mainLoader", value: false }));
        const status = response?.status;
        const resData = response?.data;
        if (status && status === 200) {
          if (resData?.data) {
            return resData?.data;
          }
        } else {
          handleError(dispatch, resData);
          return false;
        }
      }
    );
  };
};

export const createAppUser = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi(`/admin/user`, "POST", data).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        dispatch(
          toast_({
            type: "success",
            message: resData?.message,
          })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const editAppUser = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi(`/admin/edit-user?${data.params}`, "POST", data.data).then(
      (response) => {
        dispatch(loader_({ key: "mainLoader", value: false }));
        const status = response?.status;
        const resData = response?.data;
        if (status && status === 200) {
          dispatch(
            toast_({
              type: "success",
              message: resData?.message,
            })
          );
          return true;
        } else {
          handleError(dispatch, resData);
          return false;
        }
      }
    );
  };
};

export const getContentReports = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi(`/admin/reports?${data.params}`, "GET").then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getContentRatings = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi(`/admin/ratings?${data.params}`, "GET").then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getTaggedUsers = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi(`/admin/tags?${data.params}`, "GET").then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const blockUnblockDeleteUser = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi(`/admin/user-status`, "PATCH", data).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const flagUnflagDeleteContent = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi(`/admin/content-status`, "PATCH", data).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getDashboardDetails = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/dashboard`,
      method: "GET",
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getFollowerFollowing = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/follow?${data.params}`,
      method: "GET",
      customHeaders: {
        moduleHeader:
          data.userType === 1 ? moduleAthlete.value : moduleFan.value,
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getVideoLibrary = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/content-list?${data.params.replaceAll("#", "%23")}`,
      method: "GET",
      customHeaders: {
        moduleHeader:
          data.userType === 1 ? moduleAthlete.value : moduleFan.value,
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getVideoRated = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/rate-list?${data.params.replaceAll("#", "%23")}`,
      method: "GET",
      customHeaders: {
        moduleHeader:
          data.userType === 1 ? moduleAthlete.value : moduleFan.value,
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getSubscription = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/user-subscription?${data.params}`,
      method: "GET",
      customHeaders: {
        moduleHeader:
          data.userType === 1 ? moduleAthlete.value : moduleFan.value,
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getInvitation = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/user-invitation?${data.params}`,
      method: "GET",
      customHeaders: {
        moduleHeader:
          data.userType === 1 ? moduleAthlete.value : moduleFan.value,
      },
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
