import * as React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useHistory } from "react-router-dom";
import { LeftSidebar, HeaderSection } from "components/molecules";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import codes from "country-calling-code";
import {
  updateAdminDetails,
  uploadAFile,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import { connect } from "react-redux";
import {
  customTextFieldValidator,
  emailFieldValidator,
  mobileNumberValidator,
  fileFieldValidator,
} from "utils/validations";
import { Loader } from "components/atoms";
import "./index.css";
import { getErrors } from "utils/helper";

const countryCodes = codes.sort((a, b) =>
  a.isoCode3 > b.isoCode3 ? 1 : b.isoCode3 > a.isoCode3 ? -1 : 0
);

const ProfileEdit = (props) => {
  const {
    updateAdminDetails,
    showLoader,
    detail,
    uploadAFile,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const history = useHistory();

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    mobileNumber: "",
    countryCode: "1",
    image: null,
    uploadedFileUrl: "",
    errors: {},
  });

  React.useEffect(() => {
    if (detail && detail.email) {
      setFormData({
        name: detail.username,
        email: detail.email,
        mobileNumber: detail.phoneNo ? detail.phoneNo : "",
        countryCode:
          detail.countryCode && detail.countryCode !== "NA"
            ? detail.countryCode.slice(1)
            : "1",
        image: "img-url",
        uploadedFileUrl: detail?.profilePhoto ? detail?.profilePhoto : "",
        errors: {},
      });
    }
  }, [detail]);

  /**
   * This useEffect is used to set Active Module as Dashboard
   */
  React.useEffect(() => {
    toggleMenu_({ key: "dashboard" });
    activateMenuLevel2_({ key: "dashboard" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * When form is submitted the values are set to empty values
   */
  const onCloseForm = () => {
    setFormData({
      name: "",
      email: "",
      mobileNumber: "",
      countryCode: "1",
      image: null,
      uploadedFileUrl: "",
      errors: {},
    });
  };

  /**
   * This Function is called a image is uploaded
   * @param {event} e : This event is triggered when a image is uploaded related to the sport
   */
  const onChangeFile = (e) => {
    const newFormData = { ...formData };

    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    let uploadedFileUrl = "";
    let uploadedFile = e.target.files[0];

    if (uploadedFile) {
      uploadedFileUrl = URL.createObjectURL(e.target.files[0]);
      newFormData.image = e.target.files[0];
      newFormData.uploadedFileUrl = uploadedFileUrl;

      newFormData.errors.uploadedFileUrl = fileFieldValidator({
        min: 1,
        max: 800,
        fileType: "image/png",
        fileSizeUnit: "KB",
        multiplier: 1000,
        file: newFormData.image,
        req: true,
      });
    } else {
      newFormData.image = null;
      newFormData.uploadedFileUrl = "";
      newFormData.errors.uploadedFileUrl = "Please upload a file !";
    }
    setFormData(newFormData);
  };

  /**
   * This Function is used to remove image
   */
  const handleRemoveImage = () => {
    const newFormData = { ...formData };
    newFormData.image = null;
    newFormData.uploadedFileUrl = "";
    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    setFormData(newFormData);
  };

  /**
   * This Function is called when a image is corrupted
   */
  const handleCorruptImage = () => {
    const newFormData = { ...formData };

    if (newFormData.uploadedFileUrl) {
      newFormData.image = null;
      newFormData.uploadedFileUrl = "";
      newFormData.errors.uploadedFileUrl =
        "Please upload a valid file format !";

      setFormData(newFormData);
    }
  };

  /**
   * This Function is called everytime the entered value is changed
   * @param {string} key : It will take the changed value for the input fields and then will validate it.
   */
  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  /**
   * This Function is called when the form click on submit button after all the values are added/edited
   * then this function will validate the values and hit the post API in case of Add and put API in case of edit
   * @returns false if all the values fail to validate
   */
  const handleSubmit = () => {
    if (checkForError()) return false;
    if (formData.image !== "img-url") {
      const data = new FormData();
      data.append("files", formData.image);
      uploadAFile(data).then((res) => {
        if (res) {
          let postData = {
            email: formData.email.toLowerCase(),
            username: formData.name.trim(),
            phoneNo: formData.mobileNumber,
            countryCode: `+${formData.countryCode}`,
            profilePhoto: "fileUrl" in res ? res.fileUrl : "",
          };
          updateAdminDetails(postData).then((resp) => {
            if (resp) {
              onCloseForm();
              history.push("/profile");
            }
          });
        }
      });
    } else {
      let postData = {
        email: formData.email.toLowerCase(),
        username: formData.name.trim(),
        phoneNo: formData.mobileNumber,
        countryCode: `+${formData.countryCode}`,
      };
      if (detail?.profilePhoto) {
        postData.profilePhoto = detail?.profilePhoto;
      }
      updateAdminDetails(postData).then((resp) => {
        if (resp) {
          onCloseForm();
          history.push("/profile");
        }
      });
    }
  };

  /**
   * This Function is used to validate the entered value as per the defined rules
   * @param {string} key : takes the key to be checked
   * @param {string} val : takes the value corresponding to the key passed
   * @returns the error array if any error found
   */
  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "name") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 3 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter name !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            true,
            "Please enter only alphabets with spaces in between !",
          ],
        });
      } else if (key === "email") {
        err = emailFieldValidator(val, true, "Please enter email !");
      } else if (key === "mobileNumber") {
        err = mobileNumberValidator({
          len: [10, "Please enter 10 digits !"],
          val,
          req: [true, "Please enter mobile number !"],
          min: [10, "Min. length is 10 digits !"],
          max: [10, "Max. length is 10 digits !"],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.name = customTextFieldValidator({
        min: [3, "Min. length is 3 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.name,
        req: [true, "Please enter name !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          true,
          "Please enter only alphabets with spaces in between !",
        ],
      });
      newFormState.errors.mobileNumber = mobileNumberValidator({
        len: [10, "Please enter 10 digits !"],
        val: newFormState.mobileNumber,
        req: [true, "Please enter mobile number !"],
        min: [10, "Min. length is 10 digits !"],
        max: [10, "Max. length is 10 digits !"],
      });
      newFormState.errors.email = emailFieldValidator(
        newFormState.email,
        true,
        "Please enter email !"
      );

      if (newFormState.image && newFormState.image !== "img-url") {
        newFormState.errors.uploadedFileUrl = fileFieldValidator({
          min: 1,
          max: 800,
          fileType: "image/png",
          fileSizeUnit: "KB",
          multiplier: 1000,
          file: newFormState.image,
          req: true,
        });
      }

      newFormState.errors = getErrors(newFormState?.errors);
    }
    setFormData(newFormState);
    return Object.keys(newFormState.errors).length > 0 ? true : false;
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          <div className="edit-profile-wrap">
            {showLoader && <Loader />}
            <div className="edit-profile-title">
              <div className="title-left">
                <h1 className="section-title">My Profile</h1>
                <Link to="/profile">
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="section-title">Edit</h1>
              </div>
              <div className="btn-group">
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </div>
            <div className="edit-profile-info">
              <div className="upload-profile-wrap">
                {(!formData.uploadedFileUrl ||
                  formData?.errors?.uploadedFileUrl) && (
                  <div className="upload-profile-file">
                    <input
                      type="file"
                      name="sportImage"
                      onChange={onChangeFile}
                      accept=".png"
                    />
                    <div className="upload-icon">
                      <FileUploadOutlinedIcon />
                    </div>
                  </div>
                )}
                {formData.uploadedFileUrl &&
                  !formData?.errors?.uploadedFileUrl && (
                    <div className="upload-profile-image">
                      <figure>
                        <img
                          src={formData.uploadedFileUrl}
                          alt="User Icon"
                          onError={handleCorruptImage}
                        />
                      </figure>
                      <div className="close-icon" onClick={handleRemoveImage}>
                        <CloseIcon />
                      </div>
                    </div>
                  )}
              </div>
              {formData?.errors?.uploadedFileUrl && (
                <span className="error-msg">
                  {formData?.errors?.uploadedFileUrl}
                </span>
              )}
            </div>
            <div className="addprofile-row">
              <div className="addprofile-col">
                <label className="form-label">Name*</label>
                <TextField
                  placeholder="Name"
                  variant="outlined"
                  onChange={onChangeForm("name")}
                  value={formData.name}
                  className={formData?.errors?.name ? "input-error" : ""}
                />
                {formData?.errors?.name && (
                  <span className="error-msg">{formData?.errors?.name}</span>
                )}
              </div>
              <div className="addprofile-col">
                <label className="form-label">Mobile Number*</label>
                <div className="country-name-section">
                  <div className="country-code">
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select"
                        value={formData.countryCode}
                        onChange={onChangeForm("countryCode")}
                      >
                        {countryCodes.map((item, i) => {
                          return (
                            <MenuItem
                              value={item.countryCodes[0]}
                              key={i}
                            >{`${item.isoCode3} (+${item.countryCodes[0]})`}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="country-text">
                    <TextField
                      placeholder="Mobile Number"
                      onChange={onChangeForm("mobileNumber")}
                      value={formData.mobileNumber}
                      className={
                        formData?.errors?.mobileNumber ? "input-error" : ""
                      }
                    />
                    {formData?.errors?.mobileNumber && (
                      <span className="error-msg">
                        {formData?.errors?.mobileNumber}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="addprofile-col top-spacing">
                <label className="form-label">Email ID*</label>
                <TextField
                  placeholder="Email ID"
                  variant="outlined"
                  onChange={onChangeForm("email")}
                  value={formData.email}
                  className={formData?.errors?.email ? "input-error" : ""}
                />
                {formData?.errors?.email && (
                  <span className="error-msg">{formData?.errors?.email}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
  detail: state?.authInfo?.auth,
});
const mapDispatch = (dispatch) => ({
  updateAdminDetails: (data) => dispatch(updateAdminDetails(data)),
  uploadAFile: (data) => dispatch(uploadAFile(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});
export default connect(mapState, mapDispatch)(ProfileEdit);
