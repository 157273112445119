import * as React from "react";
import { LeftSidebar, HeaderSection, Confirmation } from "components/molecules";
import {
  Link,
  useHistory,
  useParams,
  withRouter,
  useLocation,
} from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./index.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { connect } from "react-redux";
import {
  getVideoDetail,
  getVideoDetail_,
  flagUnflagDeleteContent,
  updateReportStatus,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import {
  convertObjToStringParams,
  showAction,
  showSection,
} from "utils/helper";
import { Loader } from "components/atoms";

/**
 * This Component contains reported ads list
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getVideoDetail : function containing API call for getting video list,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * toggleMenu_,activateMenuLevel2_ : keeps the track of active page.
 * updateReportStatus : used for updating ad status
 * @returns the reported ads list component which will be rendered when this page is called.
 */
const ReportDetailAd = (props) => {
  let history = useHistory();
  const {
    getVideoDetail,
    showLoader,
    flagUnflagDeleteContent,
    detail,
    updateReportStatus,
    alPermissions,
    getVideoDetail_,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const { adId } = useParams();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const reportId = queryParams.get("reportId");
  const [reportStatus, setReportStatus] = React.useState(
    queryParams.get("reportStatus") || 1
  );

  const [parentCounter, setParentCounter] = React.useState(0);
  const [openConf, setOpenConf] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpenConf(false);
    },
    clickYes: () => {
      // Will do something
    },
  });
  const [tabvalue, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 7))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Report management and used to Fetch Video Details through API
   */
  React.useEffect(() => {
    toggleMenu_({ key: "reportMgmt" });
    activateMenuLevel2_({ key: "report" });
    // fetchVideoDetail();
    return () => {
      getVideoDetail_(null);
    };
  }, []);

  React.useEffect(() => {
    if (detail?.reportStatus && detail?.reportStatus !== reportStatus) {
      setReportStatus(detail?.reportStatus);
    }
  }, [detail]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This function is used to call API for video detail
   */
  const fetchVideoDetail = () => {
    const apiData = { id: adId };
    const params = convertObjToStringParams(apiData);
    return getVideoDetail({ params });
  };

  /**
   * This Function is used to open the dialog box
   * @param {object} : This contains type parameter which specific the type of dialog box
   */
  const handleClickOpen = ({ type }) => {
    let obj = { ...confirmationInfo };
    if (type === "mark-pending") {
      obj = {
        ...obj,
        title: "Mark as pending",
        description: `Are you sure you want to mark report as pending ?`,
        clickYes: () => {
          const apiData = {
            id: reportId,
            reportStatus: 1,
            reportType: 2,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) {
              fetchVideoDetail();
              setOpenConf(false);
              setReportStatus(1);
              setParentCounter((pc) => pc + 1);
            }
          });
        },
      };
    } else if (type === "mark-resolved") {
      obj = {
        ...obj,
        title: "Mark as resolved",
        description: `Are you sure you want to mark report as resolved ?`,
        clickYes: () => {
          const apiData = {
            id: reportId,
            reportStatus: 2,
            reportType: 2,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) {
              fetchVideoDetail();
              setOpenConf(false);
              setReportStatus(2);
              setParentCounter((pc) => pc + 1);
            }
          });
        },
      };
    } else if (type === "unflag") {
      obj = {
        ...obj,
        title: "Unflag content",
        description: `Are you sure you want to unflag ${detail?.caption} ?`,
        clickYes: () => {
          const apiData = { id: adId, status: 1 };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) {
              fetchVideoDetail();
              setOpenConf(false);
            }
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete content",
        description: `Are you sure you want to delete ${detail?.caption} ?`,
        clickYes: () => {
          const apiData = { id: adId, status: 3 };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) {
              setOpenConf(false);
              history.push("/reported-content");
            }
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Flag content",
        description: `Are you sure you want to flag ${detail?.caption} ?`,
        clickYes: () => {
          const apiData = { id: adId, status: 2 };
          flagUnflagDeleteContent(apiData).then((res) => {
            if (res) {
              fetchVideoDetail();
              setOpenConf(false);
            }
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpenConf(true);
  };

  const contentType = detail?.contentFormat ? detail?.contentFormat : "mp4";

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section report-ad-detail">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="report-ad-header">
              <div className="title-left">
                <Link to={`/reported-content?type=2`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title">Report Detail</h1>
              </div>
              {showAction(alPermissions, 7) && (
                <div className="btn-group">
                  <Button
                    variant="outlined"
                    color={reportStatus === 1 ? "success" : "error"}
                    onClick={() =>
                      handleClickOpen({
                        type: `mark-${
                          reportStatus === 1 ? "resolved" : "pending"
                        }`,
                      })
                    }
                  >
                    Mark {reportStatus === 1 ? "Resolved" : "Pending"}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleClickOpen({ type: "delete" })}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
            <div className="report-ad-section">
              <div className="report-ad-wrapper">
                {detail?.contentUrlMp4 && detail?.contentUrlMp4.length > 0 && (
                  <video controls poster={detail?.contentCoverImage}>
                    <source
                      src={detail?.contentUrlMp4}
                      type={`video/${contentType}`}
                    />
                  </video>
                )}
              </div>
              <div className="report-ad-description">
                <p>{detail?.caption}</p>
              </div>
            </div>
            <div className="report-info-section">
              <div className="flex-row">
                <div className="flex-col">
                  <label>Advertisement id : {adId}</label>
                </div>
                <div className="flex-col">
                  <label>
                    Ad Status: Active
                    {/* {detail?.adminStatus === 1 ? "No" : "Yes"} */}
                  </label>
                </div>
                <div className="flex-col">
                  <label>
                    Report Count : 2
                    {/* {detail?.adminStatus === 1 ? "No" : "Yes"} */}
                  </label>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-col">
                  <label>URL : ""</label>
                </div>
              </div>
            </div>
            <div className="tabs-section">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabvalue}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label={`Reported Users (${
                          detail?.report_total_count ?? 0
                        })`}
                        value="1"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    {/* <ReportedTabSection
                      contentId={videoId}
                      reportPage={true}
                      parentCounter={parentCounter}
                      fetchPageDetail={fetchVideoDetail}
                    /> */}
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={openConf}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.videoFeedInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getVideoDetail: (data) => dispatch(getVideoDetail(data)),
  flagUnflagDeleteContent: (data) => dispatch(flagUnflagDeleteContent(data)),
  updateReportStatus: (data) => dispatch(updateReportStatus(data)),
  getVideoDetail_: (data) => dispatch(getVideoDetail_(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});
export default withRouter(connect(mapState, mapDispatch)(ReportDetailAd));
