import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataNotFound from "assets/images/data-not-found.png";
import { connect } from "react-redux";
import { updateSingleReportStatus } from "store/actions";
import "./index.css";

/**
 * This Component contains Moderation Table
 * @param {Object} props : listData : contains list of content found
 * @returns the Moderation Table Component which will be rendered when this page is called.
 */
const ModerationTableList = (props) => {
  let { listData } = props;
  let rows =
    listData?.moderationData?.ModerationLabels &&
    listData?.moderationData?.ModerationLabels?.length > 0
      ? listData?.moderationData?.ModerationLabels
      : [];

  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        {listData?.moderationData?.ModerationLabels === undefined && (
          <>
            <figure>
              <img src={DataNotFound} alt={"Data Not Found"} />
            </figure>
            <h3>No Data Found</h3>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="reported-by-tab table-wrapper moderator-list">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Moderation Content</TableCell>
              <TableCell>Time Stamp</TableCell>
              <TableCell>Confidence</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row?.ModerationLabel?.Name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.ModerationLabel?.ParentName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.Timestamp}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.ModerationLabel?.Confidence}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});

const mapDispatch = (dispatch) => ({
  updateSingleReportStatus: (data) => dispatch(updateSingleReportStatus(data)),
});
export default connect(mapState, mapDispatch)(ModerationTableList);
