import * as React from "react";
import {
  Link,
  useHistory,
  useParams,
  withRouter,
  useLocation,
} from "react-router-dom";
import {
  LeftSidebar,
  HeaderSection,
  Confirmation,
  ReportedTabSection,
} from "components/molecules";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import User from "assets/images/user-pic.png";
import "./index.css";
import { connect } from "react-redux";
import {
  getFanDetail,
  blockUnblockDeleteUser,
  updateReportStatus,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import {
  convertObjToStringParams,
  showAction,
  showSection,
} from "utils/helper";
import { Loader } from "components/atoms";

/**
 * This Component contains reported users list
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getFanDetail : function containing API call for getting fans list,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * toggleMenu_,activateMenuLevel2_ : keeps the track of active page.
 * blockUnblockDeleteUser : used to block/unblock athlete
 * updateReportStatus : used for updating user status
 * @returns the reported users list component which will be rendered when this page is called.
 */
const ReportDetailFanUser = (props) => {
  const {
    getFanDetail,
    showLoader,
    blockUnblockDeleteUser,
    detail,
    updateReportStatus,
    alPermissions,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const { userId } = useParams();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const reportId = queryParams.get("reportId");
  const [reportStatus, setReportStatus] = React.useState(
    queryParams.get("reportStatus") || 1
  );

  const [parentCounter, setParentCounter] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {
      // Will do something
    },
  });
  let history = useHistory();

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 7))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Report management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "reportMgmt" });
    activateMenuLevel2_({ key: "report" });
    fetchFanDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (
      detail?.details?.reportStatus &&
      detail?.details?.reportStatus !== reportStatus
    ) {
      setReportStatus(detail?.details?.reportStatus);
    }
  }, [detail]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This Function is used to call API to get fan details
   */
  const fetchFanDetail = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getFanDetail({ params });
  };

  /**
   * Used to create API Data to send in API call
   * @returns object having id
   */
  const getApiData = () => {
    return {
      id: userId,
    };
  };

  /**
   * This Function is used to open the dialog box
   * @param {object} : This contains type parameter which specific the type of dialog box
   */
  const handleClickOpen = ({ type }) => {
    let obj = { ...confirmationInfo };
    if (type === "mark-pending") {
      obj = {
        ...obj,
        title: "Mark as pending",
        description: `Are you sure you want to mark report as pending ?`,
        clickYes: () => {
          const apiData = {
            id: reportId,
            reportStatus: 1,
            reportType: 1,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) {
              fetchFanDetail();
              setOpen(false);
              setReportStatus(1);
              setParentCounter((pc) => pc + 1);
            }
          });
        },
      };
    } else if (type === "mark-resolved") {
      obj = {
        ...obj,
        title: "Mark as resolved",
        description: `Are you sure you want to mark report as resolved ?`,
        clickYes: () => {
          const apiData = {
            id: reportId,
            reportStatus: 2,
            reportType: 1,
          };
          updateReportStatus(apiData).then((res) => {
            if (res) {
              fetchFanDetail();
              setOpen(false);
              setReportStatus(2);
              setParentCounter((pc) => pc + 1);
            }
          });
        },
      };
    } else if (type === "activate") {
      obj = {
        ...obj,
        title: "Activate user",
        description: `Are you sure you want to activate ${detail?.details?.name} ?`,
        clickYes: () => {
          const apiData = {
            id: userId,
            status: 1,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) {
              fetchFanDetail();
              setOpen(false);
            }
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete user",
        description: `Are you sure you want to delete ${detail?.details?.name} ?`,
        clickYes: () => {
          const apiData = {
            id: userId,
            status: 3,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) {
              setOpen(false);
              history.push("/reported-content");
            }
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Block user",
        description: `Are you sure you want to block ${detail?.details?.name} ?`,
        clickYes: () => {
          const apiData = {
            id: userId,
            status: 2,
          };
          blockUnblockDeleteUser(apiData).then((res) => {
            if (res) {
              fetchFanDetail();
              setOpen(false);
            }
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          <div className="main-section-inner">
            {showLoader && <Loader />}
            <div className="report-wrap">
              <div className="report-title">
                <div className="title-left">
                  <Link to={`/reported-content?type=1`}>
                    <ArrowBackIosNewIcon />
                  </Link>
                  <h1 className="section-title">Report Detail</h1>
                </div>
                {showAction(alPermissions, 7) && (
                  <div className="btn-group">
                    <Button
                      variant="outlined"
                      color={reportStatus === 1 ? "success" : "error"}
                      onClick={() =>
                        handleClickOpen({
                          type: `mark-${
                            reportStatus === 1 ? "resolved" : "pending"
                          }`,
                        })
                      }
                    >
                      Mark {reportStatus === 1 ? "Resolved" : "Pending"}
                    </Button>
                    <Button
                      color={
                        detail?.details?.status === 1 ? "error" : "success"
                      }
                      variant="outlined"
                      onClick={() =>
                        handleClickOpen({
                          type:
                            detail?.details?.status === 1
                              ? "de-activate"
                              : "activate",
                        })
                      }
                    >
                      {detail?.details?.status === 1 ? "Block" : "Activate"}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleClickOpen({ type: "delete" })}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
              <div className="user-wrap">
                <div className="report-subtitle">
                  <h3 className="section-heading">
                    {detail?.details?.userType === 1 ? "Fan" : "Athlete"}{" "}
                    Details
                  </h3>
                  <Link
                    to={`/${
                      detail?.details?.userType === 1 ? "fans" : "athletes"
                    }/${userId}`}
                  >
                    View more details
                  </Link>
                </div>
                <div className="user-profile-inner">
                  <div className="report-user-left">
                    <div className="report-user-image">
                      <figure>
                        <img
                          src={
                            detail?.details?.profileImage &&
                            detail?.details?.profileImage !== "NA"
                              ? detail?.details?.profileImage
                              : User
                          }
                          alt="User"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="report-user-right">
                    <div className="user-inner-row">
                      <div className="user-col">
                        <label className="user-detail">Name</label>
                        <p>{detail?.details?.name}</p>
                      </div>
                      <div className="user-col">
                        <label className="user-detail">Username</label>
                        <p>{detail?.details?.username}</p>
                      </div>
                      <div className="user-col">
                        <label className="user-detail">Mobile Number</label>
                        <p>
                          {detail?.details?.phoneNo !== "NA" &&
                          detail?.details?.countryCode !== "NA"
                            ? `${detail?.details?.countryCode} ${detail?.details?.phoneNo}`
                            : "NA"}
                        </p>
                      </div>
                      <div className="user-col top-spacing">
                        <label className="user-detail">Email Address</label>
                        <p>{detail?.details?.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ReportedTabSection
                contentId={userId}
                reportPage={true}
                parentCounter={parentCounter}
                fetchPageDetail={fetchFanDetail}
              />
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.fanInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getFanDetail: (data) => dispatch(getFanDetail(data)),
  blockUnblockDeleteUser: (data) => dispatch(blockUnblockDeleteUser(data)),
  updateReportStatus: (data) => dispatch(updateReportStatus(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});
export default withRouter(connect(mapState, mapDispatch)(ReportDetailFanUser));
