import { LOADER } from "store/types";

const initialState = {
  mainLoader: false,
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADER:
      state[action.payload.key] = action.payload.value;
      return { ...state };

    default:
      return state;
  }
};

export default loaderReducer;
