import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import "./index.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import Moment from "react-moment";
import { ColumnWithSort } from "components/atoms";
import { Confirmation } from "components/molecules";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSubadminStatus } from "store/actions";
import { showAction } from "utils/helper";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Sub Admin Table List
 * @param {Object} props : listData : contains list of subadmins , alPermissions : keeps the track if the logged in admin have permission to the particular module , updateSubadminStatus : contains API to update subadmin status , filter : used to filter on the basis of chosen filters
 * @returns the Sub Admin Table Component which will be rendered when this page is called.
 */
const TableList = (props) => {
  const {
    listData,
    filter,
    toggleSortOrder,
    handleFilterSubmit,
    updateSubadminStatus,
    alPermissions,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData.result : [];

  const [open, setOpen] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {
      // Will do something
    },
  });

  /**
   * This Function is used to open the dialog box
   * @param {object} : This contains type parameter which specific the type of dialog box ,
   * row parameter which contains details about the subadmin
   */
  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "activate") {
      obj = {
        ...obj,
        title: "Activate user",
        description: `Are you sure you want to activate ${row.username} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 1,
          };
          updateSubadminStatus(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "delete") {
      obj = {
        ...obj,
        title: "Delete user",
        description: `Are you sure you want to delete ${row.username} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 3,
          };
          updateSubadminStatus(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else {
      obj = {
        ...obj,
        title: "Block user",
        description: `Are you sure you want to block ${row.username} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 2,
          };
          updateSubadminStatus(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }

  return (
    <div className="table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <ColumnWithSort
                  title="Name"
                  sortBy={filter?.sortBy}
                  currentKey="username"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Email ID</TableCell>
              <TableCell>
                <ColumnWithSort
                  title="Added On"
                  sortBy={filter?.sortBy}
                  currentKey="createdAt"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>
              {showAction(alPermissions, 3) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link to={`/sub-admins/${row._id}`}>{row.username}</Link>
                </TableCell>
                <TableCell>
                  {row?.phoneNo !== "NA" && row?.countryCode !== "NA"
                    ? `${row?.countryCode} ${row?.phoneNo}`
                    : "NA"}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY">{row.createdAt}</Moment>
                </TableCell>
                {showAction(alPermissions, 3) && (
                  <TableCell>
                    <div className="action-wrap">
                      {row.status === 1 ? (
                        <Tooltip title="Block">
                          <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="span"
                            onClick={() =>
                              handleClickOpen({ type: "de-activate", row })
                            }
                          >
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Activate">
                          <IconButton
                            onClick={() =>
                              handleClickOpen({ type: "activate", row })
                            }
                          >
                            <CheckCircleOutlineIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          aria-label="upload picture"
                          component="span"
                          onClick={() =>
                            handleClickOpen({ type: "delete", row })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  updateSubadminStatus: (data) => dispatch(updateSubadminStatus(data)),
});
export default connect(mapState, mapDispatch)(TableList);
