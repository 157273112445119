import * as React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import { useHistory, withRouter } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./index.css";
import { createAppUser, setMenu_, activateMenuLevel2_ } from "store/actions";
import { connect } from "react-redux";
import {
  customTextFieldValidator,
  emailFieldValidator,
  mobileNumberValidator,
} from "utils/validations";
import { Loader } from "components/atoms";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import codes from "country-calling-code";
import { showSection, showAction, getErrors } from "utils/helper";
import { Link } from "react-router-dom";

const countryCodes = codes.sort((a, b) =>
  a.isoCode3 > b.isoCode3 ? 1 : b.isoCode3 > a.isoCode3 ? -1 : 0
);

/**
 *  This Component contains Add Athlete Page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * createAppUser : function containing API call for saving created user Details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * @returns the Add Athlete component which will be rendered when this page is called.
 */
const AthleteAdd = (props) => {
  const {
    createAppUser,
    showLoader,
    alPermissions,
    setMenu_,
    activateMenuLevel2_,
  } = props;
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    mobileNumber: "",
    countryCode: "1",
    errors: {},
  });
  const history = useHistory();

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 1))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as User Management/Athlete
   */
  React.useEffect(() => {
    activateMenuLevel2_({ key: "athlete" });
    setMenu_({ key: "userMgmt" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * When form is submitted the values are set to empty values
   */
  const onCloseForm = () => {
    setFormData({
      name: "",
      email: "",
      mobileNumber: "",
      countryCode: "1",
      errors: {},
    });
  };

  /**
   * This Function is called everytime the entered value is changed
   * @param {string} key : It will take the changed value for the input fields and then will validate it.
   */
  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  /**
   * This Function is called when the form click on submit button after all the values are added/edited
   * then this function will validate the values and hit the post API in case of Add and put API in case of edit
   * @returns false if all the values fail to validate
   */
  const handleSubmit = () => {
    if (checkForError()) return false;
    const data = {
      email: formData.email.toLowerCase(),
      name: formData.name.trim(),
      phoneNo: formData.mobileNumber,
      userType: 2,
      countryCode: `+${formData.countryCode}`,
    };
    createAppUser(data).then((res) => {
      if (res) {
        onCloseForm();
        history.push("/athletes");
      }
    });
  };

  /**
   * This Function is used to validate the entered value as per the defined rules
   * @param {string} key : takes the key to be checked
   * @param {string} val : takes the value corresponding to the key passed
   * @returns the error array if any error found
   */
  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "name") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 3 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter name !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            true,
            "Please enter only alphabets with spaces in between !",
          ],
        });
      } else if (key === "email") {
        err = emailFieldValidator(val, true, "Please enter email !");
      } else if (key === "mobileNumber") {
        err = mobileNumberValidator({
          len: [10, "Please enter 10 digits !"],
          val,
          req: [true, "Please enter mobile number !"],
          min: [10, "Min. length is 10 digits !"],
          max: [10, "Max. length is 10 digits !"],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.name = customTextFieldValidator({
        min: [3, "Min. length is 3 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.name,
        req: [true, "Please enter name !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          true,
          "Please enter only alphabets with spaces in between !",
        ],
      });
      newFormState.errors.mobileNumber = mobileNumberValidator({
        len: [10, "Please enter 10 digits !"],
        val: newFormState.mobileNumber,
        req: [true, "Please enter mobile number !"],
        min: [10, "Min. length is 10 digits !"],
        max: [10, "Max. length is 10 digits !"],
      });
      newFormState.errors.email = emailFieldValidator(
        newFormState.email,
        true,
        "Please enter email !"
      );
      newFormState.errors = getErrors(newFormState?.errors);
    }
    setFormData(newFormState);
    return Object.keys(newFormState.errors).length > 0 ? true : false;
  };
  return (
    <div className="pageWrapper">
      {showLoader && <Loader />}
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="title-left">
                <Link to={`/athletes`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title">Add Athlete</h1>
              </div>
              <div className="breadcrumb-right">
                {showAction(alPermissions, 1) && (
                  <Button variant="contained" onClick={handleSubmit}>
                    Save
                  </Button>
                )}
              </div>
            </div>
            <div className="addathlete-info">
              <div className="addathlete-row">
                <div className="addathlete-col">
                  <label className="form-label">Name*</label>
                  <TextField
                    placeholder="Name"
                    variant="outlined"
                    onChange={onChangeForm("name")}
                    value={formData.name}
                    className={formData?.errors?.name ? "input-error" : ""}
                  />
                  {formData?.errors?.name && (
                    <span className="error-msg">{formData?.errors?.name}</span>
                  )}
                </div>
                <div className="addathlete-col">
                  <label className="form-label">Mobile Number*</label>
                  <div className="country-name-section">
                    <div className="country-code">
                      <FormControl fullWidth>
                        <Select
                          placeholder="Select"
                          value={formData.countryCode}
                          onChange={onChangeForm("countryCode")}
                        >
                          {countryCodes.map((item, i) => {
                            return (
                              <MenuItem
                                value={item.countryCodes[0]}
                                key={i}
                              >{`${item.isoCode3} (+${item.countryCodes[0]})`}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="country-text">
                      <TextField
                        placeholder="Mobile Number"
                        onChange={onChangeForm("mobileNumber")}
                        value={formData.mobileNumber}
                        className={
                          formData?.errors?.mobileNumber ? "input-error" : ""
                        }
                      />
                      {formData?.errors?.mobileNumber && (
                        <span className="error-msg">
                          {formData?.errors?.mobileNumber}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="addathlete-col top-spacing">
                  <label className="form-label">Email ID*</label>
                  <TextField
                    placeholder="Email ID"
                    variant="outlined"
                    onChange={onChangeForm("email")}
                    value={formData.email}
                    className={formData?.errors?.email ? "input-error" : ""}
                  />
                  {formData?.errors?.email && (
                    <span className="error-msg">{formData?.errors?.email}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  createAppUser: (data) => dispatch(createAppUser(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  setMenu_: (data) => dispatch(setMenu_(data)),
});
export default withRouter(connect(mapState, mapDispatch)(AthleteAdd));
