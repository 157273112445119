import {
  GET_NOTIFICATION_DETAIL,
  SET_NOTIFICATION_INITIAL,
  SET_NOTIFICATION_PAGINATION,
} from "store/types";

const initialState = {
  current: null,
  page: 1,
  limit: 10,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_DETAIL:
      state.current = action.payload;
      return { ...state };

    case SET_NOTIFICATION_PAGINATION:
      return {
        ...state,
        page: action.payload.page,
        limit: action.payload.limit,
      };

    case SET_NOTIFICATION_INITIAL:
      return { ...initialState };

    default:
      return state;
  }
};

export default notificationReducer;
