import PropTypes from "prop-types";
import Button from "@mui/material/Button";

/**
 * This Component contains Submit Button
 * @param {Object} props : name : name of the button , onClickButton : function to be triggered on click of button
 * @returns the Submit Button which will be rendered when this component is called.
 */
const FilterSubmit = (props) => {
  const { name, customClass, onClickButton } = props;
  return (
    <Button
      variant="contained"
      className={customClass}
      onClick={onClickButton}
      type="submit"
    >
      {name}
    </Button>
  );
};

FilterSubmit.propTypes = {
  name: PropTypes.string,
  customClass: PropTypes.string,
  onClickButton: PropTypes.func,
};

export default FilterSubmit;
