import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  PrivacyPolicyTab,
  FaqTab,
  DatadetectionTab,
  AboutUsTab,
  TermsToUseTab,
  WhyAreYouSeeingthisAd,
  SubscriptionPolicy,
} from "components/molecules";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./index.css";
import { useLocation, useHistory } from "react-router-dom";
import { showSection } from "utils/helper";
import { connect } from "react-redux";
import { toggleMenu_, activateMenuLevel2_ } from "store/actions";

/**
 *  This Component contains Static Content and Tabs
 * @param {Object} props
 * toggleMenu_,activateMenuLevel2_ : keeps the track of active page.
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * @returns the Static Content component which will be rendered when this page is called.
 */
const StaticContentList = (props) => {
  const { alPermissions, toggleMenu_, activateMenuLevel2_ } = props;
  const history = useHistory();
  const { search } = useLocation();
  const qSearch = new URLSearchParams(search);
  const [tabvalue, setValue] = React.useState(qSearch.get("type") || "1");

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 4))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Static management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "staticMgmt" });
    activateMenuLevel2_({ key: "content" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This Function is called when Tabs are changed
   * @param {event} event : This event is triggered when tab is toggled between types of static content
   * @param {number} newValue : passed to get the value of selected tab
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(`/static-content?type=${newValue}`);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section static-content">
          <div className="main-section-inner">
            <div className="static-content-header">
              <div className="title-left">
                <h1 className="title">Static Content Management</h1>
              </div>
            </div>
            <div className="tabs-section">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabvalue}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="Static Content Tabs"
                    >
                      <Tab label="Privacy Policy" value="1" />
                      <Tab label="FAQ" value="2" />
                      <Tab label="Data Deletion Policy" value="3" />
                      <Tab label="About Us" value="4" />
                      <Tab label="Terms To Use" value="5" />
                      <Tab label="Subscription Policy" value="8" />
                      <Tab label="Why are you seeing this ad ?" value="6" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <PrivacyPolicyTab />
                  </TabPanel>
                  <TabPanel value="2">
                    <FaqTab />
                  </TabPanel>
                  <TabPanel value="3">
                    <DatadetectionTab />
                  </TabPanel>
                  <TabPanel value="4">
                    <AboutUsTab />
                  </TabPanel>
                  <TabPanel value="5">
                    <TermsToUseTab />
                  </TabPanel>
                  <TabPanel value="6">
                    <WhyAreYouSeeingthisAd />
                  </TabPanel>
                  <TabPanel value="8">
                    <SubscriptionPolicy />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});

const mapDispatch = (dispatch) => ({
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});
export default connect(mapState, mapDispatch)(StaticContentList);
