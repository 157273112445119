import { callApiAdvanced } from "utils/apiCaller";
import { toast_, loader_ } from "store/actions";
import { modulesArr } from "utils/helper";
import {
  GET_NOTIFICATION_DETAIL,
  SET_NOTIFICATION_INITIAL,
  SET_NOTIFICATION_PAGINATION,
} from "store/types";

const moduleNotification = modulesArr.filter((item) => item.id === 8)[0];

const customHeaders = {
  moduleHeader: moduleNotification.value,
};

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const addNotification = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/notification`,
      method: "POST",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        dispatch(
          toast_({
            type: "success",
            message: "Notification added successfully.",
          })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getAllNotifications = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/notifications?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const deleteNotification = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/notification?${data.params}`,
      method: "DELETE",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getNotificationDetail_ = (payload) => {
  return {
    type: GET_NOTIFICATION_DETAIL,
    payload,
  };
};

export const getNotificationPaginationDetail_ = (payload) => {
  return {
    type: SET_NOTIFICATION_PAGINATION,
    payload,
  };
};

export const setNotificationInitial_ = () => {
  return {
    type: SET_NOTIFICATION_INITIAL,
  };
};

export const getNotificationDetail = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/notification?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          dispatch(getNotificationDetail_(resData?.data));
          return true;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const sendNotification = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/notification`,
      method: "PATCH",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        dispatch(
          toast_({
            type: "success",
            message: "Notification sent successfully.",
          })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getSelectedUsers = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/notification/selected-user?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getNotificationDesc = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/notificationId?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
