import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import SportsBlack from "assets/images/sports-black.svg";
import "./index.css";
import { ColumnWithSort } from "components/atoms";
import Moment from "react-moment";
import { Confirmation } from "components/molecules";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSubOrdinate, showAction } from "utils/helper";
import { statusSport } from "store/actions";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Sports Management Table
 * @param {Object} props : listData : contains list of sports , alPermissions : keeps the track if the logged in admin have permission to the particular module , statusSport : contains API to update sport status , filter : used to filter on the basis of chosen filters
 * @returns the Sports Table Component which will be rendered when this page is called.
 */
const SportsTableList = (props) => {
  const {
    listData,
    filter,
    toggleSortOrder,
    handleFilterSubmit,
    statusSport,
    alPermissions,
  } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];
  const [open, setOpen] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState({
    title: "",
    description: "",
    clickClose: () => {
      setOpen(false);
    },
    clickYes: () => {
      // Will do something
    },
  });

  /**
   * This Function is used to open the dialog box
   * @param {object} : This contains type parameter which specific the type of dialog box ,
   * row parameter which contains details about the sport
   */
  const handleClickOpen = ({ type, row }) => {
    let obj = { ...confirmationInfo };
    if (type === "enable") {
      obj = {
        ...obj,
        title: "Enable Sport",
        description: `Are you sure you want to enable ${row.sportName} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 1,
          };
          statusSport(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    } else if (type === "disable") {
      obj = {
        ...obj,
        title: "Disable user",
        description: `Are you sure you want to disable ${row.sportName} ?`,
        clickYes: () => {
          const apiData = {
            id: row._id,
            status: 2,
          };
          statusSport(apiData).then((res) => {
            if (res) handleFilterSubmit();
            setOpen(false);
          });
        },
      };
    }
    setConfirmationInfo(obj);
    setOpen(true);
  };

  /**
   * This Function is used to set default image
   */
  const showDefaultImage = (e) => {
    e.target.src = SportsBlack;
  };

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }
  return (
    <div className="table-wrapper sport-list">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sport Icon</TableCell>
              <TableCell>Sport Name</TableCell>
              <TableCell>Subordinate Type</TableCell>
              <TableCell>Number of Subordinates</TableCell>
              <TableCell>
                <ColumnWithSort
                  title="Last updated on"
                  sortBy={filter?.sortBy}
                  currentKey="updatedOn"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>
              <TableCell>Status</TableCell>
              {showAction(alPermissions, 10) && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <div className="sport-icon bg">
                    <figure>
                      <img
                        src={row.sportImg}
                        alt="Sport Icon"
                        onError={showDefaultImage}
                      />
                    </figure>
                  </div>
                </TableCell>
                <TableCell>
                  <Link to={`/sports/${row._id}`}>{row.sportName}</Link>
                </TableCell>
                <TableCell>{getSubOrdinate(row?.ordinateType || 1)}</TableCell>
                <TableCell>{row.positions.length}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY">{row.updatedAt}</Moment>
                </TableCell>
                <TableCell
                  className={
                    row.status === 1 ? "status-active" : "status-block"
                  }
                >
                  {row.status === 1 ? "Enabled" : "Disabled"}
                </TableCell>
                {showAction(alPermissions, 10) && (
                  <TableCell>
                    <div className="action-wrap">
                      {row.status === 1 ? (
                        <Tooltip title="Disable">
                          <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="span"
                            onClick={() =>
                              handleClickOpen({ type: "disable", row })
                            }
                          >
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Enable">
                          <IconButton
                            onClick={() =>
                              handleClickOpen({ type: "enable", row })
                            }
                          >
                            <CheckCircleOutlineIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title={confirmationInfo.title}
        description={confirmationInfo.description}
        open={open}
        handleClose={confirmationInfo.clickClose}
        handleClickYes={confirmationInfo.clickYes}
      />
    </div>
  );
};

SportsTableList.propTypes = {
  listData: PropTypes.any,
  filter: PropTypes.object,
  toggleSortOrder: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  statusSport: PropTypes.func,
};
const mapDispatch = (dispatch) => ({
  statusSport: (data) => dispatch(statusSport(data)),
});

export default connect(null, mapDispatch)(SportsTableList);
