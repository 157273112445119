/**
 * This file contains all the validator functions
 */
import validator from "validator";

/**
 * This is TextField Validator
 * @param {number} min : minimum number of character allowed
 * @param {number} max : maximum number of character allowed
 * @param {string} val : value to be validated
 * @param {boolean} req : checks if the field is required
 * @param {boolean} onlyAlpha : checks if only alphabets are allowed
 * @param {boolean} alphaWithSpaces : checks if alphabets with spaces are allowed
 * @returns Error if any  
 */
export const textFieldValidator = (
  min = 1,
  max = 20,
  val = "",
  req = true,
  onlyAlpha = true,
  alphaWithSpaces = false
) => {
  let error = "";
  if (!val && req) {
    error = "Please fill this field !";
  } else if (val) {
    if (val.length < min) {
      error = `Min. length is ${min} characters !`;
    } else if (val.length > max) {
      error = `Max. length is ${max} characters !`;
    } else if (onlyAlpha) {
      if (!validator.isAlpha(val)) error = "Please enter only alphabets !";
    } else if (alphaWithSpaces) {
      if (
        !validator.matches(val, /^[a-z ]+$/i) ||
        val.startsWith(" ") ||
        val.endsWith(" ")
      )
        error = "Please enter only alphabets with spaces in between !";
    }
  }
  return error;
};

/**
 * This is Custom TextField Validator
 * @param {Object} param0 : min : minimum number of character allowed , max : maximum number of character allowed , val : value to be validated , req : checks if the field is required , onlyAlpha : checks if only alphabets are allowed , alphaWithSpaces : checks if alphabets with spaces are allowed , customRegex : pattern to be matched
 * @returns Error if any
 */
export const customTextFieldValidator = ({
  min,
  max,
  val,
  req,
  onlyAlpha = [],
  alphaWithSpaces = [],
  customRegex = [],
}) => {
  let error = "";
  if (!val && req[0]) {
    error = req[1];
  } else if (val) {
    if (val.length < min[0]) {
      error = min[1];
    } else if (val.length > max[0]) {
      error = max[1];
    } else if (onlyAlpha[0]) {
      if (!validator.isAlpha(val)) error = onlyAlpha[1];
    } else if (alphaWithSpaces[0]) {
      if (
        !validator.matches(val, /^[a-z ]+$/i) ||
        val.startsWith(" ") ||
        val.endsWith(" ")
      )
        error = alphaWithSpaces[1];
    } else if (customRegex[0]) {
      if (
        !validator.matches(val, customRegex[2]) ||
        val.startsWith(" ") ||
        val.endsWith(" ")
      )
        error = customRegex[1];
    }
  }
  return error;
};

/**
 * This is Email Field Validator
 * @param {string} val : value to be validated
 * @param {boolean} req : checks if the field is required
 * @param {string} msg : message to be shown
 * @returns  Error if any
 */
export const emailFieldValidator = (
  val = "",
  req = true,
  msg = "Please enter your email !"
) => {
  let error = "";
  if (!val && req) {
    error = msg;
  } else if (val) {
    if (!validator.isEmail(val)) error = "Please enter a valid email !";
  }
  return error;
};

/**
 * This is Password Field Validator
 * @param {number} min : minimum number of character allowed
 * @param {number} max : maximum number of character allowed
 * @param {string} val : value to be validated
 * @param {boolean} req : checks if the field is required
 * @param {string} regex : pattern to be matched
 * @returns Error if any
 */
export const passwordFieldValidator = (
  min = 8,
  max = 20,
  val = "",
  req = true,
  regex = false
) => {
  let error = "";
  if (!val && req) {
    error = "Please enter your password !";
  } else if (val) {
    if (val.length < min) {
      error = `Min. length is ${min} characters !`;
    } else if (val.length > max) {
      error = `Max. length is ${max} characters !`;
    } else if (regex) {
      let regExp =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
      if (!regExp.test(val)) {
        error =
          "Please enter a proper password with at least one uppercase letter, one lowercase letter, one number and one special character !";
      }
    }
  }
  return error;
};

/**
 * This is File Validator
 * @param {*} param :  min : minimum number of character allowed ,max : maximum number of character allowed, fileType : allowed file type ,fileSizeUnit : file size to be validated,file : file to be validated,req : checks if the field is required
 * @returns Error if any
 */
export const fileFieldValidator = ({
  min,
  max,
  fileType,
  fileSizeUnit,
  file,
  req,
  multiplier,
}) => {
  let error = "";
  if (!file && req) {
    error = "Please upload a file !";
  } else if (file) {
    if (min && file.size < min * multiplier)
      error = `Please upload a file more than ${min} ${fileSizeUnit} !`;

    if (max && file.size > max * multiplier)
      error = `Please upload a file less than ${max} ${fileSizeUnit} !`;

    if (fileType && !file.type.includes(fileType))
      error = `Please upload a ${fileType} file !`;
  }
  return error;
};

/**
 * This is Mobile Number Validator
 * @param {*} param : len : max length allowed ,val : value to be checked ,req : checks if the field is required ,min : minimum number of character allowed ,max : maximum number of character allowed
 * @returns Error if any
 */
export const mobileNumberValidator = ({
  len,
  val,
  req,
  min = [],
  max = [],
}) => {
  let error = "";
  if (!val && req[0]) {
    error = req[1];
  } else if (val) {
    val = val.toString();
    if (!validator.isNumeric(val)) {
      error = "Please enter only digits !";
    } else if (len[0] && val.length !== len[0]) {
      error = len[1];
    } else if (min[0] && val.length < min[0]) {
      error = min[1];
    } else if (max[0] && val.length > max[0]) {
      error = max[1];
    }
  }
  return error;
};

/**
 * This is URL Validator
 * @param {Object} param : val : value to be checked ,req : checks if the field is required ,msg : message to be shown
 * @returns Error if any
 */
export const urlValidator = ({ val, req = [], msg = "" }) => {
  let error = "";
  if (!val && req[0]) {
    error = req[1];
  } else if (val) {
    if (!validator.isURL(val)) {
      error = msg;
    }
  }
  return error;
};

/**
 * This is Rating Validator
 * @param {number} val : value to be checked
 * @returns Error if any
 */
export const ratingValidator = (val) => {
  let error = "";
  if (val < 0 || val > 5) {
    error = "Rating should be between 0 and 5";
  }
  return error;
};


