import {
  GET_SUPPORT_DETAIL,
  SET_SUPPORT_INITIAL,
  SET_SUPPORT_PAGINATION,
} from "store/types";

const initialState = {
  current: null,
  page: 1,
  limit: 10,
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPORT_DETAIL:
      state.current = action.payload;
      return { ...state };
    case SET_SUPPORT_PAGINATION:
      return {
        ...state,
        page: action.payload.page,
        limit: action.payload.limit,
      };

    case SET_SUPPORT_INITIAL:
      return { ...initialState };

    default:
      return state;
  }
};

export default supportReducer;
