import React from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import "./index.css";
import { connect } from "react-redux";
import { flagUnflagDeleteContent } from "store/actions";
import { roundOff } from "utils/helper";
import NoData from "components/atoms/no-data";

/**
 *  This Component contains Fan Video Rated Table List
 * @param {Object} props : listData : contains list of rated videos
 * @returns the Fan Video Rated Table Component which will be rendered when this page is called.
 */
const FanRankedTableList = (props) => {
  const { listData } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }
  return (
    <div className="table-wrapper athlete-rated">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Caption</TableCell>
              <TableCell>Uploader</TableCell>
              <TableCell>Highlight Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link to={`/video-feed/${row.content._id}`}>
                    <Tooltip
                      title={row?.content.caption ? row?.content.caption : "NA"}
                    >
                      <span>
                        {row.content.caption.length <= 10 &&
                          row.content.caption}
                        {row.content.caption.length > 10 &&
                          `${row.content.caption.slice(0, 10)} ...`}
                        {!row?.content.caption && "NA"}
                      </span>
                    </Tooltip>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    to={`/${
                      row.userData.userType === 1 ? "athletes" : "fans"
                    }/${row.userData.userId}`}
                  >
                    {row.userData.name}
                  </Link>
                </TableCell>
                <TableCell>{roundOff(row?.rating ?? 0)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
const mapState = (state) => ({
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  flagUnflagDeleteContent: (data) => dispatch(flagUnflagDeleteContent(data)),
});
export default connect(mapState, mapDispatch)(FanRankedTableList);
