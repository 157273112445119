import { callApiAdvanced } from "utils/apiCaller";
import { toast_, loader_ } from "store/actions";
import {
  GET_SUBADMIN_DETAIL,
  SET_SUBADMIN_INITIAL,
  SET_SUBADMIN_PAGINATION,
} from "store/types";
import { modulesArr } from "utils/helper";

const athleteMod = modulesArr.filter((item) => item.id === 3)[0];

const customHeaders = {
  moduleHeader: athleteMod.value,
};

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const getAllSubadmins = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/get-subAdmins?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const updateSubadminStatus = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/change-status-subAdmin`,
      method: "PATCH",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const createSubAdmin = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/create-subAdmin`,
      method: "POST",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        dispatch(
          toast_({
            type: "success",
            message: resData?.message,
          })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getSubadmin_ = (payload) => {
  return {
    type: GET_SUBADMIN_DETAIL,
    payload,
  };
};

export const getSubAdminPaginationDetail_ = (payload) => {
  return {
    type: SET_SUBADMIN_PAGINATION,
    payload,
  };
};

export const setSubAdminInitial_ = () => {
  return {
    type: SET_SUBADMIN_INITIAL,
  };
};

export const getSubadmin = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/get-subAdmin?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          dispatch(getSubadmin_(resData?.data));
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const editSubadminPermissions = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/edit-subAdmin-permissions`,
      method: "PATCH",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
