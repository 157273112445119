import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  AthletesSubscriptionTableList,
} from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./index.css";
import { Link, useParams } from "react-router-dom";
import { setMenu_, activateMenuLevel2_ } from "store/actions";
import { connect } from "react-redux";

/**
 *  This Component contains athlete subscription listing
 * @param {Object} props setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * @returns the athlete subscription listing component which will be rendered when this page is called.
 */
const AthletesSubscription = (props) => {
  const { id: userId } = useParams();
  const { setMenu_, activateMenuLevel2_ } = props;

  /**
   * This useEffect is used to set Active Module as User Management/Athlete
   */
  React.useEffect(() => {
    activateMenuLevel2_({ key: "athlete" });
    setMenu_({ key: "userMgmt" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section athlete-subscription">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="title-left">
                <Link to={`/athletes`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="section-title">Athlete Details</h1>
                <Link to={`/athletes/${userId}`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h2 className="section-sub-title">Subscription History</h2>
              </div>
            </div>
            <AthletesSubscriptionTableList />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  setMenu_: (data) => dispatch(setMenu_(data)),
});
export default connect(null, mapDispatch)(AthletesSubscription);
