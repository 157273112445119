import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  NotificationAddTableList,
  PaginationSection,
} from "components/molecules";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Popover from "@mui/material/Popover";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { FilterSubmit, FilterReset, Loader } from "components/atoms";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import {
  addNotification,
  uploadAFile,
  getAllAthletes,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import { connect } from "react-redux";
import { customTextFieldValidator } from "utils/validations";
import {
  convertObjToStringParams,
  getErrors,
  showAction,
  showSection,
} from "utils/helper";
import { Link } from "react-router-dom";

/**
 *  This Component contains Add Notification Page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * addNotification : function containing API call for saving created notification Details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * getAllAthletes : function containing API call for getting athlete list
 * @returns the Add Notification component which will be rendered when this page is called.
 */
const AddNotification = (props) => {
  const {
    addNotification,
    showLoader,
    alPermissions,
    getAllAthletes,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  let history = useHistory();

  const [formData, setFormData] = React.useState({
    title: "",
    url: "",
    receiverType: 1,
    desc: "",
    image: null,
    uploadedFileUrl: "",
    errors: {},
  });

  const [value, setValue] = React.useState([null, null]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    from: "",
    to: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "addedOn", val: -1 },
    status: "",
    error: "",
  });
  const [listData, setListData] = React.useState(null);
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 8))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to call handleFilterSubmit
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Notification Management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "notificationMgmt" });
    activateMenuLevel2_({ key: "notification" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  ////////////////// Users List ///////////////////////
  /**
   * Used to open filter popup on click of filter icon
   * @param {event} event : This event parameter keeps the value of current target
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Used to close the filter Popup
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * This is used to set the filter values on the basis of which listing will be sorted.
   * @param {string} key : used to send the key whose value needs to be changed
   */
  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;

    setFilter(newFilter);
  };

  /**
   * Debouncing For Search
   */
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  /**
   * Used to create API Data to send in API call
   * @returns object having id, page, limit and selected filter values
   */
  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
      sortKey: filter.sortBy.key,
      sortOrder: filter.sortBy.val,
    };
    if (value[0]) apiData.from = value[0];
    if (value[1]) apiData.to = value[1];
    if (filter.search) apiData.search = filter.search;
    if (filter.status) apiData.status = filter.status;
    return apiData;
  };

  /**
   * used to call API to get list of all athletes
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    setAnchorEl(null);
    getAllAthletes({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  /**
   * This Function is used to reset the selected values in filter
   */
  const handleFilterReset = () => {
    const newFilter = { ...filter };
    newFilter.from = "";
    newFilter.to = "";
    newFilter.status = "";
    newFilter.error = "";
    setValue([null, null]);
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to sort any column in table
   * @param {Object} obj : takes the key of column to sort and it's sorting order ascending/desending
   */
  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  ////////////////// Notification Form /////////////////

  /**
   * This function is called everytime the entered value is changed
   * @param {string} key : It will take the changed value for the input fields and then will validate it.
   */
  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };
  /**
   * This function is used to validate the entered value as per the defined rules
   * @param {string} key : takes the key to be checked
   * @param {string} val : takes the value corresponding to the key passed
   * @returns the error array if any error found
   */
  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "title") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 3 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter title !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            false,
            "Please enter only alphabets with spaces in between !",
          ],
          customRegex: [
            true,
            "Please enter only alphabets, &, -, spaces in between !",
            /^[a-z-& ]+$/i,
          ],
        });
      }
      if (key === "desc") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 1 characters !"],
          max: [200, "Max. length is 200 characters !"],
          val: val,
          req: [true, "Please enter description !"],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.title = customTextFieldValidator({
        min: [3, "Min. length is 3 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.title,
        req: [true, "Please enter title !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          false,
          "Please enter only alphabets with spaces in between !",
        ],
        customRegex: [
          true,
          "Please enter only alphabets, &, -, spaces in between !",
          /^[a-z-& ]+$/i,
        ],
      });

      newFormState.errors.desc = customTextFieldValidator({
        min: [3, "Min. length is 1 characters !"],
        max: [200, "Max. length is 200 characters !"],
        val: newFormState.desc,
        req: [true, "Please enter description !"],
      });

      newFormState.errors = getErrors(newFormState?.errors);
    }
    setFormData(newFormState);
    return Object.keys(newFormState.errors).length > 0 ? true : false;
  };

  /**
   * This function is called when the form click on submit button after all the values are added/edited
   * then this function will validate the values and hit the post API in case of Add and put API in case of edit
   * @returns false if all the values fail to validate
   */
  const handleSubmit = () => {
    if (checkForError()) return false;
    let postData = {
      title: formData.title.trim(),
      receiverType: parseInt(formData.receiverType),
      message: formData.desc.trim(),
    };
    if (selectedUsers.length > 0) {
      postData.receiverIds = selectedUsers;
    }
    addNotification(postData).then((resp) => {
      if (resp) {
        onCloseForm();
        history.push("/notifications");
      }
    });
  };

  /**
   * When form is submitted the values are set to empty values
   */
  const onCloseForm = () => {
    setFormData({
      title: "",
      url: "",
      receiverType: 1,
      image: null,
      uploadedFileUrl: "",
      errors: {},
    });
  };

  /**
   * This function is used to select the targeted users
   * @param {event} event : This event is triggered when targeted users is changed
   */
  const handleChange = (event) => {
    const newFormData = { ...formData };
    newFormData.receiverType = event.target.value;
    setFormData(newFormData);
    if (parseInt(event.target.value) === 8) {
      setCounter((pc) => pc + 1);
    }
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section add-athlete">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <Link to={`/notifications`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title">Add Notification</h1>
              </div>
              {showAction(alPermissions, 8) && (
                <div className="breadcrumb-right">
                  <Button variant="contained" onClick={handleSubmit}>
                    Send
                  </Button>
                </div>
              )}
            </div>
            <div className="add-notification-wrap">
              <div className="form-row">
                <div className="col-half">
                  <div className="notification-text">
                    <label className="form-label">Title*</label>
                    <TextField
                      placeholder="Type title"
                      variant="outlined"
                      onChange={onChangeForm("title")}
                      value={formData.title}
                    />
                  </div>
                  {formData?.errors?.title && (
                    <span className="error-msg">{formData?.errors?.title}</span>
                  )}
                </div>
                <div className="col-half">
                  <div className="notification-text">
                    <label className="form-label">Description*</label>
                    <TextField
                      placeholder="Type Description"
                      variant="outlined"
                      onChange={onChangeForm("desc")}
                      value={formData.desc}
                    />
                  </div>
                  {formData?.errors?.desc && (
                    <span className="error-msg">{formData?.errors?.desc}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-row spacing">
              <div className="col-half">
                <label className="form-label top-spacing">Recipient*</label>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formData.receiverType}
                  onChange={handleChange}
                >
                  <div className="btn-row">
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="All Users"
                    />
                    <FormControlLabel
                      value={8}
                      control={<Radio />}
                      label="Selected Users"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Fan"
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Athlete"
                    />
                  </div>
                  <div className="btn-row">
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="Fan with basic plan"
                    />
                    <FormControlLabel
                      value={5}
                      control={<Radio />}
                      label="Athlete with basic plan"
                    />
                    <FormControlLabel
                      value={6}
                      control={<Radio />}
                      label="iOS"
                    />
                    <FormControlLabel
                      value={7}
                      control={<Radio />}
                      label="Andriod"
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>
            {parseInt(formData.receiverType) === 8 && (
              <>
                <div className="search-filter-wrap">
                  <div className="serach-wrap">
                    <TextField
                      label="Search"
                      variant="outlined"
                      placeholder="Search by name, email and mobile number"
                      onChange={handleSearch}
                      value={filter?.search}
                    />
                  </div>
                  <div className="filter-btn">
                    <Tooltip title="Filter">
                      <IconButton
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        color="primary"
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <div className="filterPopup">
                        <label className="form-label">Added Date</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateRangePicker
                            startText="From"
                            endText="To"
                            value={value}
                            open={datePickerOpen}
                            onChange={(newValue) => {
                              setValue(newValue);
                              if (
                                newValue[0] !== null &&
                                newValue[1] !== null
                              ) {
                                setDatePickerOpen(false);
                              }
                            }}
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField
                                  onClick={() => {
                                    setDatePickerOpen(true);
                                  }}
                                  {...startProps}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField
                                  onClick={() => {
                                    setDatePickerOpen(true);
                                  }}
                                  {...endProps}
                                />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                        <div className="filter-text">
                          <label className="form-label">Status</label>
                          <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                              label="Status"
                              placeholder="Status"
                              value={filter?.status}
                              onChange={handleChangeFilter("status")}
                            >
                              <MenuItem value={1}>Active</MenuItem>
                              <MenuItem value={2}>Blocked</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="btn-wrap">
                          <FilterReset
                            name="Reset"
                            onClickButton={handleFilterReset}
                          />
                          <FilterSubmit
                            name="Apply Filter"
                            onClickButton={handleFilterSubmit}
                          />
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
                <NotificationAddTableList
                  listData={listData}
                  filter={filter}
                  toggleSortOrder={toggleSortOrder}
                  handleFilterSubmit={handleFilterSubmit}
                  alPermissions={alPermissions}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                />
                {listData?.result?.length > 0 && (
                  <PaginationSection
                    filter={filter}
                    listData={listData}
                    handleChangePage={handleChangePage}
                    handleChangePageLimit={handleChangePageLimit}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  addNotification: (data) => dispatch(addNotification(data)),
  uploadAFile: (data) => dispatch(uploadAFile(data)),
  getAllAthletes: (data) => dispatch(getAllAthletes(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});
export default connect(mapState, mapDispatch)(AddNotification);
