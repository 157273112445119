import * as React from "react";
import IconButton from "@mui/material/IconButton";
import {
  LeftSidebar,
  HeaderSection,
  LeaderboardList,
} from "components/molecules";
import { FilterSubmit, FilterReset, Loader } from "components/atoms";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import {
  getLeaderboardDetails,
  getLeaderboardPaginationDetail_,
  activateMenuLevel2_,
  setMenu_,
} from "store/actions";
import { convertObjToStringParams, showSection } from "utils/helper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";

/**
 *  This Component contains Leaderboard Listing
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getLeaderboardDetails : function containing API call for getting details of leaderboard,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * page,limit : The page keep track of the pageNo called during API call and limit tracks the no. of records we need
 * @returns the Leaderboard Listing component which will be rendered when this page is called.
 */
const LeaderboardManagement = (props) => {
  const {
    getLeaderboardDetails,
    showLoader,
    alPermissions,
    activateMenuLevel2_,
    setMenu_,
  } = props;
  const [listData, setListData] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateVal, setDateVal] = React.useState(null);
  const [valIST, setValIST] = React.useState(
    new Date().setDate(new Date().getDate())
  );

  const history = useHistory();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "addedOn", val: -1 },
    error: "",
    ratingError: "",
  });

  const totalRecords =
    listData?.topAthletes && listData?.topAthletes.length > 0
      ? listData?.topAthletes.length
      : 0;
  const totalPages = Math.ceil(totalRecords / filter?.pageLimit) || 1;
  const lowerLimit = filter.pageLimit * (filter.pageNo - 1) + 1;
  const upperLimit =
    listData?.topAthletes &&
    listData?.topAthletes.length >= lowerLimit + (filter?.pageLimit - 1)
      ? lowerLimit + (filter?.pageLimit - 1)
      : lowerLimit + (listData?.topAthletes.length - lowerLimit);

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 1))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to call handleFilterSubmit
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as User Management/Athlete
   */
  React.useEffect(() => {
    activateMenuLevel2_({ key: "leader" });
    setMenu_({ key: "leaderboard" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Used to open filter popup on click of filter icon
   * @param {event} event : This event parameter keeps the value of current target
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Used to close the filter Popup
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Used to create API Data to send in API call
   * @returns object having id, page, limit and selected filter values
   */
  const getApiData = () => {
    return {
      date: dateVal === null ? moment().format("DD-MM-YYYY") : dateVal,
    };
  };

  /**
   * used to call API to get list of all Athletes
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    if (filter.error || filter.ratingError) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    setAnchorEl(null);
    getLeaderboardDetails({ params }).then((apiData) => {
      setListData(apiData);
      setFilter({ ...filter, pageLimit: 10, pageNo: 1 });
    });
  };

  /**
   * This Function is used to reset the selected values in filter
   */
  const handleFilterReset = () => {
    setValIST(new Date().setDate(new Date().getDate()));
    setDateVal(moment().format("DD-MM-YYYY"));
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (e) => {
    setFilter({ ...filter, pageNo: 1, pageLimit: e.target.value });
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    setFilter({ ...filter, pageNo: val });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section athlete-list">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Leaderboard Management</h1>
              </div>
            </div>
            <div className="search-filter-wrap">
              <div className="serach-wrap">
                <h3 className="title">Top most Athletes</h3>
              </div>
              <div className="filter-btn">
                <Tooltip title="Filter">
                  <IconButton
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    color="primary"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <div className="filterPopup">
                    <div className="age-group">
                      <label className="textLabel">Date</label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Select Date"
                          value={valIST}
                          onChange={(newValue) => {
                            setValIST(newValue);
                            setDateVal(moment(newValue).format("DD-MM-YYYY"));
                          }}
                          disableFuture={true}
                          renderInput={(params) => <TextField {...params} />}
                          disableHighlightToday
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="btn-wrap">
                      <FilterReset
                        name="Reset"
                        onClickButton={handleFilterReset}
                      />
                      <FilterSubmit
                        name="Apply Filter"
                        onClickButton={handleFilterSubmit}
                      />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            {showLoader && <Loader />}
            <LeaderboardList
              listData={listData}
              lowerLimit={lowerLimit}
              upperLimit={upperLimit}
            />
            {listData?.topAthletes?.length > 0 && (
              <div className="pagination-wrap">
                <div className="pagination">
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      color="primary"
                      page={filter.pageNo}
                      onChange={(e, val) => {
                        handleChangePage(val);
                      }}
                    />
                  </Stack>
                </div>
                <div className="filterWrap">
                  <div className="select-dropdown">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Show
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.pageLimit}
                        label="Page Limit"
                        onChange={handleChangePageLimit}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="countWrap">
                    <p>
                      {lowerLimit || 0}-<b>{upperLimit || 0}</b> of{" "}
                      <b>{totalRecords}</b>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
  page: state?.athleteInfo?.page,
  limit: state?.athleteInfo?.limit,
});

const mapDispatch = (dispatch) => ({
  getLeaderboardDetails: (data) => dispatch(getLeaderboardDetails(data)),
  getLeaderboardPaginationDetail_: (data) =>
    dispatch(getLeaderboardPaginationDetail_(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  setMenu_: (data) => dispatch(setMenu_(data)),
});

LeaderboardManagement.propTypes = {
  getLeaderboardDetails: PropTypes.func,
  showLoader: PropTypes.bool,
};
export default connect(mapState, mapDispatch)(LeaderboardManagement);
