import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataNotFound from "assets/images/data-not-found.png";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./index.css";

/**
 * This Component contains Leaderboard List
 * @param {Object} props : listData : contains list of top athletes
 * @returns the Leaderboard Component which will be rendered when this page is called.
 */
const LeaderboardList = (props) => {
  const { listData, upperLimit, lowerLimit } = props;
  let rows =
    listData?.topAthletes && listData?.topAthletes?.length > 0
      ? listData?.topAthletes
      : [];

  if (rows.length === 0) {
    return (
      <div className="not-search-found">
        <figure>
          <img src={DataNotFound} alt={"Data Not Found"} />
        </figure>
        <h3>No Data Found</h3>
      </div>
    );
  }

  return (
    <div className="table-wrapper leaderboard-table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Sports</TableCell>
              <TableCell>Rank</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(lowerLimit - 1, upperLimit).map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link to={`/athletes/${row.userId}`}>{row.name}</Link>
                </TableCell>
                <TableCell>{row.sportName}</TableCell>
                <TableCell>{lowerLimit + idx}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

LeaderboardList.propTypes = {
  listData: PropTypes.any,
};

export default LeaderboardList;
