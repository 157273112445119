import * as React from "react";
import { useHistory, withRouter } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { LeftSidebar, HeaderSection } from "components/molecules";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import codes from "country-calling-code";
import { connect } from "react-redux";
import {
  createSubAdmin,
  uploadAFile,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import { Loader } from "components/atoms";
import {
  customTextFieldValidator,
  emailFieldValidator,
  mobileNumberValidator,
  fileFieldValidator,
} from "utils/validations";
import {
  getErrors,
  modulesArr,
  permissionsArr,
  showAction,
  showSection,
} from "utils/helper";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const countryCodes = codes.sort((a, b) =>
  a.isoCode3 > b.isoCode3 ? 1 : b.isoCode3 > a.isoCode3 ? -1 : 0
);

/**
 *  This Component contains Add Subadmin Page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * createSubAdmin : function containing API call for saving created subadmin Details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * @returns the Add Subadmin component which will be rendered when this page is called.
 */
const SubAdminAdd = (props) => {
  const history = useHistory();
  const {
    createSubAdmin,
    showLoader,
    alPermissions,
    uploadAFile,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    mobileNumber: "",
    countryCode: "1",
    image: null,
    uploadedFileUrl: "",
    errors: {},
    permissions: [],
  });

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 3))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as SubAdmin management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "subadminMgmt" });
    activateMenuLevel2_({ key: "subadmins" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * When form is submitted the values are set to empty values
   */
  const onCloseForm = () => {
    setFormData({
      name: "",
      email: "",
      mobileNumber: "",
      errors: {},
      countryCode: "1",
      image: null,
      uploadedFileUrl: "",
      permissions: [],
    });
  };

  /**
   * This Function is called everytime the entered value is changed
   * @param {string} key : It will take the changed value for the input fields and then will validate it.
   */
  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  /**
   * This Function is used to validate the entered value as per the defined rules
   * @param {string} key : takes the key to be checked
   * @param {string} val : takes the value corresponding to the key passed
   * @returns the error array if any error found
   */
  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "name") {
        err = customTextFieldValidator({
          min: [3, "Min. length is 3 characters !"],
          max: [40, "Max. length is 40 characters !"],
          val: val,
          req: [true, "Please enter name !"],
          onlyAlpha: [false, "Please enter only alphabets !"],
          alphaWithSpaces: [
            true,
            "Please enter only alphabets with spaces in between !",
          ],
        });
      } else if (key === "email") {
        err = emailFieldValidator(val, true, "Please enter email !");
      } else if (key === "mobileNumber") {
        err = mobileNumberValidator({
          len: [10, "Please enter 10 digits !"],
          val,
          req: [true, "Please enter mobile number !"],
          min: [10, "Min. length is 10 digits !"],
          max: [10, "Max. length is 10 digits !"],
        });
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.name = customTextFieldValidator({
        min: [3, "Min. length is 3 characters !"],
        max: [40, "Max. length is 40 characters !"],
        val: newFormState.name,
        req: [true, "Please enter name !"],
        onlyAlpha: [false, "Please enter only alphabets !"],
        alphaWithSpaces: [
          true,
          "Please enter only alphabets with spaces in between !",
        ],
      });
      newFormState.errors.mobileNumber = mobileNumberValidator({
        len: [10, "Please enter 10 digits !"],
        val: newFormState.mobileNumber,
        req: [true, "Please enter mobile number !"],
        min: [10, "Min. length is 10 digits !"],
        max: [10, "Max. length is 10 digits !"],
      });
      newFormState.errors.email = emailFieldValidator(
        newFormState.email,
        true,
        "Please enter email !"
      );
      newFormState.errors.uploadedFileUrl = fileFieldValidator({
        min: 1,
        max: 800,
        fileType: "image/png",
        fileSizeUnit: "KB",
        multiplier: 1000,
        file: newFormState.image,
        req: false,
      });

      if (newFormState.permissions.length === 0) {
        newFormState.errors.permissions =
          "Please select atleast one permission !";
      }
      newFormState.errors = getErrors(newFormState?.errors);

    }
    setFormData(newFormState);
    return Object.keys(newFormState.errors).length > 0 ? true : false;
  };

  /**
   * This Function is called when the form click on submit button after all the values are added/edited
   * then this function will validate the values and hit the post API in case of Add and put API in case of edit
   * @returns false if all the values fail to validate
   */
  const handleSubmit = () => {
    if (checkForError()) return false;
    if (formData.image) {
      const data = new FormData();
      data.append("files", formData.image);
      uploadAFile(data).then((res) => {
        if (res) {
          const postData = {
            email: formData.email.toLowerCase(),
            username: formData.name.trim(),
            phoneNo: formData.mobileNumber,
            countryCode: `+${formData.countryCode}`,
            permissions: formData.permissions,
            profilePhoto: res.fileUrl,
          };
          createSubAdmin(postData).then((resp) => {
            if (resp) {
              onCloseForm();
              history.push("/sub-admins");
            }
          });
        }
      });
    } else {
      const postData = {
        email: formData.email.toLowerCase(),
        username: formData.name.trim(),
        phoneNo: formData.mobileNumber,
        countryCode: `+${formData.countryCode}`,
        permissions: formData.permissions,
      };
      createSubAdmin(postData).then((resp) => {
        if (resp) {
          onCloseForm();
          history.push("/sub-admins");
        }
      });
    }
  };

  /**
   * This Function is called a image is uploaded
   * @param {event} e : This event is triggered when a image is uploaded related to the sport
   */
  const onChangeFile = (e) => {
    const newFormData = { ...formData };

    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    let uploadedFileUrl = "";
    let uploadedFile = e.target.files[0];

    if (uploadedFile) {
      uploadedFileUrl = URL.createObjectURL(e.target.files[0]);
      newFormData.image = e.target.files[0];
      newFormData.uploadedFileUrl = uploadedFileUrl;

      newFormData.errors.uploadedFileUrl = fileFieldValidator({
        min: 1,
        max: 100,
        fileType: "image/png",
        fileSizeUnit: "KB",
        multiplier: 1000,
        file: newFormData.image,
        req: true,
      });
    } else {
      newFormData.image = null;
      newFormData.uploadedFileUrl = "";
      newFormData.errors.uploadedFileUrl = "Please upload a file !";
    }
    setFormData(newFormData);
  };

  /**
   * This Function is used to remove image
   */
  const handleRemoveImage = () => {
    const newFormData = { ...formData };
    newFormData.image = null;
    newFormData.uploadedFileUrl = "";
    if (newFormData.errors.uploadedFileUrl)
      delete newFormData.errors.uploadedFileUrl;

    setFormData(newFormData);
  };

  /**
   * This Function is called when a image is corrupted
   */
  const handleCorruptImage = () => {
    const newFormData = { ...formData };
    newFormData.image = null;
    newFormData.uploadedFileUrl = "";
    newFormData.errors.uploadedFileUrl = "Please upload a valid file format !";

    setFormData(newFormData);
  };

  /**
   * This Function is called when checkbox is selected/changed
   * @param {Object} item : contains value selected by the admin
   * @returns the value selected in the form of state
   */
  const handleCheckboxChange = (item) => (e) => {
    const newFormData = { ...formData };
    if (newFormData.errors.permissions) delete newFormData.errors.permissions;
    if (e.target.checked) {
      newFormData.permissions.push({
        name: item.value,
        value: 1,
      });
    } else {
      newFormData.permissions = newFormData.permissions.filter((it) => {
        if (it.name === item.value) return false;
        return true;
      });
    }
    setFormData(newFormData);
  };

  /**
   * This Function is called to check if checkbox is selected
   * @param {*} item : contains status of all checkboxs
   * @returns true if checkbox is checked and false if checkbox is not checked
   */
  const isCheckBoxChecked = (item) => {
    const newFormData = { ...formData };
    const found = newFormData.permissions.find((it) => {
      if (it.name === item.value) return true;
      return false;
    });
    return found ? true : false;
  };

  const getRadioValue = (item) => {
    const newFormData = { ...formData };
    const found = newFormData.permissions.find((it) => {
      if (it.name === item.value) return true;
      return false;
    });
    return found ? found.value : "";
  };

  const handleChangeRadio = (item) => (e) => {
    const newFormData = { ...formData };

    newFormData.permissions = newFormData.permissions.map((it) => {
      if (it.name === item.value) {
        it.value = parseInt(e.target.value);
      }
      return it;
    });
    setFormData(newFormData);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="subadmin-wrap">
            <div className="subadmin-title">
              <div className="title-left">
                <Link to={`/sub-admins`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title">Add Admin</h1>
              </div>
              <div className="btn-group">
                {showAction(alPermissions, 3) && (
                  <Button variant="contained" onClick={handleSubmit}>
                    Save
                  </Button>
                )}
              </div>
            </div>
            <div className="add-permission-form">
              <div className="addadmin-row">
                <div className="addadmin-col">
                  <label className="form-label">Name*</label>
                  <TextField
                    placeholder="Name"
                    variant="outlined"
                    onChange={onChangeForm("name")}
                    value={formData.name}
                    className={formData?.errors?.name ? "input-error" : ""}
                  />
                  {formData?.errors?.name && (
                    <span className="error-msg">{formData?.errors?.name}</span>
                  )}
                </div>
                <div className="addadmin-col">
                  <label className="form-label">Mobile Number*</label>
                  <div className="country-name-section">
                    <div className="country-code">
                      <FormControl fullWidth>
                        <Select
                          placeholder="Select"
                          value={formData.countryCode}
                          onChange={onChangeForm("countryCode")}
                        >
                          {countryCodes.map((item, i) => {
                            return (
                              <MenuItem
                                value={item.countryCodes[0]}
                                key={i}
                              >{`${item.isoCode3} (+${item.countryCodes[0]})`}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="country-text">
                      <TextField
                        placeholder="Mobile Number"
                        onChange={onChangeForm("mobileNumber")}
                        value={formData.mobileNumber}
                        className={
                          formData?.errors?.mobileNumber ? "input-error" : ""
                        }
                      />
                      {formData?.errors?.mobileNumber && (
                        <span className="error-msg">
                          {formData?.errors?.mobileNumber}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="addadmin-col top-spacing">
                  <label className="form-label">Email ID*</label>
                  <TextField
                    placeholder="Email ID"
                    variant="outlined"
                    onChange={onChangeForm("email")}
                    value={formData.email}
                    className={formData?.errors?.email ? "input-error" : ""}
                  />
                  {formData?.errors?.email && (
                    <span className="error-msg">{formData?.errors?.email}</span>
                  )}
                </div>
              </div>
              <h3 className="section-heading">Permissions</h3>
              <div className="add-permission-row">
                <div className="permission-left">
                  {formData?.errors?.permissions && (
                    <span className="error-msg">
                      {formData?.errors?.permissions}
                    </span>
                  )}
                  {modulesArr.map((it, i) => {
                    return (
                      <div className="add-form-row" key={it.name + i}>
                        <div className="add-form-col form-checkbox">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCheckBoxChecked(it)}
                                  onChange={handleCheckboxChange(it)}
                                />
                              }
                              label={it.name}
                            />
                          </FormGroup>
                        </div>
                        <div className="add-form-col radio-btn">
                          <RadioGroup
                            row
                            aria-label="edit"
                            name="row-radio-buttons-group"
                            value={getRadioValue(it)}
                            onChange={handleChangeRadio(it)}
                          >
                            <FormControlLabel
                              value={permissionsArr[0].value}
                              control={<Radio />}
                              label={permissionsArr[0].name}
                              disabled={!isCheckBoxChecked(it)}
                            />
                            <FormControlLabel
                              value={permissionsArr[1].value}
                              control={<Radio />}
                              label={permissionsArr[1].name}
                              disabled={!isCheckBoxChecked(it)}
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="permission-right">
                  <div className="edit-profile-info">
                    <h2 className="sub-title">Profile Pic</h2>
                    <div className="upload-profile-wrap">
                      {(!formData.uploadedFileUrl ||
                        formData?.errors?.uploadedFileUrl) && (
                        <div className="upload-profile-file">
                          <input
                            type="file"
                            name="sportImage"
                            onChange={onChangeFile}
                            accept=".png"
                          />
                          <div className="upload-icon">
                            <FileUploadOutlinedIcon />
                          </div>
                        </div>
                      )}
                      {formData.uploadedFileUrl &&
                        !formData?.errors?.uploadedFileUrl && (
                          <div className="upload-profile-image">
                            <figure>
                              <img
                                src={formData.uploadedFileUrl}
                                alt="User Icon"
                                onError={handleCorruptImage}
                              />
                            </figure>
                            <div
                              className="close-icon"
                              onClick={handleRemoveImage}
                            >
                              <CloseIcon />
                            </div>
                          </div>
                        )}
                    </div>
                    {formData?.errors?.uploadedFileUrl && (
                      <span className="error-msg">
                        {formData?.errors?.uploadedFileUrl}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  createSubAdmin: (data) => dispatch(createSubAdmin(data)),
  uploadAFile: (data) => dispatch(uploadAFile(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});
export default withRouter(connect(mapState, mapDispatch)(SubAdminAdd));
