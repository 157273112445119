import { GET_ALL_USERS, SET_CONTENT_CREATOR_INITIAL } from "store/types";

const initialState = {
  current: null,
  page: 1,
  limit: 10,
  allUsers: null,
};

const contentCreatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      state.allUsers = action.payload;
      return { ...state };

    case SET_CONTENT_CREATOR_INITIAL:
      return { ...initialState };

    default:
      return state;
  }
};

export default contentCreatorReducer;
