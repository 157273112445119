import { TOAST } from "store/types";

const initialState = {
  toast: null,
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOAST:
      state.toast = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default toastReducer;
