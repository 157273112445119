import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./index.css";
import { AccountLeftSection } from "components/molecules";
import { emailFieldValidator } from "utils/validations";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { forgotPassword } from "store/actions";
import { withRouter, useHistory } from "react-router-dom";
import { Loader } from "components/atoms";
import { getErrors } from "utils/helper";

/**
 * This Component is Forget Password Component
 * @param {Object} props : It takes showLoader in this object which will show loading state until data is getting fetched.
 * and this forgetPassword variable will store the info user who want to reset the password.
 * @returns the Forgot Password component which will be rendered when this page is called.
 */
const ForgotPassword = (props) => {
  const history = useHistory();
  const { forgotPassword, showLoader } = props;
  const [formData, setFormData] = useState({
    email: "",
    errors: {},
  });

  /**
   * This Function is called when Submit button on Forgot Password Page is clicked
   * @param {event} e : This will take the event that is trigger when Submit button is clicked.
   * @returns API Call for getting user's Information.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkForError()) return false;
    const data = { email: formData.email.toLowerCase() };
    forgotPassword(data).then((res) => {
      if (res) {
        onCloseForm();
        history.push("/");
      }
    });
  };

  /**
   * When form is submitted the values are set to empty values
   */
  const onCloseForm = () => {
    setFormData({
      email: "",
      errors: {},
    });
  };

  /**
   * This Function is called everytime the entered value is changed
   * @param {string} key : It will take the changed value for the input fields and then will validate it.
   */
  const onChangeForm = (key) => (e) => {
    const newFormData = { ...formData };

    checkForError(key, e.target.value);
    newFormData[key] = e.target.value;

    setFormData(newFormData);
  };

  /**
   * This Function is used to validate the entered value as per the defined rules
   * @param {string} key : takes the key to be checked
   * @param {string} val : takes the value corresponding to the key passed
   * @returns the error array if any error found
   */
  const checkForError = (key = "", val = "") => {
    const newFormState = { ...formData };
    let err;
    if (key) {
      if (newFormState.errors[key]) {
        delete newFormState.errors[key];
      }
      if (key === "email") {
        err = emailFieldValidator(val, true);
      }
      if (err) {
        newFormState.errors[key] = err;
      }
    } else {
      newFormState.errors.email = emailFieldValidator(newFormState.email, true);
      newFormState.errors = getErrors(newFormState?.errors);
    }
    setFormData(newFormState);
    return Object.keys(newFormState.errors).length > 0 ? true : false;
  };

  return (
    <div className="accountWrapper">
      {showLoader && <Loader />}
      <div className="accountInner">
        <AccountLeftSection />
        <div className="right-section">
          <div className="right-inner">
            <h1 className="page-title">Forgot Password</h1>
            <p className="page-description">
              We’ll help you reset it and get back on track
            </p>
            <div className="form-wrapper normal-btn">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="input-row">
                  <TextField
                    id="email"
                    label="Email address"
                    variant="outlined"
                    onChange={onChangeForm("email")}
                    value={formData.email}
                    className={formData?.errors?.email ? "input-error" : ""}
                  />
                  {formData?.errors?.email && (
                    <span className="error-msg">{formData?.errors?.email}</span>
                  )}
                </div>
                <div className="btn-wrap login-btn">
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state.loaderInfo.mainLoader,
});
const mapDispatch = (dispatch) => ({
  forgotPassword: (data) => dispatch(forgotPassword(data)),
});

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func,
  showLoader: PropTypes.bool,
};
export default withRouter(connect(mapState, mapDispatch)(ForgotPassword));
