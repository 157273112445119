import dotenv from "dotenv";

dotenv.config();

const API_URL = process.env.REACT_APP_API_URL;
const INIT = "INIT";
const LOADING = "LOADING";
const SUCCESS = "SUCCESS";
const ERROR = "ERROR";
const API_KEY = process.env.REACT_APP_API_KEY;
const ADBUTLER_API_URL = process.env.ADBUTLER_API_URL;

export { API_URL, INIT, LOADING, SUCCESS, ERROR, API_KEY, ADBUTLER_API_URL };
