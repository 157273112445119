import { callApiAdvanced } from "utils/apiCaller";
import { toast_, loader_ } from "store/actions";
import { modulesArr } from "utils/helper";

const contentCreator = modulesArr.filter((item) => item.id === 13)[0];

const customHeaders = {
  moduleHeader: contentCreator.value,
};

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const getAllContentCreators = () => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/content-creator`,
      method: "GET",
      customHeaders,
      basicAuth: true,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const addContentCreators = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/content-creator`,
      method: "POST",
      body: { users: data.users, id: data.id },
      customHeaders,
      basicAuth: true,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        dispatch(
          toast_({
            type: "success",
            message: `Content Creators ${data.type} successfully.`,
          })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const searchContentCreator = (data, isLoader = true) => {
  return (dispatch) => {
    if (isLoader) dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/creator-search?${data.params}`,
      method: "GET",
      customHeaders,
      basicAuth: true,
    }).then((response) => {
      if (isLoader) dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const editRanking = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/change-order`,
      method: "PATCH",
      body: data,
      customHeaders,
      basicAuth: true,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
