import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { connect } from "react-redux";
import {
  toggleMenu_,
  activateMenuLevel2_,
  setAthleteInitial_,
  setFanInitial_,
  setNotificationInitial_,
  setSportInitial_,
  setSubAdminInitial_,
  setSupportInitial_,
  setVideoInitial_,
  setReportInitial_,
} from "store/actions";
import { showSection } from "utils/helper";

/**
 * This is Left Sidebar containing navigation buttons for all modules
 * @param {Object} props : containing functions to set the active module
 * @returns Left Sidebar Component
 */
const LeftSidebar = (props) => {
  const {
    menuInfo,
    toggleMenu_,
    activateMenuLevel2_,
    menuLevel2,
    alPermissions,
    setAthleteInitial_,
    setFanInitial_,
    setNotificationInitial_,
    setSportInitial_,
    setSubAdminInitial_,
    setSupportInitial_,
    setVideoInitial_,
    setReportInitial_,
  } = props;

  /**
   * This Function is called when modules are switched
   * @param {string} key : contains value to set as active module
   */
  const handleMenuToggle = (key) => {
    toggleMenu_({ key });
  };

  /**
   * This Function is called when modules are switched
   * @param {string} key : contains value to set as active module's second level
   */
  const activeMenuLevel2 = (key) => {
    activateMenuLevel2_({ key });
  };

  return (
    <aside className="sidebar">
      <div className="sidebarInner">
        <ul className="sidebar-menu">
          <li
            key="1"
            onClick={() => {
              handleMenuToggle("dashboard");
            }}
          >
            <Link
              to="/dashboard"
              onClick={() => {
                activeMenuLevel2("dashboard");
              }}
              className={menuLevel2 === "dashboard" ? "active" : ""}
            >
              <i className="icon dashboard"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          {showSection(alPermissions, 111) && (
            <li
              className={`parent-menu ${
                !menuInfo?.userMgmt ? "" : "down-arrow"
              }`}
              key="5"
            >
              <Link
                to="#"
                className="arrow"
                onClick={() => {
                  handleMenuToggle("userMgmt");
                }}
              >
                <i className="icon user"></i>
                <span>User Management</span>
              </Link>
              {menuInfo?.userMgmt && (
                <ul className="sub-menu">
                  {showSection(alPermissions, 1) && (
                    <li key="2">
                      <Link
                        to="/athletes"
                        onClick={() => {
                          activeMenuLevel2("athlete");
                          setAthleteInitial_();
                        }}
                        className={menuLevel2 === "athlete" ? "active" : ""}
                      >
                        <i className="icon athlete"></i>
                        <span>Athlete Management</span>
                      </Link>
                    </li>
                  )}
                  {showSection(alPermissions, 2) && (
                    <li key="3">
                      <Link
                        to="/fans"
                        onClick={() => {
                          activeMenuLevel2("fan");
                          setFanInitial_();
                        }}
                        className={menuLevel2 === "fan" ? "active" : ""}
                      >
                        <i className="icon fan"></i>
                        <span>Fan Management</span>
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}
          {showSection(alPermissions, 3) && (
            <li
              key="4"
              onClick={() => {
                handleMenuToggle("subadminMgmt");
              }}
            >
              <Link
                to="/sub-admins"
                onClick={() => {
                  activeMenuLevel2("subadmins");
                  setSubAdminInitial_();
                }}
                className={menuLevel2 === "subadmins" ? "active" : ""}
              >
                <i className="icon subadmins"></i>
                <span>Admin Management</span>
              </Link>
            </li>
          )}
          {showSection(alPermissions, 7) && (
            <li
              key="7"
              onClick={() => {
                handleMenuToggle("reportMgmt");
                setReportInitial_();
              }}
            >
              <Link
                to="/reported-content?type=1"
                onClick={() => {
                  activeMenuLevel2("report");
                }}
                className={menuLevel2 === "report" ? "active" : ""}
              >
                <i className="icon report"></i>
                <span>Reported Content Management</span>
              </Link>
            </li>
          )}
          {showSection(alPermissions, 6) && (
            <li
              key="6"
              onClick={() => {
                handleMenuToggle("feedMgmt");
              }}
            >
              <Link
                to="/video-feed"
                onClick={() => {
                  activeMenuLevel2("feed");
                  setVideoInitial_();
                }}
                className={menuLevel2 === "feed" ? "active" : ""}
              >
                <i className="icon feed"></i>
                <span>Feed Management</span>
              </Link>
            </li>
          )}
          {/* Testing */}
          {showSection(alPermissions, 8) && (
            <li
              key="8"
              onClick={() => {
                handleMenuToggle("notificationMgmt");
              }}
            >
              <Link
                to="/notifications"
                onClick={() => {
                  activeMenuLevel2("notification");
                  setNotificationInitial_();
                }}
                className={menuLevel2 === "notification" ? "active" : ""}
              >
                <i className="icon notification"></i>
                <span>Notification Management</span>
              </Link>
            </li>
          )}
          {showSection(alPermissions, 9) && (
            <li
              key="9"
              onClick={() => {
                handleMenuToggle("earningMgmt");
              }}
            >
              <Link
                to="/earnings"
                onClick={() => {
                  activeMenuLevel2("earning");
                }}
                className={menuLevel2 === "earning" ? "active" : ""}
              >
                <i className="icon earning"></i>
                <span>Earnings Management</span>
              </Link>
            </li>
          )}
          {showSection(alPermissions, 4) && (
            <li
              key="10"
              onClick={() => {
                handleMenuToggle("staticMgmt");
              }}
            >
              <Link
                to="/static-content?type=1"
                onClick={() => {
                  activeMenuLevel2("content");
                }}
                className={menuLevel2 === "content" ? "active" : ""}
              >
                <i className="icon content"></i>
                <span>Static Content Management</span>
              </Link>
            </li>
          )}
          {showSection(alPermissions, 10) && (
            <li
              key="11"
              onClick={() => {
                handleMenuToggle("sportMgmt");
              }}
            >
              <Link
                to="/sports"
                onClick={() => {
                  activeMenuLevel2("sport");
                  setSportInitial_();
                }}
                className={menuLevel2 === "sport" ? "active" : ""}
              >
                <i className="icon sport"></i>
                <span>Sports Management</span>
              </Link>
            </li>
          )}
          {showSection(alPermissions, 11) && (
            <li
              key="12"
              onClick={() => {
                handleMenuToggle("supportMgmt");
              }}
            >
              <Link
                to="/support"
                onClick={() => {
                  activeMenuLevel2("support");
                  setSupportInitial_();
                }}
                className={menuLevel2 === "support" ? "active" : ""}
              >
                <i className="icon support"></i>
                <span>Support Management</span>
              </Link>
            </li>
          )}
          {showSection(alPermissions, 12) && (
            <li
              key="15"
              onClick={() => {
                handleMenuToggle("leaderboard");
              }}
            >
              <Link
                to="/leaderboard"
                onClick={() => {
                  activeMenuLevel2("leader");
                }}
                className={menuLevel2 === "leader" ? "active" : ""}
              >
                <i className="icon subadmins"></i>
                <span>Leaderboard Management</span>
              </Link>
            </li>
          )}
          {showSection(alPermissions, 13) && (
            <li
              key="17"
              onClick={() => {
                handleMenuToggle("contentCreator");
              }}
            >
              <Link
                to="/content-creator"
                onClick={() => {
                  activeMenuLevel2("contentCreator");
                }}
                className={menuLevel2 === "contentCreator" ? "active" : ""}
              >
                <i className="icon subadmins"></i>
                <span>Content Creator Management</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </aside>
  );
};

const mapState = (state) => ({
  menuInfo: state.sideMenuInfo,
  menuLevel2: state.sideMenuInfo.menuLevel2,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  setAthleteInitial_: () => dispatch(setAthleteInitial_()),
  setFanInitial_: () => dispatch(setFanInitial_()),
  setNotificationInitial_: () => dispatch(setNotificationInitial_()),
  setSportInitial_: () => dispatch(setSportInitial_()),
  setSubAdminInitial_: () => dispatch(setSubAdminInitial_()),
  setSupportInitial_: () => dispatch(setSupportInitial_()),
  setVideoInitial_: () => dispatch(setVideoInitial_()),
  setReportInitial_: () => dispatch(setReportInitial_()),
});
export default connect(mapState, mapDispatch)(LeftSidebar);
