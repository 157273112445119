import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  ContentCreatorList,
} from "components/molecules";
import { FilterReset, FilterSubmit, Loader } from "components/atoms";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import {
  activateMenuLevel2_,
  setMenu_,
  getAllContentCreators,
  addContentCreators,
  searchContentCreator,
} from "store/actions";
import {
  convertObjToStringParams,
  showSection,
  showAction,
} from "utils/helper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./index.css";
import {
  Autocomplete,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DataNotFound from "assets/images/data-not-found.png";

/**
 *  This Component contains Leaderboard Listing
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getLeaderboardDetails : function containing API call for getting details of leaderboard,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * page,limit : The page keep track of the pageNo called during API call and limit tracks the no. of records we need
 * @returns the Leaderboard Listing component which will be rendered when this page is called.
 */
const ContentCreators = (props) => {
  const {
    addContentCreators,
    showLoader,
    alPermissions,
    getAllContentCreators,
    activateMenuLevel2_,
    setMenu_,
    searchContentCreator,
  } = props;

  const [listData, setListData] = React.useState(null);
  const [allListData, setAllListData] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userTobeAdded, setUserToBeAdded] = React.useState({});
  const [localUsers, setLocalUsers] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);

  const history = useHistory();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let [counter, setCounter] = React.useState(0);
  let [searchCounter, setSearchCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    pageLimit: 10,
    pageNo: 1,
    error: "",
    ratingError: "",
    userSearch: "",
    search: "",
    userId: "",
  });
  const dataTobePassed = filter.search !== "" ? filteredData : localUsers;
  const totalRecords =
    dataTobePassed && dataTobePassed.length > 0 ? dataTobePassed.length : 0;
  const totalPages = Math.ceil(totalRecords / filter?.pageLimit) || 1;
  const lowerLimit = filter.pageLimit * (filter.pageNo - 1) + 1;
  const upperLimit =
    dataTobePassed &&
    dataTobePassed.length >= lowerLimit + (filter?.pageLimit - 1)
      ? lowerLimit + (filter?.pageLimit - 1)
      : lowerLimit + (dataTobePassed.length - lowerLimit);

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 1))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is usAddToList
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleGetUser();
  }, [searchCounter]);

  /**
   * This useEffect is used to set Active Module as User Management/Athlete
   */
  React.useEffect(() => {
    activateMenuLevel2_({ key: "contentCreator" });
    setMenu_({ key: "contentCreator" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Used to open filter popup on click of filter icon
   * @param {event} event : This event parameter keeps the value of current target
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Used to close the filter Popup
   */
  const handleClose = () => {
    setAnchorEl(null);
    setUserToBeAdded({});
  };

  /**
   * used to call API to get list of all Athletes
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    setAnchorEl(null);
    getAllContentCreators().then((apiData) => {
      if (apiData === undefined) {
        setAllListData({ users: [] });
        setLocalUsers([]);
      } else {
        setAllListData(apiData);
        setLocalUsers(apiData?.users);
      }
    });
  };

  const handleGetUser = () => {
    const apiData = { page: 1, limit: 50 };
    if (filter.userSearch) apiData.search = filter.userSearch;
    const params = convertObjToStringParams(apiData);
    searchContentCreator({ params }, false).then((apiData) => {
      handleAlreadyaddedValues(apiData);
    });
  };

  const handleAlreadyaddedValues = (users) => {
    const ids = localUsers.map((user) => user.userId);
    const filterUsers = users?.result.filter((user) => !ids.includes(user._id));
    setListData({ ...listData, result: filterUsers });
  };

  /**
   * Debouncing For Search
   */
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleSearch = (e) => {
    setFilter({ ...filter, search: e.target.value });

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      handleFilter(e.target.value);
    });
  };

  /**
   * Debouncing For Search
   */
  const debounceUserCount = React.useRef(0);
  const apiUser = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleUserSearch = (e) => {
    setFilter({ ...filter, userSearch: e.target.value });

    debounceUserCount.current += 1;
    const localDebounceCount = debounceUserCount.current;
    apiUser().then((d) => {
      if (localDebounceCount !== debounceUserCount.current) {
        return;
      }
      setSearchCounter((pc) => pc + 1);
    });
  };

  const handleFilter = (val) => {
    if (localUsers) {
      const localFilteredData = localUsers.filter((data) => {
        return (
          data.name.toLowerCase().includes(val.toLowerCase()) ||
          data.username.toLowerCase().includes(val.toLowerCase())
        );
      });
      setFilteredData(localFilteredData);
    }
  };

  const handleReordering = (data, type) => {
    const reorderedData = data.map((reorder, idx) => {
      return { ...reorder, order: idx + 1 };
    });
    setLocalUsers(reorderedData);
    handlePublish(reorderedData, type);
  };

  const handleDelete = (id) => {
    const newUsers = localUsers.filter((user) => user.userId !== id);
    if (
      filter.pageNo - 1 !== 0 &&
      newUsers.length <= filter.pageLimit * (filter.pageNo - 1)
    ) {
      setFilter({ ...filter, pageNo: filter.pageNo - 1 });
    }
    handleReordering(newUsers, "deleted");
  };

  const handleAddToList = () => {
    if (Object.keys(userTobeAdded).length > 0) {
      const user = userTobeAdded;
      const toBeAdded = {
        ...user,
        userId: user._id,
        addedOn: Date.now(),
      };

      const newArray =
        localUsers !== null ? [...localUsers, toBeAdded] : [toBeAdded];
      handleReordering(newArray, "added");
      setFilter({ ...filter, userSearch: "" });
      setSearchCounter((pc) => pc + 1);
    }
    handleClose();
  };

  const handleEdit = (rank, valueToBeChanged) => {
    const newUsers = localUsers.filter(
      (userInn) => userInn.userId !== localUsers[valueToBeChanged].userId
    );
    newUsers.splice(rank - 1, 0, localUsers[valueToBeChanged]);
    setFilter({ ...filter, search: "" });
    handleReordering(newUsers, "updated");
  };

  const handlePublish = (reorderedData, type) => {
    const data = { users: reorderedData, type };
    if (allListData?._id) data.id = allListData._id;
    addContentCreators(data).then((resp) => {
      if (resp) {
        handleClose();
        setCounter((pc) => pc + 1);
      }
    });
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (e) => {
    const { value } = e.target;
    setFilter({ ...filter, pageNo: 1, pageLimit: value });
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    setFilter({ ...filter, pageNo: val });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section athlete-list">
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Content Creator</h1>
              </div>
              <div className="btn-group">
                {showAction(alPermissions, 13) && (
                  <Button variant="contained" onClick={handleClick}>
                    Add
                  </Button>
                )}

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <div className="filterPopup">
                    <Autocomplete
                      options={listData?.result || []}
                      value={userTobeAdded}
                      getOptionLabel={(option) => option.username || ""}
                      disableClearable
                      onChange={(e, value) => {
                        setUserToBeAdded(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={"Search Content Creators"}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleUserSearch}
                          value={filter?.userSearch}
                        />
                      )}
                    />
                    <div className="btn-wrap">
                      <FilterReset name="Close" onClickButton={handleClose} />
                      <FilterSubmit
                        name="Add To List"
                        onClickButton={handleAddToList}
                      />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            <div className="serach-wrap margin">
              <TextField
                label="Search"
                variant="outlined"
                placeholder="Search"
                onChange={handleSearch}
                value={filter?.search}
              />
            </div>
            {showLoader && <Loader />}
            {dataTobePassed && dataTobePassed.length > 0 ? (
              <ContentCreatorList
                listData={dataTobePassed.slice(lowerLimit - 1, upperLimit)}
                alPermissions={alPermissions}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                dataLength={dataTobePassed.length}
              />
            ) : (
              <div className="not-search-found">
                <figure>
                  <img src={DataNotFound} alt={"Data Not Found"} />
                </figure>
                <h3>No Data Found</h3>
              </div>
            )}
            {dataTobePassed && dataTobePassed?.length > 0 && (
              <div className="pagination-wrap">
                <div className="pagination">
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      color="primary"
                      page={filter.pageNo}
                      onChange={(e, val) => {
                        handleChangePage(val);
                      }}
                    />
                  </Stack>
                </div>
                <div className="filterWrap">
                  <div className="select-dropdown">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Show
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter?.pageLimit}
                        label="Page Limit"
                        onChange={handleChangePageLimit}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="countWrap">
                    <p>
                      {lowerLimit || 0}-<b>{upperLimit || 0}</b> of{" "}
                      <b>{totalRecords}</b>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
  page: state?.athleteInfo?.page,
  limit: state?.athleteInfo?.limit,
});

const mapDispatch = (dispatch) => ({
  searchContentCreator: (data) => dispatch(searchContentCreator(data)),
  getAllContentCreators: (data) => dispatch(getAllContentCreators(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  setMenu_: (data) => dispatch(setMenu_(data)),
  addContentCreators: (data) => dispatch(addContentCreators(data)),
});

ContentCreators.propTypes = {
  searchContentCreator: PropTypes.func,
  getAllContentCreators: PropTypes.func,
  showLoader: PropTypes.bool,
};
export default connect(mapState, mapDispatch)(ContentCreators);
