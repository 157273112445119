import { SET_REPORT_INITIAL, SET_REPORT_PAGINATION } from "store/types";

const initialState = {
  page: 1,
  limit: 10,
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_PAGINATION:
      return {
        ...state,
        page: action.payload.page,
        limit: action.payload.limit,
      };

    case SET_REPORT_INITIAL:
      return { ...initialState };

    default:
      return state;
  }
};

export default reportReducer;
