import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router";
import { getInvitation, getFanDetail, getFanDetail_ } from "store/actions";
import { connect } from "react-redux";
import { Loader } from "components/atoms";
import { convertObjToStringParams } from "utils/helper";
import Moment from "react-moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Fan's Invitation Table List
 * @param {Object} props : getInvitation : function containing API call for getting invitation listing, showLoader : show loading state until data is getting fetched.
 * @returns the Fan's Invitation Table Component which will be rendered when this page is called.
 */
const FanInvitationTableList = (props) => {
  const { id } = useParams();

  const { getInvitation, showLoader, getFanDetail, detail, getFanDetail_ } =
    props;
  const [listData, setListData] = useState(null);
  let [counter, setCounter] = React.useState(0);
  let [loader, setLoader] = React.useState(true);

  const [pageLimit, setPageLimit] = React.useState(listData?.pageLimit || 10);
  const totalRecords = listData?.total || 0;
  const totalPages = Math.ceil(totalRecords / pageLimit) || 1;
  const lowerLimit = pageLimit * (listData?.page - 1) + 1;
  const upperLimit =
    listData?.result?.length === pageLimit
      ? listData.page * pageLimit
      : listData?.result?.length >= lowerLimit + (pageLimit - 1)
      ? lowerLimit + (pageLimit - 1)
      : lowerLimit + (listData?.result?.length - lowerLimit);

  const [filter, setFilter] = React.useState({
    pageLimit: 10,
    pageNo: 1,
    error: "",
  });

  /**
   * Used to create API Data to send in API call
   * @returns object having id, page, limit and other params values
   */
  const getApiData = () => {
    return {
      page: filter.pageNo,
      limit: filter.pageLimit,
      id,
    };
  };

  /**
   * used to call API to get list of invitation on Athlete page
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getInvitation({ params }).then((apiData) => {
      const deletedUserArray = Array(
        (detail?.details?.inviteCount ?? 0) - apiData?.total
      ).fill({
        name: "Deleted User",
      });
      const previousArray = Array((filter.pageNo - 1) * pageLimit).fill({
        name: "",
      });
      setListData({
        ...apiData,
        result: [...previousArray, ...apiData.result, ...deletedUserArray],
        nonDeletedUsers: apiData?.total,
        total: detail?.details?.inviteCount ?? 0,
      });
    });
  };

  const handleChangeLimit = (event) => {
    setPageLimit(event.target.value);
    handleChangePageLimit(event.target.value);
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    if (
      Math.ceil((val * 1) / val) <= Math.ceil(listData?.nonDeletedUsers / val)
    ) {
      setCounter((pc) => pc + 1);
    } else {
      setPageLimit(val);
    }
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    if (
      Math.ceil((pageLimit * val) / pageLimit) <=
      Math.ceil(listData?.nonDeletedUsers / pageLimit)
    ) {
      setCounter((pc) => pc + 1);
    } else {
      setListData({
        ...listData,
        page: val,
      });
    }
  };

  const handleTotalCount = () => {
    const params = convertObjToStringParams({ id });
    getFanDetail({ params });
  };

  React.useEffect(() => {
    handleTotalCount();
  }, [counter]);

  /**
   * This useEffect is used to call Fan Invitation Listing API when this Component is rendered on the screen
   */
  React.useEffect(() => {
    if (detail !== null) {
      setLoader(false);
      handleFilterSubmit();
    }
  }, [detail]);

  React.useEffect(() => {
    return () => {
      getFanDetail_(null);
    };
  }, []);

  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];

  if (rows.length === 0 && detail?.details?.inviteCount === 0) {
    return <NoData listData={listData} />;
  }

  return (
    <div className="table-wrapper subscription">
      {showLoader || loader || detail === null ? (
        <Loader />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Invitation Date & Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(lowerLimit - 1, upperLimit).map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row?.username}</TableCell>
                    <TableCell>{row?.email}</TableCell>
                    <TableCell>
                      {row?.invite?.timestamp ? (
                        <Moment format="DD MMM, YYYY & h:mm A">
                          {row?.invite?.timestamp}
                        </Moment>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {listData?.result?.length > 0 && (
            <div className="pagination-wrap">
              <div className="pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    color="primary"
                    page={listData?.page || 1}
                    onChange={(e, val) => {
                      handleChangePage(val);
                    }}
                  />
                </Stack>
              </div>
              <div className="filterWrap">
                <div className="select-dropdown">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Show</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pageLimit}
                      label="Page Limit"
                      onChange={handleChangeLimit}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={40}>40</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="countWrap">
                  <p>
                    {lowerLimit || 0}-<b>{upperLimit || 0}</b> of{" "}
                    <b>{totalRecords}</b>
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  detail: state.fanInfo?.current,
});

const mapDispatch = (dispatch) => ({
  getInvitation: (data) => dispatch(getInvitation(data)),
  getFanDetail: (data) => dispatch(getFanDetail(data)),
  getFanDetail_: (data) => dispatch(getFanDetail_(data)),
});

export default connect(mapState, mapDispatch)(FanInvitationTableList);
