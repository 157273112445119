import * as React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./index.css";
import { Loader } from "components/atoms";
import { connect } from "react-redux";
import {
  getSupportDetail,
  getSupportDetail_,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import { convertObjToStringParams, showSection } from "utils/helper";
import { Link } from "react-router-dom";

/**
 *  This Component contains Support detail
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getSupportDetail : function containing API call for getting support details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * @returns the Support detail component which will be rendered when this page is called.
 */
const SupportDetail = (props) => {
  const history = useHistory();
  const {
    getSupportDetail,
    showLoader,
    detail,
    alPermissions,
    getSupportDetail_,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const { id } = useParams();

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 11))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Support management
   */
  React.useEffect(() => {
    fetchDetail();
    toggleMenu_({ key: "supportMgmt" });
    activateMenuLevel2_({ key: "support" });
    return () => {
      getSupportDetail_(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This Function is contains API call for getting support details.
   */
  const fetchDetail = () => {
    const apiData = { id };
    const params = convertObjToStringParams(apiData);
    getSupportDetail({ params });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="support-wrap">
            <div className="support-title">
              <div className="title-left">
                <Link to={`/support`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="section-title">Support Details</h1>
              </div>
            </div>
            <div className="user-wrap">
              <h3 className="section-heading">User Details</h3>
              <div className="user-inner">
                <div className="user-inner-row">
                  <div className="user-col">
                    <label className="user-detail">Name</label>
                    <p>{detail?.userData?.name}</p>
                  </div>
                  <div className="user-col">
                    <label className="user-detail">Email</label>
                    <p>{detail?.userData?.email}</p>
                  </div>
                  <div className="user-col">
                    <label className="user-detail">Subject</label>
                    <p>{detail?.subject}</p>
                  </div>
                </div>
              </div>
              <div className="profile-image-sec">
                <h3 className="section-heading">Reported Images</h3>
                <div className="row">
                  {detail?.imageArray?.length > 0 ? (
                    detail?.imageArray.map((item, i) => {
                      return (
                        <div className="col-24 top-spacing " key={i}>
                          <div className="profile-image">
                            <figure>
                              <a href={item} target="_blank" rel="noreferrer">
                                <img src={item} alt="Document Upload" />
                              </a>
                            </figure>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="nodata">No images</p>
                  )}
                </div>
              </div>
              <div className="description-sec">
                <h3 className="section-heading">Description</h3>
                <p>{detail?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.supportInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getSupportDetail: (data) => dispatch(getSupportDetail(data)),
  getSupportDetail_: (data) => dispatch(getSupportDetail_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
});

export default connect(mapState, mapDispatch)(SupportDetail);
