import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { ColumnWithSort } from "components/atoms";
import Moment from "react-moment";
import "./index.css";
import moment from "moment";
import NoData from "components/atoms/no-data";

/**
 * This Component is used to Add Notification
 * @param {Object} props  : listData : contains list of users , filter : used to filter values on the basis of filters selected , selectedUsers : keeps the track of selected users
 * @returns the Add Notification Component which will be rendered when this page is called.
 */
const NotificationAddTableList = (props) => {
  const { listData, filter, toggleSortOrder, selectedUsers, setSelectedUsers } =
    props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];

  const [groupCheck, setGroupCheck] = React.useState({});

  /**
   * This Function is called to check if checkbox is selected
   * @param {*} item : contains status of all checkboxs
   * @returns true if checkbox is checked and false if checkbox is not checked
   */
  const isCheckBoxChecked = (item) => {
    const found = selectedUsers.includes(item);
    return found ? true : false;
  };

  /**
   * This Function is called when checkbox is selected/changed
   * @param {Object} item : contains value selected by the admin
   * @returns the value selected in the form of state
   */
  const handleCheckboxChange = (item) => (e) => {
    let newUsers = [...selectedUsers];
    let newRows = rows.map((item) => item._id);
    let flag = true;

    if (e.target.checked) {
      newUsers.push(item);
      newRows.forEach((element) => {
        if (!newUsers.includes(element)) {
          flag = false;
        }
      });
      groupCheck[filter.pageNo] = flag;
    } else {
      newUsers = newUsers.filter((it) => {
        if (it === item) return false;
        return true;
      });
      groupCheck[filter.pageNo] = false;
    }
    setGroupCheck(groupCheck);
    setSelectedUsers(newUsers);
  };

  const handleGroupCheckboxChange = (e) => {
    let newUsers = [...selectedUsers];
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    let newRows = rows.map((item) => item._id);
    if (e.target.checked) {
      newUsers = [...newUsers, ...newRows].filter(distinct);
      groupCheck[filter.pageNo] = true;
    } else {
      newUsers = [...newUsers]
        .filter((item) => !newRows.includes(item))
        .filter(distinct);
      groupCheck[filter.pageNo] = false;
    }
    setGroupCheck(groupCheck);
    setSelectedUsers(newUsers);
  };

  const isGroupCheckBoxChecked = () => {
    const flag = groupCheck[filter.pageNo] ?? false;
    return flag;
  };

  if (rows.length === 0) {
    return <NoData listData={listData} />;
  }
  return (
    <div className="table-wrapper add-notification-list">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={isGroupCheckBoxChecked()}
                  onChange={handleGroupCheckboxChange}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Email ID</TableCell>
              <TableCell>
                <ColumnWithSort
                  title="Added On"
                  sortBy={filter?.sortBy}
                  currentKey="addedOn"
                  toggleSortOrder={toggleSortOrder}
                />
              </TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Checkbox
                    checked={isCheckBoxChecked(row._id)}
                    onChange={handleCheckboxChange(row._id)}
                  />
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.userType === 1 ? "Fan" : "Athlete"}</TableCell>
                <TableCell>
                  {row?.phoneNo !== "NA" && row?.countryCode !== "NA"
                    ? `${row?.countryCode} ${row?.phoneNo}`
                    : "NA"}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  <Moment format="DD MMM, YYYY">{row.addedon}</Moment>
                </TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>
                  {row?.subscription?.expireDate &&
                  moment().format("YYYY-MM-DD") <=
                    moment(row?.subscription.expireDate).format("YYYY-MM-DD")
                    ? "Subscribed"
                    : "Free"}
                </TableCell>
                <TableCell
                  className={
                    row.status === 1 ? "status-active" : "status-block"
                  }
                >
                  {row.status === 1 ? "Active" : "Blocked"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default NotificationAddTableList;
