import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";

/**
 * This Component contains Video BreadCrumb to navigate through clicks
 * @returns the Video BreadCrumb Component which will be rendered when this component is called.
 */
const VideoBreadCrumb = () => {
  return (
    <div className="title-left">
      <Link to={`/video-feed`}>
        <ArrowBackIosNewIcon />
      </Link>
      <h1 className="title">Video Details</h1>
    </div>
  );
};

export default VideoBreadCrumb;
