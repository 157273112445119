import { callApiAdvanced } from "utils/apiCaller";
import { toast_, loader_ } from "store/actions";
import {
  GET_SPORT_DETAIL,
  SET_EDIT_POSITION,
  SET_SPORT_INITIAL,
  SET_SPORT_PAGINATION,
} from "store/types";
import { modulesArr } from "utils/helper";

const moduleSport = modulesArr.filter((item) => item.id === 10)[0];

const customHeaders = {
  moduleHeader: moduleSport.value,
};

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const addSports = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/sport`,
      method: "POST",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        dispatch(
          toast_({
            type: "success",
            message: "Sport added successfully.",
          })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getAllSports = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/sports?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const deleteSport = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/sport?${data.params}`,
      method: "DELETE",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const statusSport = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/sport-enable`,
      method: "PATCH",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getSportDetail_ = (payload) => {
  return {
    type: GET_SPORT_DETAIL,
    payload,
  };
};

export const getSportPaginationDetail_ = (payload) => {
  return {
    type: SET_SPORT_PAGINATION,
    payload,
  };
};

export const setSportInitial_ = () => {
  return {
    type: SET_SPORT_INITIAL,
  };
};

export const getSportDetail = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/sport?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          dispatch(getSportDetail_(resData?.data));
          return true;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const addPosition = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/position`,
      method: "POST",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const deletePosition = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/position?${data.params}`,
      method: "DELETE",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const updatePosition = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/position`,
      method: "PATCH",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const setEditPosition_ = (payload) => {
  return {
    type: SET_EDIT_POSITION,
    payload,
  };
};

export const updateSport = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/sport`,
      method: "PATCH",
      body: data,
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        dispatch(
          toast_({
            type: "success",
            message: "Sport updated successfully.",
          })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const getSportPositions = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApiAdvanced({
      endpoint: `/admin/positions?${data.params}`,
      method: "GET",
      customHeaders,
    }).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data) {
          return resData?.data;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
