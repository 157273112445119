import * as React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  LeftSidebar,
  HeaderSection,
  AddPositionDialogs,
  SportsPositionTableList,
  PaginationSection,
} from "components/molecules";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import "./index.css";
import { connect } from "react-redux";
import {
  getSportDetail,
  getSportPositions,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import {
  convertObjToStringParams,
  getSubOrdinate,
  showAction,
  showSection,
} from "utils/helper";
import { Loader } from "components/atoms";
import SportsBlack from "assets/images/sports-black.svg";
import Button from "@mui/material/Button";

/**
 *  This Component contains sport detail
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getSportDetail : function containing API call for getting sport details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * getSportPositions : function containing API call for getting sport positions,
 * @returns the sport detail component which will be rendered when this page is called.
 */
const SportDetail = (props) => {
  const history = useHistory();

  const {
    getSportDetail,
    showLoader,
    detail,
    getSportPositions,
    alPermissions,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const { id } = useParams();
  const [openAddPosition, setOpenAddPosition] = React.useState(false);
  const [filter, setFilter] = React.useState({
    pageLimit: 10,
    pageNo: 1,
  });
  let [counter, setCounter] = React.useState(0);
  const [listData, setListData] = React.useState(null);
  const [pageMount, setPageMount] = React.useState(false);

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 10))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Sports management
   */
  React.useEffect(() => {
    fetchSportDetail();
    toggleMenu_({ key: "sportMgmt" });
    activateMenuLevel2_({ key: "sport" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to call handleFilterSubmit
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSportDetail = () => {
    const apiData = { id };
    const params = convertObjToStringParams(apiData);
    getSportDetail({ params }).then((res) => {
      if (res && !pageMount) setPageMount(true);
    });
  };

  /**
   * This Function is used to set default image
   */
  const showDefaultImage = (e) => {
    e.target.src = SportsBlack;
  };

  /**
   * This Function is used to open dialog box
   */
  const handleAddPositionDialog = () => {
    setOpenAddPosition(true);
  };

  /**
   * Used to create API Data to send in API call
   * @returns object having id, page, limit
   */
  const getApiData = () => {
    return {
      page: filter.pageNo,
      limit: filter.pageLimit,
      id,
    };
  };

  /**
   * used to call API to get sport detail
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getSportPositions({ params }).then((apiData) => {
      setListData(apiData);
    });
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        {showLoader && <Loader />}
        <div className="main-section sport-detail-list">
          <div className="sport-wrap">
            <div className="sport-title">
              <div className="title-left">
                <Link to={`/sports`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title-info">Sport Detail</h1>
              </div>
            </div>
            {pageMount && (
              <div className="sport-detail-info">
                <div className="info-sport">
                  <div className="info-sport-title">
                    <label>Sport Name:</label>
                    <strong>{detail?.sportName}</strong>
                  </div>
                  <div className="info-sport-description">
                    <label>Sport Icon:</label>
                    <div className="info-sport-icon">
                      <figure>
                        <img
                          src={detail?.sportImg}
                          alt="Sports Icon"
                          onError={showDefaultImage}
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="edit-text">
                  {showAction(alPermissions, 10) && (
                    <Link to={`/sports/${detail?._id}/edit`}>
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </Link>
                  )}
                </div>
              </div>
            )}
            {detail?.ordinateType && detail?.ordinateType !== 4 && (
              <>
                <div className="sport-title">
                  <div className="title-left">
                    <h2 className="sub-title">
                      {getSubOrdinate(detail?.ordinateType || 1)}s
                    </h2>
                  </div>
                  <div className="btn-group">
                    <div className="add-position-dialog">
                      {showAction(alPermissions, 10) && (
                        <Button
                          variant="contained"
                          onClick={handleAddPositionDialog}
                        >
                          Add a {getSubOrdinate(detail?.ordinateType || 1)}
                        </Button>
                      )}
                      <AddPositionDialogs
                        open={openAddPosition}
                        setOpen={setOpenAddPosition}
                        sport={detail}
                        fetchSportDetail={handleFilterSubmit}
                        ordinateName={getSubOrdinate(detail?.ordinateType || 1)}
                      />
                    </div>
                  </div>
                </div>
                <SportsPositionTableList
                  listData={listData}
                  filter={filter}
                  fetchSportDetail={handleFilterSubmit}
                  sport={detail}
                  ordinateName={getSubOrdinate(detail?.ordinateType || 1)}
                  alPermissions={alPermissions}
                />
                {listData?.result?.length > 0 && (
                  <PaginationSection
                    filter={filter}
                    listData={listData}
                    handleChangePage={handleChangePage}
                    handleChangePageLimit={handleChangePageLimit}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.sportInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getSportDetail: (data) => dispatch(getSportDetail(data)),
  getSportPositions: (data) => dispatch(getSportPositions(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});

export default connect(mapState, mapDispatch)(SportDetail);
