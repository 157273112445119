import * as React from "react";
import { LeftSidebar, HeaderSection } from "components/molecules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import {
  editFaq,
  getFaq,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import { Loader } from "components/atoms";
import { showSection } from "utils/helper";
import { Link } from "react-router-dom";

/**
 *  This Component contains Edit FAQ Page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * editFaq : function containing API call for editing FAQ details,
 * getFaq : function containing API call for getting FAQ details,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * @returns the Edit FAQ component which will be rendered when this page is called.
 */
const FaqEdit = (props) => {
  const {
    getFaq,
    alPermissions,
    showLoader,
    editFaq,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;
  const history = useHistory();
  const { id } = useParams();

  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 4))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Static management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "staticMgmt" });
    activateMenuLevel2_({ key: "content" });
    fetchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    getFaq({ id }).then((resp) => {
      if (resp) {
        setQuestion(resp.question);
        setAnswer(resp.answer);
      }
    });
  };

  /**
   * This Function is called when Submit button on FAQ edit Page is clicked
   * @param {event} e : This will take the event that is trigger when Submit button is clicked.
   * @returns API Call for editing FAQ.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      question: question,
      answer: answer,
    };
    editFaq({ data, id }).then((resp) => {
      if (resp) {
        setQuestion("");
        setAnswer("");
        history.push(`/static-content?type=2`);
      }
    });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="static-content-header">
              <div className="title-left">
                <Link to={`/static-content?type=2`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title">Edit</h1>
              </div>
              <div className="btn-group">
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </div>
            <div className="text-editor">
              <h2 className="text-editor-heading">Question</h2>
              <ReactQuill
                theme="snow"
                onChange={setQuestion}
                value={question}
              />
            </div>
            <div className="text-editor">
              <h2 className="text-editor-heading">Answer</h2>
              <ReactQuill theme="snow" onChange={setAnswer} value={answer} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  editFaq: (data) => dispatch(editFaq(data)),
  getFaq: (data) => dispatch(getFaq(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});

export default connect(mapState, mapDispatch)(FaqEdit);
