import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  NotificationDetailTableList,
  PaginationSection,
} from "components/molecules";
import Button from "@mui/material/Button";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { FilterSubmit, FilterReset, Loader } from "components/atoms";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import {
  convertObjToStringParams,
  showAction,
  showSection,
  getReceiverType,
} from "utils/helper";
import {
  getNotificationDetail,
  getNotificationDetail_,
  getSelectedUsers,
  sendNotification,
  toggleMenu_,
  activateMenuLevel2_,
} from "store/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/**
 *  This Component contains notification detail
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getNotificationDetail : function containing API call for getting notification detail,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * setMenu_,activateMenuLevel2_ : keeps the track of active page.
 * getSelectedUsers : used to get target user in a notification
 * @returns the notification detail component which will be rendered when this page is called.
 */
const NotificationDetail = (props) => {
  let history = useHistory();
  const { id: notificationId } = useParams();
  const {
    getNotificationDetail,
    showLoader,
    detail,
    alPermissions,
    getNotificationDetail_,
    getSelectedUsers,
    sendNotification,
    toggleMenu_,
    activateMenuLevel2_,
  } = props;

  const [value, setValue] = React.useState([null, null]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    from: "",
    to: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "addedOn", val: -1 },
    status: "",
    error: "",
  });
  const [listData, setListData] = React.useState(null);

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 8))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Notification Management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "notificationMgmt" });
    activateMenuLevel2_({ key: "notification" });
    fetchDetail();

    return () => {
      getNotificationDetail_(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to call handleFilterSubmit
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDetail = () => {
    const apiData = {
      id: notificationId,
    };
    const params = convertObjToStringParams(apiData);
    getNotificationDetail({ params }).then(() => setCounter((pc) => pc + 1));
  };

  /**
   * Used to open filter popup on click of filter icon
   * @param {event} event : This event parameter keeps the value of current target
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Used to close the filter Popup
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Debouncing For Search
   */
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  /**
   * This is used to set the filter values on the basis of which listing will be sorted.
   * @param {string} key : used to send the key whose value needs to be changed
   */
  const handleChangeFilter = (key) => (e) => {
    const newFilter = { ...filter };
    newFilter[key] = e.target.value;

    setFilter(newFilter);
  };

  /**
   * Used to create API Data to send in API call
   * @returns object having id, page, limit and selected filter values
   */
  const getApiData = () => {
    let apiData = {
      id: notificationId,
      page: filter.pageNo,
      limit: filter.pageLimit,
      sortKey: filter.sortBy.key,
      sortOrder: filter.sortBy.val,
    };
    if (value[0]) apiData.from = value[0];
    if (value[1]) apiData.to = value[1];
    if (filter.search) apiData.search = filter.search;
    if (filter.status) apiData.status = filter.status;
    return apiData;
  };

  /**
   * used to call API to get notification detail
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    if (filter.error) return false;

    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    setAnchorEl(null);
    if (detail?.receiverType && parseInt(detail?.receiverType) === 8) {
      getSelectedUsers({ params }).then((apiData) => {
        setListData(apiData);
      });
    }
  };

  /**
   * This Function is used to reset the selected values in filter
   */
  const handleFilterReset = () => {
    const newFilter = { ...filter };
    newFilter.from = "";
    newFilter.to = "";
    newFilter.status = "";
    newFilter.error = "";
    setValue([null, null]);
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to sort any column in table
   * @param {Object} obj : takes the key of column to sort and it's sorting order ascending/desending
   */
  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  /**
   * This Function is used to send notication
   */
  const handleSendNotification = () => {
    const apiData = {
      id: notificationId,
    };
    sendNotification(apiData).then((resp) => {
      if (resp) {
        history.push("/notifications");
      }
    });
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section add-athlete">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <Link to={`/notifications`}>
                  <ArrowBackIosNewIcon />
                </Link>
                <h1 className="title">Notification Detail</h1>
              </div>
              {showAction(alPermissions, 8) && (
                <div className="breadcrumb-right">
                  <Button variant="contained" onClick={handleSendNotification}>
                    Send
                  </Button>
                </div>
              )}
            </div>
            <div className="add-notification-wrap">
              <div className="form-row">
                <div className="col-half">
                  <div className="notification-text detail">
                    <label className="form-label">Title*</label>
                    <p className="detail-title">{detail?.title ?? "-"}</p>
                  </div>
                </div>
                <div className="col-half">
                  <div className="notification-text detail">
                    <label className="form-label">Description*</label>
                    <p className="detail-title">{detail?.message ?? "-"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-half">
                <div className="notification-text detail">
                  <label className="form-label">Recipient*</label>
                  {detail?.receiverType && (
                    <div className="btn-row">
                      {getReceiverType(parseInt(detail?.receiverType))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {detail?.receiverType && parseInt(detail?.receiverType) === 8 && (
              <>
                <div className="search-filter-wrap">
                  <div className="serach-wrap">
                    <TextField
                      label="Search"
                      variant="outlined"
                      placeholder="Search by name, email and mobile number"
                      onChange={handleSearch}
                      value={filter?.search}
                    />
                  </div>
                  <div className="filter-btn">
                    <Tooltip title="Filter">
                      <IconButton
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        color="primary"
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <div className="filterPopup">
                        <label className="form-label">Added Date</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateRangePicker
                            startText="From"
                            endText="To"
                            value={value}
                            open={datePickerOpen}
                            onChange={(newValue) => {
                              setValue(newValue);
                              if (
                                newValue[0] !== null &&
                                newValue[1] !== null
                              ) {
                                setDatePickerOpen(false);
                              }
                            }}
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField
                                  onClick={() => {
                                    setDatePickerOpen(true);
                                  }}
                                  {...startProps}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField
                                  onClick={() => {
                                    setDatePickerOpen(true);
                                  }}
                                  {...endProps}
                                />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                        <div className="filter-text">
                          <label className="form-label">Status</label>
                          <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                              label="Status"
                              placeholder="Status"
                              value={filter?.status}
                              onChange={handleChangeFilter("status")}
                            >
                              <MenuItem value={1}>Active</MenuItem>
                              <MenuItem value={2}>Blocked</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="btn-wrap">
                          <FilterReset
                            name="Reset"
                            onClickButton={handleFilterReset}
                          />
                          <FilterSubmit
                            name="Apply Filter"
                            onClickButton={handleFilterSubmit}
                          />
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
                <NotificationDetailTableList
                  listData={listData}
                  filter={filter}
                  toggleSortOrder={toggleSortOrder}
                  handleFilterSubmit={handleFilterSubmit}
                  alPermissions={alPermissions}
                />
                {listData?.result?.length > 0 && (
                  <PaginationSection
                    filter={filter}
                    listData={listData}
                    handleChangePage={handleChangePage}
                    handleChangePageLimit={handleChangePageLimit}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
  detail: state.notificationInfo?.current,
  alPermissions: state?.authInfo?.auth?.permissions,
});
const mapDispatch = (dispatch) => ({
  getNotificationDetail: (data) => dispatch(getNotificationDetail(data)),
  getNotificationDetail_: (data) => dispatch(getNotificationDetail_(data)),
  sendNotification: (data) => dispatch(sendNotification(data)),
  getSelectedUsers: (data) => dispatch(getSelectedUsers(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
});
export default connect(mapState, mapDispatch)(NotificationDetail);
