import { callApi } from "utils/apiCaller";
import { USER_LOGIN } from "store/types";
import { toast_, loader_ } from "store/actions";

const handleError = (dispatch, resData) => {
  if (resData && resData.message) {
    dispatch(toast_({ type: "error", message: resData.message }));
  } else {
    dispatch(toast_({ type: "error", message: "Something went wrong !" }));
  }
};

export const loginUser_ = (payload) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const loginUser = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi("/admin/login", "POST", data).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data?.authToken) {
          localStorage.setItem("token", resData?.data?.authToken);
          dispatch(loginUser_(resData?.data));
          dispatch(toast_({ type: "success", message: "Login successfull !" }));
          return true;
        }
      } else {
        dispatch(loginUser_(null));
        localStorage.clear();
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi("/admin/password/forgot", "POST", data, false, true).then(
      (response) => {
        dispatch(loader_({ key: "mainLoader", value: false }));
        const status = response?.status;
        const resData = response?.data;
        if (status && status === 200) {
          dispatch(
            toast_({
              type: "success",
              message: "A reset link has been sent on your email.",
            })
          );
          return true;
        } else {
          handleError(dispatch, resData);
          return false;
        }
      }
    );
  };
};

export const resetPassword = (data) => {
  let token = data.token;
  delete data.token;
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi(`/admin/password/reset/${token}`, "POST", data).then(
      (response) => {
        dispatch(loader_({ key: "mainLoader", value: false }));
        const status = response?.status;
        const resData = response?.data;
        if (status && status === 200) {
          dispatch(
            toast_({
              type: "success",
              message: "Password changed successfully.",
            })
          );
          return true;
        } else {
          handleError(dispatch, resData);
          return false;
        }
      }
    );
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    return callApi("/admin/logout", "PATCH").then((response) => {
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const verifyPasswordToken = (data) => {
  return (dispatch) => {
    return callApi(`/admin/password/verify/${data.token}`, "GET").then(
      (response) => {
        const status = response?.status;
        const resData = response?.data;
        if (status === 200) {
          return true;
        } else {
          handleError(dispatch, resData);
          return false;
        }
      }
    );
  };
};

export const myDetails = () => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi("/admin/details", "GET").then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        if (resData?.data?.authToken) {
          dispatch(loginUser_(resData?.data));
          return true;
        }
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const changeMyPassword = (data) => {
  return (dispatch) => {
    return callApi("/admin/password", "PATCH", data).then((response) => {
      const status = response?.status;
      const resData = response?.data;
      if (status === 200) {
        dispatch(
          toast_({
            type: "success",
            message: "Password updated successfully.",
          })
        );
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};

export const updateAdminDetails = (data) => {
  return (dispatch) => {
    dispatch(loader_({ key: "mainLoader", value: true }));
    return callApi("/admin/update", "PATCH", data).then((response) => {
      dispatch(loader_({ key: "mainLoader", value: false }));
      const status = response?.status;
      const resData = response?.data;
      if (status && status === 200) {
        dispatch(myDetails());
        return true;
      } else {
        handleError(dispatch, resData);
        return false;
      }
    });
  };
};
