import * as React from "react";
import {
  LeftSidebar,
  HeaderSection,
  SupportTableList,
  PaginationSection,
} from "components/molecules";
import TextField from "@mui/material/TextField";
import { Loader } from "components/atoms";
import {
  getAllSupport,
  toggleMenu_,
  activateMenuLevel2_,
  getSupportPaginationDetail_,
} from "store/actions";
import { convertObjToStringParams, showSection } from "utils/helper";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

/**
 *  This Component contains Support Listing
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getAllSupport : function containing API call for getting all the Support,
 * alPermissions : keeps the track if the logged in admin have permission to the particular module
 * page,limit : The page keep track of the pageNo called during API call and limit tracks the no. of records we need
 * @returns the Support Listing component which will be rendered when this page is called.
 */
const SupportList = (props) => {
  const {
    getAllSupport,
    showLoader,
    alPermissions,
    toggleMenu_,
    activateMenuLevel2_,
    getSupportPaginationDetail_,
    page,
    limit,
  } = props;
  const [listData, setListData] = React.useState(null);

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: limit,
    pageNo: page,
    sortBy: { key: "createdAt", val: -1 },
    error: "",
  });
  const history = useHistory();

  /**
   * This Function check if the admin have the permission to module else will redirect to unauthorized page.
   */
  React.useEffect(() => {
    if (Array.isArray(alPermissions) && !showSection(alPermissions, 11))
      history.push("/unauthorized");
  }, [alPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to call handleFilterSubmit
   */
  React.useEffect(() => {
    handleFilterSubmit();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This useEffect is used to set Active Module as Support management
   */
  React.useEffect(() => {
    toggleMenu_({ key: "supportMgmt" });
    activateMenuLevel2_({ key: "support" });
  }, []);

  /**
   * Used to create API Data to send in API call
   * @returns object having search, page, limit
   */
  const getApiData = () => {
    let apiData = {
      page: filter.pageNo,
      limit: filter.pageLimit,
    };
    if (filter.search) apiData.search = filter.search;
    return apiData;
  };

  /**
   * used to call API to get list of support
   * @returns false,if there is any error in the param values
   */
  const handleFilterSubmit = () => {
    if (filter.error) return false;
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);

    getAllSupport({ params }).then((resp) => {
      setListData(resp);
    });
  };

  /**
   * Debouncing For Search
   */
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  /**
   * This Function is used to set the selected value of limit
   * @param {number} val : takes the limit when changed by the admin
   */
  const handleChangePageLimit = (val) => {
    const newFilter = { ...filter };
    newFilter.pageLimit = val;
    newFilter.pageNo = 1;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
    getSupportPaginationDetail_({
      limit: val,
      page: newFilter.pageNo,
    });
  };

  /**
   * This Function is used to set the selected value of page
   * @param {number} val : takes the page no when changed by the admin
   */
  const handleChangePage = (val) => {
    const newFilter = { ...filter };
    newFilter.pageNo = val;
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
    getSupportPaginationDetail_({
      page: val,
      limit: newFilter.pageLimit,
    });
  };

  /**
   * This Function is used to sort any column in table
   * @param {Object} obj : takes the key of column to sort and it's sorting order ascending/desending
   */
  const toggleSortOrder = (obj) => {
    const { currentKey, value } = obj;
    const newFilter = { ...filter };
    newFilter.sortBy = { key: currentKey, val: value };
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="pageWrapper">
      <HeaderSection />
      <div className="main-wrapper">
        <LeftSidebar />
        <div className="main-section">
          {showLoader && <Loader />}
          <div className="main-section-inner">
            <div className="breadcrumb-wrap">
              <div className="breadcrumb-left">
                <h1 className="title">Support Management</h1>
              </div>
            </div>
            <div className="search-filter-wrap">
              <div className="serach-wrap">
                <TextField
                  label="Search"
                  variant="outlined"
                  placeholder="Search by name, email"
                  onChange={handleSearch}
                  value={filter?.search}
                />
              </div>
            </div>
            <SupportTableList
              listData={listData}
              filter={filter}
              toggleSortOrder={toggleSortOrder}
              handleFilterSubmit={handleFilterSubmit}
              alPermissions={alPermissions}
            />
            {listData?.result?.length > 0 && (
              <PaginationSection
                limit={limit}
                mainSection={true}
                listData={listData}
                handleChangePage={handleChangePage}
                handleChangePageLimit={handleChangePageLimit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state?.loaderInfo?.mainLoader,
  alPermissions: state?.authInfo?.auth?.permissions,
  page: state?.supportInfo?.page,
  limit: state?.supportInfo?.limit,
});
const mapDispatch = (dispatch) => ({
  getAllSupport: (data) => dispatch(getAllSupport(data)),
  getSupportPaginationDetail_: (data) =>
    dispatch(getSupportPaginationDetail_(data)),
  toggleMenu_: (data) => dispatch(toggleMenu_(data)),
  activateMenuLevel2_: (data) => dispatch(activateMenuLevel2_(data)),
});
export default connect(mapState, mapDispatch)(SupportList);
