import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { roundOff } from "utils/helper";
import NoData from "components/atoms/no-data";

/**
 * This Component contains Video Rating Table
 * @param {Object} props : listData : contains list of ratings
 * @returns the Video Rating Table Component which will be rendered when this page is called.
 */
const VideoRankedTableList = (props) => {
  const { listData } = props;
  let rows =
    listData?.result && listData?.result?.length > 0 ? listData?.result : [];

  if (rows.length === 0) {
    return <NoData listData={listData} />;

  }
  return (
    <div className="rank-tab table-wrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link
                    to={`/${
                      row.userData.userType === 1 ? "fans" : "athletes"
                    }/${row.userData.userId}`}
                  >
                    {row.userData.name}
                  </Link>
                </TableCell>
                <TableCell>{roundOff(row.rating ?? 0)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default VideoRankedTableList;
