import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Search from "assets/images/Vector.png";
import "./index.css";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { getAllStaticContent } from "store/actions";
import { convertObjToStringParams } from "utils/helper";
import { Loader } from "components/atoms";
import { Close } from "@mui/icons-material";

/**
 *  This Component contains FAQ page
 * @param {Object} props It takes showLoader in this object which will show loading state until data is getting fetched.
 * getAllStaticContent : function containing API call for getting FAQs,
 * @returns the FAQ component which will be rendered when this page is called.
 */
const Faqs = (props) => {
  const { getAllStaticContent, showLoader } = props;
  const [listData, setListData] = React.useState([]);
  const categories = [
    { key: "ACCOUNT_PROFILE", type: "Account Profile" },
    { key: "VIDEO_REEL", type: "Video Reel" },
    { key: "CUSTOMER_SUPPORT", type: "Customer Support" },
    { key: "FIVE_STAR_RATING", type: "Fivestar Rating" },
    { key: "PRIVACY", type: "Privacy" },
    { key: "OTHERS", type: "Others" },
  ];

  let [counter, setCounter] = React.useState(0);
  const [filter, setFilter] = React.useState({
    search: "",
    pageLimit: 10,
    pageNo: 1,
    sortBy: { key: "addedOn", val: -1 },
    error: "",
  });

  /**
   * This useEffect is used to call fetchDetail function when this Component is rendered on the screen
   */
  React.useEffect(() => {
    fetchDetail();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Used to create API Data to send in API call
   * @returns object having type, search
   */
  const getApiData = () => {
    let apiData = {
      type: 2,
    };
    if (filter.search) apiData.search = filter.search;
    return apiData;
  };

  /**
   * This Function is contains API call for getting FAQ content.
   */
  const fetchDetail = () => {
    const apiData = getApiData();
    const params = convertObjToStringParams(apiData);
    getAllStaticContent({ params }).then((resp) => {
      setListData(resp[0]);
    });
  };

  /**
   * Debouncing For Search
   */
  const debounceCount = React.useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  /**
   * This Function is called when search value is changed & API is called to get data related to search value.
   * @param {event} e : This parameter contains the event object which is triggered when search value is changed
   */
  const handleSearch = (e) => {
    const newFilter = { ...filter };
    newFilter["search"] = e.target.value;
    setFilter(newFilter);

    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      setCounter((pc) => pc + 1);
    });
  };

  /**
   * This function is used to clear search value
   */
  const handleClearSearch = () => {
    const newFilter = { ...filter };
    newFilter["search"] = "";
    setFilter(newFilter);
    setCounter((pc) => pc + 1);
  };

  return (
    <div className="policys-wrapper blacktheme">
      <div className="policys-inner">
        <div className="search-wrap-faq">
          <TextField
            variant="outlined"
            placeholder="Search your queries"
            onChange={handleSearch}
            value={filter?.search}
          />
          {filter?.search.length === 0 ? (
            <div className="search-icon">
              <img src={Search} alt="Search your queries" />
            </div>
          ) : (
            <Close className="search-icon" onClick={handleClearSearch} />
          )}
        </div>
        <div>
          {showLoader && <Loader />}
          <div className="accordian-section">
            {listData &&
              categories.map((category, i) => {
                let array = listData[category.key];
                return (
                  <div className="accordian-subsec">
                    {array && array.length > 0 && (
                      <div className="accordian-title">
                        <h3 className="title">{category.type}</h3>
                      </div>
                    )}
                    {array &&
                      array.map((faq, i) => {
                        return (
                          <Accordion key={faq._id + i}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                <h3>{faq.question && parse(faq.question)}</h3>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                {faq.answer && parse(faq.answer)}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state) => ({
  showLoader: state.loaderInfo?.mainLoader,
});
const mapDispatch = (dispatch) => ({
  getAllStaticContent: (data) => dispatch(getAllStaticContent(data)),
});
export default connect(mapState, mapDispatch)(Faqs);
