import { GET_EARNING_DETAIL,SET_INITIAL } from "store/types";

const initialState = {
  current: null,
};

const earningReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EARNING_DETAIL:
      state.current = action.payload;
      return { ...state };

    case SET_INITIAL:
      state.current = null;
      return { ...state };

    default:
      return state;
  }
};

export default earningReducer;
